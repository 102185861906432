import QueryString from 'query-string';

const hasLocalStorage = () => typeof window.localStorage !== 'undefined';

const isStored = (key: string) => hasLocalStorage() && window.localStorage.getItem(key) !== null;
const getStoredValue = (key: string) => window.localStorage.getItem(key);
const storeValue = (key: string, value: string) => window.localStorage.setItem(key, value);

/*

- Shell EmbedPage provides style information WHEN inside an iframe
   - In local development, it sends window.postMessage with the stylesheet inline (because there is no style-file)
   - In test/prod env Shell EmbedPage inserts styleUrl-query parameter
- If present in url, style url is saved to redux's settings store
- Style url is read from settings (so even if it was not in url, it's received from backend on settings' load)
- Test / Prod
  - Style url is received from url, if not in url (because of in-app navigation) then backend settings acts as fallback
- Development time
  - styleUrl not provider because shell dev-server has inline styles. In this case Window.postMessage is used to send
  styles to child. They are saved to local storage and restored from there if found, to enable page to work without
  being inside an iframe
 */

export function getShellStylePathFromAddress() {
	if (!window.location.search.length) {
		return null;
	}

	const queryParams = QueryString.parse(window.location.search.substring(1));
	let styleUrl = queryParams['styleUrl'];
	if (styleUrl) {
		if (Array.isArray(styleUrl)) {
			styleUrl = styleUrl[0];
		}
	}
	return styleUrl;
}

export const isDevelopmentMode = process.env.NODE_ENV === 'development';

export function getShellInlineStyles(callback: (styles: string) => void) {
	if (window.parent == window) {
		// There's no parent window.
		if (isDevelopmentMode && isStored('debugStyle')) {
			const style = getStoredValue('debugStyle');
			if (style) {
				callback(style);
			}
			return;
		}
	}

	window.addEventListener('message', receiveStyleMessage, false);
	window.parent.postMessage('style', '*');

	function receiveStyleMessage(event: { data: { type: string; content: string } }) {
		if (!document || !document.head) {
			return;
		}

		if (event.data.type === 'styleRules') {
			if (isDevelopmentMode && hasLocalStorage()) {
				storeValue('debugStyle', event.data.content);
			}
			callback(event.data.content);
		}
	}
}
