import { ActionType, createAsyncAction, createAction } from 'typesafe-actions';
import {
	AllowedTerminationDatesAM,
	TerminationDataAIM,
	TerminationCompletionInfoAM,
	IdentificationContext,
	IdentificationFormDataAM,
} from './rentalApiClient';
import { CurrentParkingSpaceAM } from './parkingSpaceApiClient';

export const terminationActions = {
	startTermination: createAsyncAction(
		'termination/START_REQUEST',
		'termination/START_SUCCESS',
		'termination/START_FAILURE',
	)<TerminationDataAIM, void, Error>(),

	getAllowedTerminationDates: createAsyncAction(
		'termination/GET_ALLOWED_DATES_REQUEST',
		'termination/GET_ALLOWED_DATES_SUCCESS',
		'termination/GET_ALLOWED_DATES_FAILURE',
	)<string, AllowedTerminationDatesAM, Error>(),

	getIdentificationForm: createAsyncAction(
		'termination/GET_IDENTIFICATION_FORM_REQUEST',
		'termination/GET_IDENTIFICATION_FORM_SUCCESS',
		'termination/GET_IDENTIFICATION_FORM_FAILURE',
	)<IdentificationContext, IdentificationFormDataAM, Error>(),

	setParkingSpaceToBeTerminated: createAction('rental/SET_PARKING_SPACE_TO_BE_TERMINATED')<CurrentParkingSpaceAM>(),
	finalizeTermination: createAsyncAction(
		'termination/FINALIZE_REQUEST',
		'termination/FINALIZE_SUCCESS',
		'termination/FINALIZE_FAILURE',
	)<void, TerminationCompletionInfoAM, Error>(),
};

export type TerminationActionTypes = ActionType<typeof terminationActions>;
