import { ParkingSpaceAM } from '../../Data/ParkingSpaceAM';
import { formatWattage } from '../../electricChargingUtils';
import ChargingPrice from '../ChargingPrice';
import { ResourceTextInline, useResource } from '@kojamo/react-utils';
import { useAppLanguage } from '../../hooks/useAppLanguage';
import { getElectricChargingTypeKey } from '../../Common/getElectricChargingTypeKey';

export function ParkingSpacePriceDetails(props: { space: ParkingSpaceAM }) {
	const { space } = props;
	const { getResourceText } = useResource();
	const { language } = useAppLanguage();
	return (
		<ul className="atom-singleParkingSpace__details">
			<li className="atom-singleParkingSpace__singleDetail">
				<span className="detailTitle">
					<ResourceTextInline resourceKey="ParkingSpace_ElectricChargingPrice_Title" />
				</span>
				{!space.electricChargingPrice && !space.electricChargingCompany ? (
					<ResourceTextInline resourceKey="ParkingSpace_ElectricChargingPrice_IncludedInRent" />
				) : (
					<ChargingPrice price={space.electricChargingPrice} />
				)}
			</li>
			<li className="atom-singleParkingSpace__singleDetail">
				<span className="detailTitle">
					<ResourceTextInline resourceKey="ParkingSpace_ElectricChargingType_Title" />
				</span>
				<span>
					{formatWattage(space.electricChargingWattage, language)}{' '}
					{getResourceText(getElectricChargingTypeKey(space.electricChargingType))}
				</span>
			</li>
			{space.electricChargingCompany && (
				<li className="atom-singleParkingSpace__singleDetail">
					<span className="detailTitle">
						<ResourceTextInline resourceKey="ParkingSpace_ElectricChargingOperator_Title" />
					</span>
					<span>{space.electricChargingCompany}</span>
				</li>
			)}
		</ul>
	);
}
