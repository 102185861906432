import { getType } from 'typesafe-actions';
import { take, takeLatest, put } from 'redux-saga/effects';
import { appActions } from './appActions';
import { authActions } from '../Authentication/authenticationActions';
import { appStateActions } from './appStateActions';

function* watchAppInitSaga() {
	yield put(appStateActions.fetchAppSettings.request());

	yield put(appStateActions.setLanguage.request());

	yield take(getType(appStateActions.fetchAppSettings.success));

	yield put(authActions.refresh.request());

	yield take(getType(authActions.refresh.success));
	yield put(appStateActions.getUserInfo.request());
}

export function* appInitSaga() {
	yield takeLatest(getType(appActions.init), watchAppInitSaga);
}
