import { ActionType, getType } from 'typesafe-actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { parkingSpaceActions } from './parkingSpaceActions';
import { CurrentParkingSpaceAM, ParkingSpaceApiClient } from './parkingSpaceApiClient';
import { AppRootState } from '../Store/appRootState';
import { UILanguage, UserInfo } from './userReducer';

function* getCurrentParkingSpaces(action: ActionType<typeof parkingSpaceActions.getCurrentParkingSpaces.request>) {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userInfo: UserInfo = yield select((state: AppRootState) => state.user.userInfo);
		const api = new ParkingSpaceApiClient(language, userInfo);
		const result: CurrentParkingSpaceAM[] = yield call([api, api.fetchCurrentSpaces], action.payload);
		yield put(parkingSpaceActions.getCurrentParkingSpaces.success(result));
	} catch (e: any) {
		yield put(parkingSpaceActions.getCurrentParkingSpaces.failure(e));
	}
}

export default function* parkingSpaceSaga() {
	yield takeLatest(getType(parkingSpaceActions.getCurrentParkingSpaces.request), getCurrentParkingSpaces);
}
