import { getType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { WatchAM } from './searchWatchApiClient';
import { OperationFailed, OperationRequested, OperationState, OperationSuccess } from '../operationState';
import { searchWatchActions, SearchWatchActionTypes } from './searchWatchActions';

export interface SearchWatchState {
	createSearchWatch: OperationState<WatchAM> | undefined;
	editSearchWatch: OperationState<WatchAM> | undefined;
	currentSearchWatch: OperationState<WatchAM> | undefined;
	deleteSearchWatch: OperationState<void> | undefined;
}

const initialState: SearchWatchState = {
	createSearchWatch: undefined,
	editSearchWatch: undefined,
	currentSearchWatch: undefined,
	deleteSearchWatch: undefined,
};

export const searchWatchReducer: Reducer<SearchWatchState, SearchWatchActionTypes> = (state = initialState, action) => {
	switch (action.type) {
		case getType(searchWatchActions.createSearchWatch.request): {
			return {
				...state,
				createSearchWatch: OperationRequested(action.payload),
			};
		}
		case getType(searchWatchActions.createSearchWatch.success): {
			return {
				...state,
				currentSearchWatch: OperationSuccess(action.payload),
				createSearchWatch: OperationSuccess(action.payload),
			};
		}
		case getType(searchWatchActions.createSearchWatch.failure): {
			return {
				...state,
				createSearchWatch: OperationFailed(action.payload),
			};
		}
		case getType(searchWatchActions.resetSearchWatchCreationState): {
			return {
				...state,
				createSearchWatch: undefined,
			};
		}
		case getType(searchWatchActions.editSearchWatch.request): {
			return {
				...state,
				editSearchWatch: OperationRequested(action.payload),
			};
		}
		case getType(searchWatchActions.editSearchWatch.success): {
			return {
				...state,
				currentSearchWatch: OperationSuccess(action.payload),
				editSearchWatch: OperationSuccess(action.payload),
			};
		}
		case getType(searchWatchActions.editSearchWatch.failure): {
			return {
				...state,
				editSearchWatch: OperationFailed(action.payload),
			};
		}
		case getType(searchWatchActions.resetSearchWatchEditState): {
			return {
				...state,
				editSearchWatch: undefined,
			};
		}
		case getType(searchWatchActions.fetchSearchWatch.request): {
			return {
				...state,
				currentSearchWatch: OperationRequested(undefined),
			};
		}
		case getType(searchWatchActions.fetchSearchWatch.success): {
			return {
				...state,
				currentSearchWatch: OperationSuccess(action.payload),
			};
		}
		case getType(searchWatchActions.fetchSearchWatch.failure): {
			return {
				...state,
				currentSearchWatch: OperationFailed(action.payload),
			};
		}
		case getType(searchWatchActions.deleteSearchWatch.request): {
			return {
				...state,
				deleteSearchWatch: OperationRequested(action.payload),
			};
		}
		case getType(searchWatchActions.deleteSearchWatch.success): {
			return {
				...state,
				currentSearchWatch: OperationFailed(new Error('deleted')),
				deleteSearchWatch: OperationSuccess(undefined),
			};
		}
		case getType(searchWatchActions.deleteSearchWatch.failure): {
			return {
				...state,
				deleteSearchWatch: OperationFailed(action.payload),
			};
		}
		case getType(searchWatchActions.resetSearchWatchRemovalState): {
			return {
				...state,
				deleteSearchWatch: undefined,
			};
		}
	}
	return state;
};
