import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { appRoutes } from '../appRoutes';
import { ResourceText, ResourceTextInline } from '@kojamo/react-utils';
import { AppRootState } from '../Store/appRootState';
import { pageViewTelemetryActions } from '../Telemetry/pageViewTelemetryActions';

interface StateProps {
	isLoggedIn: boolean;
}

const dispatchProps = {
	pageComplete: pageViewTelemetryActions.pageComplete,
};
type DispatchProps = typeof dispatchProps;
type Props = StateProps & DispatchProps;

function NotFoundPage(props: Props) {
	const { isLoggedIn, pageComplete } = props;
	useEffect(() => {
		pageComplete({
			pageType: 'NotFound',
			data: { isLoggedIn: isLoggedIn },
		});
	}, [isLoggedIn, pageComplete]);

	return (
		<div className="not-found">
			<div className="content">
				<svg
					className="not-found-icon"
					xmlns="http://www.w3.org/2000/svg"
					width="32"
					height="32"
					viewBox="0 0 32 32"
				>
					<path d="M16 31a15 15 0 1 1 0-30 15 15 0 0 1 0 30zm0-29A14 14 0 0 0 2 16a14 14 0 0 0 14 14 14 14 0 0 0 14-14A14 14 0 0 0 16 2z" />
					<circle cx="14" cy="12" r="2" />
					<circle cx="22" cy="12" r="2" />
					<path d="M13 22c0-2.8 2.2-5 5-5s5 2.2 5 5h-.9a4 4 0 0 0-4.1-4.1 4 4 0 0 0-4.1 4.1H13z" />
				</svg>
				<h1>{<ResourceText resourceKey="NotFound_Heading_Main" />}</h1>
				<Link to={appRoutes.homePage} className="arrow-after">
					<svg width="28" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
						<circle cx="14" cy="14" r="14" fill="#f05183" />
						<path
							d="M11.3 18.6a.6.6 0 0 1-.3-1.2l6.4-3.4-6.4-3.4a.6.6 0 0 1 .6-1.1l7.5 4a.6.6 0 0 1 .2.8.6.6 0 0 1-.2.2l-7.5 4a.6.6 0 0 1-.3.1z"
							fill="#fff1e7"
						/>
					</svg>
					{<ResourceTextInline resourceKey="NotFound_Link_ToFrontPage" />}
				</Link>
			</div>
		</div>
	);
}

export default connect<StateProps, DispatchProps, unknown, AppRootState>(
	({ user }) => ({
		isLoggedIn: !!user.userInfo?.userContext,
	}),
	dispatchProps,
)(NotFoundPage);
