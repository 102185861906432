import { getType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { AllowedTerminationDatesAM, IdentificationFormDataAM, TerminationCompletionInfoAM } from './rentalApiClient';
import { CurrentParkingSpaceAM } from './parkingSpaceApiClient';
import { OperationFailed, OperationRequested, OperationState, OperationSuccess } from '../operationState';
import { terminationActions, TerminationActionTypes } from './terminationActions';

export interface TerminationState {
	spaceToTerminate?: CurrentParkingSpaceAM;
	terminationOperationState?: OperationState<void>;
	allowedTerminationDates?: OperationState<AllowedTerminationDatesAM>;
	identificationForm?: OperationState<IdentificationFormDataAM>;
	terminationCompletionInfo?: OperationState<TerminationCompletionInfoAM>;
}

const initialState: TerminationState = {};

export const terminationReducer: Reducer<TerminationState, TerminationActionTypes> = (state = initialState, action) => {
	switch (action.type) {
		case getType(terminationActions.setParkingSpaceToBeTerminated): {
			return {
				...state,
				spaceToTerminate: action.payload,
			};
		}

		case getType(terminationActions.startTermination.request): {
			return {
				...state,
				terminationOperationState: OperationRequested(),
			};
		}
		case getType(terminationActions.startTermination.success): {
			return {
				...state,
				terminationOperationState: OperationSuccess(undefined),
			};
		}
		case getType(terminationActions.startTermination.failure): {
			return {
				...state,
				terminationOperationState: OperationFailed(action.payload),
			};
		}

		case getType(terminationActions.getAllowedTerminationDates.request): {
			return {
				...state,
				allowedTerminationDates: OperationRequested(action.payload),
			};
		}
		case getType(terminationActions.getAllowedTerminationDates.success): {
			return {
				...state,
				allowedTerminationDates: OperationSuccess(action.payload),
			};
		}
		case getType(terminationActions.getAllowedTerminationDates.failure): {
			return {
				...state,
				allowedTerminationDates: OperationFailed(action.payload),
			};
		}

		case getType(terminationActions.getIdentificationForm.request): {
			return {
				...state,
				identificationForm: OperationRequested(action.payload),
			};
		}
		case getType(terminationActions.getIdentificationForm.success): {
			return {
				...state,
				identificationForm: OperationSuccess(action.payload),
			};
		}
		case getType(terminationActions.getIdentificationForm.failure): {
			return {
				...state,
				identificationForm: OperationFailed(action.payload),
			};
		}

		case getType(terminationActions.finalizeTermination.request): {
			return {
				...state,
				terminationCompletionInfo: OperationRequested(action.payload),
			};
		}
		case getType(terminationActions.finalizeTermination.success): {
			return {
				...state,
				spaceToTerminate: undefined,
				allowedTerminationDates: undefined,
				terminationOperationState: undefined,
				terminationCompletionInfo: OperationSuccess(action.payload),
			};
		}
		case getType(terminationActions.finalizeTermination.failure): {
			return {
				...state,
				terminationCompletionInfo: OperationFailed(action.payload),
			};
		}
	}
	return state;
};
