export function formatDate(date: Date, options?: Intl.DateTimeFormatOptions) {
	return Intl.DateTimeFormat('fi', options).format(date);
}

/**
 * Returns negative number if a is earlier than b and positive number if a is greater than b
 * @param a
 * @param b
 */
export function compare(a: Date, b: Date): number {
	return a.getTime() - b.getTime();
}

export function isInPast(d: Date) {
	return compare(d, new Date()) <= 0;
}

export function addDays(d: Date, days: number) {
	return addMinutes(d, 24 * 60 * days);
}

export function addMinutes(d: Date, minutes: number) {
	return addMilliseconds(d, minutes * 60 * 1000);
}

export function addMilliseconds(d: Date, milliseconds: number) {
	return new Date(d.getTime() + milliseconds);
}

export function isSameDay(a: Date, b: Date) {
	return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
}
