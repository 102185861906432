import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { appRoutes } from '../appRoutes';
import { ResourceTextInline } from '@kojamo/react-utils';
import { pageViewTelemetryActions } from '../Telemetry/pageViewTelemetryActions';

export default function RentalTimedOutPage() {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(pageViewTelemetryActions.pageComplete({ pageType: 'RentalTimedOut' }));
	}, [dispatch]);

	return (
		<>
			<h2>
				<ResourceTextInline resourceKey="RentalTimedOutPage_Heading" />
			</h2>
			<Link to={appRoutes.homePage} className="arrow-after">
				<svg width="28" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
					<circle cx="14" cy="14" r="14" fill="#f05183" />
					<path
						d="M11.3 18.6a.6.6 0 0 1-.3-1.2l6.4-3.4-6.4-3.4a.6.6 0 0 1 .6-1.1l7.5 4a.6.6 0 0 1 .2.8.6.6 0 0 1-.2.2l-7.5 4a.6.6 0 0 1-.3.1z"
						fill="#fff1e7"
					/>
				</svg>
				{<ResourceTextInline resourceKey="RentalTimedOutPage_ReturnToFrontPageLink" />}
			</Link>
		</>
	);
}
