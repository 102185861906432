export enum ApiOperationState {
	Requested = 'Requested',
	Succeeded = 'Succeeded',
	Failed = 'Failed',
}

type StateBase<ApiOperationState> = { status: ApiOperationState };
type WithParam<T> = { param: T };

// eslint-disable-next-line @typescript-eslint/ban-types
type MaybeParam<TParam> = TParam extends undefined ? {} : WithParam<TParam>;
type OperationStateRequested<TParam = undefined> = StateBase<ApiOperationState.Requested> & MaybeParam<TParam>;
type OperationStateSuccess<TResult, TParam = undefined> = StateBase<ApiOperationState.Succeeded> & {
	result: TResult;
} & MaybeParam<TParam>;
type OperationStateFailed<TParam = undefined> = StateBase<ApiOperationState.Failed> & {
	error: Error;
} & MaybeParam<TParam>;
export type OperationState<TResult, TParam = undefined> =
	| OperationStateRequested<TParam>
	| OperationStateSuccess<TResult, TParam>
	| OperationStateFailed<TParam>;
export function isOperationSuccess<TResult, TParam = undefined>(
	operation: OperationState<TResult, TParam> | undefined,
): operation is OperationStateSuccess<TResult, TParam>;
export function isOperationSuccess<TResult, TParam>(
	operation: OperationState<TResult, TParam> | undefined,
	param: TParam,
): operation is OperationStateSuccess<TResult, TParam>;
export function isOperationSuccess<TResult, TParam = undefined>(
	operation: OperationState<TResult, TParam> | undefined,
	param?: TParam,
): operation is OperationStateSuccess<TResult, TParam> {
	return (
		operation !== undefined &&
		operation.status === ApiOperationState.Succeeded &&
		(!param || !hasParam(operation) || param === operation.param) // If param is provided, compare equality
	);
}

export function OperationRequested<TParam = undefined>(param?: TParam): OperationStateRequested<TParam> {
	return {
		status: ApiOperationState.Requested,
		param: param,
	} as any;
}

export function OperationSuccess<TResult, TParam = undefined>(
	result: TResult,
	param?: TParam,
): OperationStateSuccess<TResult, TParam> {
	return {
		status: ApiOperationState.Succeeded,
		result: result,
		param: param,
	} as any;
}

export function OperationFailed<TParam = undefined>(error: Error, param?: TParam): OperationStateFailed<TParam> {
	return {
		status: ApiOperationState.Failed,
		param: param,
		error: error,
	} as any;
}

export function isOperationNotExecutedOrFailed(state: ApiOperationState | undefined) {
	return state === undefined || state === ApiOperationState.Failed;
}

export function isAlreadyRequested<TResult, TParam>(
	operation: OperationState<TResult, TParam> | undefined,
	param?: TParam,
	areEqual?: (p1: TParam, p2: TParam) => boolean,
) {
	const isSame = (p1: TParam, p2: TParam) => {
		return areEqual ? areEqual(p1, p2) : p1 === p2;
	};

	if (!operation) {
		return false; // not started
	}
	if (!param) {
		return true; // didn't ask for param, any started is true
	}
	if (!hasParam(operation)) {
		return false; // did ask for param and operation didn't have one
	}
	return isSame(param, operation.param);
}

// Type guard to check if operation has parameter
function hasParam<TResult, TParam>(
	operation: OperationState<TResult, TParam>,
): operation is OperationState<TResult, TParam> & WithParam<TParam> {
	return (operation as unknown as WithParam<TParam>).param !== undefined;
}
