import { ParkingSpaceAM } from '../../Data/ParkingSpaceAM';

import { formatWattage } from '../../electricChargingUtils';
import { ResourceTextInline, useResource } from '@kojamo/react-utils';
import { useAppLanguage } from '../../hooks/useAppLanguage';
import { getElectricChargingTypeKey } from '../../Common/getElectricChargingTypeKey';

export function ParkingSpaceElectricChargingInformationNoCompany(props: { space: ParkingSpaceAM }) {
	const { language } = useAppLanguage();
	const { getResourceText } = useResource();
	const { space } = props;

	return (
		<span>
			{space.electricChargingType === 'NotInUse' ? (
				<ResourceTextInline resourceKey="ElectricChargingType_NotInUse" />
			) : (
				<span>
					{formatWattage(space.electricChargingWattage, language)}{' '}
					{getResourceText(getElectricChargingTypeKey(space.electricChargingType))}
				</span>
			)}
		</span>
	);
}
