import { Helmet } from 'react-helmet';

export function HeadScripts({ shellBaseUrl }: { shellBaseUrl: string | undefined }) {
	if (!shellBaseUrl) {
		return null;
	}
	return (
		<Helmet>
			<script src={`${shellBaseUrl}/AppUtils/shellFooter.js?${Date.now()}`} />

			<script>
				{`(function (w, d, s, l, i) {
					w[l] = w[l] || [];
					w[l].push({
						'gtm.start':
							new Date().getTime(), event: 'gtm.js'
					});
					var f = d.getElementsByTagName(s)[0],
						j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
					j.async = true;
					j.src =
						'https://gtm.lumo.fi/gtm.js?id=' + i + dl;
					f.parentNode.insertBefore(j, f);
				})(window, document, 'script', 'dataLayer', 'GTM-5TGP2KZ');`}
			</script>
		</Helmet>
	);
}
