interface Props {
	title: string | React.ReactElement;
	text: string | React.ReactElement;
	icon?: Variant;
	smallText?: string | React.ReactElement;
	children?: React.ReactElement;
}

type Variant = 'removeSpace' | 'success' | 'parkingSpaceSearchWatch';

export function TextHero(props: Props) {
	const variantClass = props.icon ? ` -${props.icon}` : '';
	return (
		<div className={`mod-textHero${variantClass}`}>
			<div className="mod-textHero__icon"></div>
			<h1 className="mod-textHero__title">{props.title}</h1>
			<p className="mod-textHero__text">{props.text}</p>
			{props.smallText ? <p className="mod-textHero__small">{props.smallText}</p> : ''}
			{props.children}
		</div>
	);
}
