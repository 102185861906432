import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { parkingSpaceActions } from '../Data/parkingSpaceActions';
import { CurrentParkingSpaceAM } from '../Data/parkingSpaceApiClient';
import { searchWatchActions } from '../Data/searchWatchActions';
import { EditSearchWatchAM, WatchAM } from '../Data/searchWatchApiClient';
import { isOperationSuccess, OperationState } from '../operationState';
import { AppRootState } from '../Store/appRootState';
import { ResourceTextInline } from '@kojamo/react-utils';
import { HighlightText } from '../Components/HighlightText';
import { Link } from 'react-router-dom';
import { TextHero } from '../Components/TextHero';
import { SearchWatchTypeSelector } from '../Components/SearchWatchTypeSelector';
import { localizedLinks } from '../localizedLinks';
import { useOperationComplete } from '../hooks/useOperationComplete';
import { appRoutes } from '../appRoutes';
import { BusinessEntityFullAM } from '../Data/businessEntityApiClient';
import { businessEntityActions } from '../Data/businessEntityActions';
import { BackToBusinessEntityPage } from '../Components/BackToBusinessEntityPage';
import { pageViewTelemetryActions } from '../Telemetry/pageViewTelemetryActions';
import { notifyShellNavigatedTo } from '../shellIntegration';
import { addTelemetryEventGA4, GA4EventAction } from '../Telemetry/TelemetryGA4';
import { getParkingSpaceCharacteristics } from '../Common/getParkingSpaceCharacteristics';
import { useAppLanguage } from '../hooks/useAppLanguage';

interface StateProps {
	userEmail: string | undefined;
	isLoggedIn: boolean;
	shellBaseUrl: string | undefined;
	businessEntityId: string;
	businessEntity: OperationState<BusinessEntityFullAM> | undefined;
	currentSpaces: OperationState<CurrentParkingSpaceAM[]> | undefined;
	currentSearchWatch: OperationState<WatchAM> | undefined;
	editSearchWatchOperation: OperationState<EditSearchWatchAM> | undefined;
}

const dispatchProps = {
	fetchBusinessEntity: businessEntityActions.fetchBusinessEntity.request,
	getCurrentParkingSpaces: parkingSpaceActions.getCurrentParkingSpaces.request,
	editSearchWatch: searchWatchActions.editSearchWatch.request,
	fetchSearchWatch: searchWatchActions.fetchSearchWatch.request,
	resetSearchWatchEditState: searchWatchActions.resetSearchWatchEditState,
	pageComplete: pageViewTelemetryActions.pageComplete,
};

type DispatchProps = typeof dispatchProps;
type Props = StateProps & DispatchProps;

enum ViewMode {
	Edit,
	Complete,
	Error,
}

function EditSearchWatchPage(props: Props) {
	const {
		businessEntityId,
		businessEntity,
		fetchBusinessEntity,
		getCurrentParkingSpaces,
		editSearchWatch,
		fetchSearchWatch,
		currentSearchWatch,
		editSearchWatchOperation,
		resetSearchWatchEditState,
		pageComplete,
		userEmail,
		shellBaseUrl,
		isLoggedIn,
	} = props;
	const { language } = useAppLanguage();
	const [typeSelection, setTypeSelection] = useState<string[]>([]);
	const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Edit);
	const [allowSubmit, setAllowSubmit] = useState<boolean>(false);
	const [completedTypes, setCompletedTypes] = useState<string[]>([]);
	const currentWatch = isOperationSuccess(currentSearchWatch) ? currentSearchWatch.result : undefined;
	const businessEntityList = isOperationSuccess(businessEntity) ? businessEntity.result : undefined;

	const typeOptions: string[] =
		isOperationSuccess(businessEntity) && businessEntity.result.id === businessEntityId
			? businessEntity.result.existingParkingSpaceCharacteristics
			: [];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		fetchBusinessEntity(businessEntityId);

		if (isLoggedIn) {
			getCurrentParkingSpaces(businessEntityId);
			if (!currentWatch) {
				fetchSearchWatch();
			}
		}
	}, [businessEntityId, isLoggedIn, currentWatch, fetchBusinessEntity, getCurrentParkingSpaces, fetchSearchWatch]);

	useEffect(() => {
		if (businessEntityList) {
			pageComplete({ businessEntityNumber: businessEntityList.number, pageType: 'EditSearchWatch' });
		}
	}, [businessEntityList, pageComplete]);

	useEffect(() => {
		notifyShellNavigatedTo(appRoutes.editSearchWatchPage);
	}, []);

	const typeSelectionChanged = (types: string[]) => {
		setTypeSelection(types);
		setAllowSubmit(!!types.length);
	};

	useEffect(() => {
		if (currentWatch?.allowedTypes) {
			typeSelectionChanged(currentWatch.allowedTypes);
		}
	}, [currentWatch?.allowedTypes]);

	useOperationComplete(editSearchWatchOperation, {
		onSuccess: (result) => {
			setViewMode(ViewMode.Complete);
			setCompletedTypes([...result.allowedTypes]);
			addTelemetryEventGA4({ action: GA4EventAction.parkingspace_searchwatch_edited });
			resetSearchWatchEditState();
			window.scrollTo(0, 0);
		},
		onError: () => {
			setViewMode(ViewMode.Error);
			resetSearchWatchEditState();
		},
	});

	const searchTypes = currentWatch ? (
		<SearchWatchTypeSelector
			options={typeOptions}
			initialSelection={currentWatch.allowedTypes}
			onChange={typeSelectionChanged}
		/>
	) : undefined;

	const onSaveClick = () => {
		editSearchWatch({
			id: currentWatch!.id,
			isSwapping: currentWatch!.isSwapping,
			allowedTypes: typeSelection,
		});
	};

	const completedTypesList = completedTypes
		? completedTypes.map((type) => (
				<li key={type}>
					<ResourceTextInline resourceKey={getParkingSpaceCharacteristics(type)} />
				</li>
		  ))
		: undefined;

	const renderContents = () => {
		switch (viewMode) {
			case ViewMode.Edit:
				return (
					<>
						<BackToBusinessEntityPage businessEntityId={businessEntityId} />
						<div className="mod-textHero -parkingSpaceSearchWatch">
							<div className="mod-textHero__icon"></div>
							<h1 className="mod-textHero__title">
								<ResourceTextInline resourceKey="EditSearchWatchPage_EditSearchWatch_Title" />
							</h1>
							<p className="mod-textHero__text">
								<ResourceTextInline
									resourceKey="EditSearchWatchPage_Ingress"
									parameters={[businessEntityList?.name || '']}
								/>
							</p>
						</div>
						<div className="mod">
							<div className="atom-boxedContent">
								<h3 className="atom-boxedContent__title">
									<ResourceTextInline resourceKey="CreateSearchWatchPage_SearchWatchType_Title" />
								</h3>
								{searchTypes}
							</div>
						</div>
						<div className="mod-ctaGroup">
							{!!userEmail ? (
								<>
									<button
										className="mod-ctaGroup__button -primary"
										onClick={onSaveClick}
										disabled={!allowSubmit}
									>
										<ResourceTextInline resourceKey="EditSearchWatchPage_SaveButton" />
									</button>
									<Link to={appRoutes.searchWatchRemovePage}>
										<button className="mod-ctaGroup__button -secondary">
											<ResourceTextInline resourceKey="EditSearchWatchPage_RemoveButton" />
										</button>
									</Link>
									<Link
										to={appRoutes.businessEntityPage(businessEntityId)}
										className={'mod-ctaGroup__link'}
									>
										<ResourceTextInline resourceKey="EditSearchWatchPage_Back" />
									</Link>

									<p className="mod-ctaGroup__text">
										<ResourceTextInline
											resourceKey="EditSearchWatchPage_NotificationEmailText"
											parameters={[userEmail]}
										/>
									</p>
									<a
										href={`${shellBaseUrl}${localizedLinks.customerContact[language]}`}
										target="_top"
										className="mod-ctaGroup__link"
									>
										<ResourceTextInline resourceKey="CreateSearchWatchPage_ChangeContactInfo_LinkText" />
									</a>
								</>
							) : (
								<HighlightText
									version="error"
									text={
										<>
											<span className="content">
												<ResourceTextInline resourceKey="SearchWatchPage_EmailMissing_Text" />
												<a
													href={`${shellBaseUrl}${localizedLinks.customerContact[language]}`}
													target="_top"
												>
													<ResourceTextInline resourceKey="SearchWatchPage_EmailMissing_LinkText" />
												</a>
											</span>
										</>
									}
								/>
							)}
						</div>{' '}
					</>
				);
			case ViewMode.Error:
				return (
					<>
						<HighlightText
							version="error"
							text={
								<>
									<span className="content">
										<ResourceTextInline resourceKey="EditSearchWatchPage_EditSearchWatchError_Text" />
									</span>
									<Link
										to={appRoutes.businessEntityPage(businessEntityId)}
										className={'mod-ctaGroup__link'}
									>
										<ResourceTextInline resourceKey="EditSearchWatchPage_Completed_ReturnToFrontPageLink" />
									</Link>
								</>
							}
						/>
					</>
				);
			case ViewMode.Complete:
				return (
					<>
						<TextHero
							icon="success"
							title={<ResourceTextInline resourceKey={'EditSearchWatchPage_Completed_Heading'} />}
							text={<ResourceTextInline resourceKey={'EditSearchWatchPage_Completed_Subheading'} />}
						/>

						{completedTypesList && (
							<div className="mod">
								<div className="atom-boxedContent">
									<ul className="atom-boxedContent__list -checked">{completedTypesList}</ul>
								</div>
							</div>
						)}

						<div className="mod-ctaGroup">
							<Link to={appRoutes.businessEntityPage(businessEntityId)} className={'mod-ctaGroup__link'}>
								<ResourceTextInline resourceKey="EditSearchWatchPage_Completed_ReturnToFrontPageLink" />
							</Link>
						</div>
					</>
				);
		}
	};

	return renderContents();
}

export default connect<StateProps, DispatchProps, unknown, AppRootState>((appRootState: AppRootState) => {
	const { parkingSpace, user, searchWatch, businessEntity } = appRootState;
	const settings = isOperationSuccess(appRootState.appState.settings)
		? appRootState.appState.settings.result
		: undefined;

	return {
		currentSearchWatch: searchWatch.currentSearchWatch,
		userEmail: user.email,
		isLoggedIn: !!user.userInfo?.userContext,
		shellBaseUrl: settings?.shellBaseUrl,
		businessEntityId: user.businessEntityId!,
		businessEntity: businessEntity.businessEntity,
		currentSpaces: parkingSpace.currentSpaces,
		editSearchWatchOperation: searchWatch.editSearchWatch,
	};
}, dispatchProps)(EditSearchWatchPage);
