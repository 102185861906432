import { getType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { User } from 'oidc-client';
import { authActions, AuthActionTypes } from '../Authentication/authenticationActions';
import { appStateActions, AppStateActionTypes } from './appStateActions';

export type UILanguage = 'fi' | 'en';
export interface UserContext {
	firstName: string;
	lastName: string;
	userId: string;
	customerId: string;
	accessToken: string;
	expiresAt: number;
	impersonatingUser?: string;
}

export interface UserInfo {
	userContext: UserContext | undefined;
	contractId: string | undefined;
}

export interface UserState {
	isRefreshing: boolean;
	isInitialized: boolean;
	userInfo: UserInfo;
	businessEntityId?: string;
	email: string | undefined;
	antiForgeryToken: string | undefined;
	uiLanguage: UILanguage;
}

const initialState: UserState = {
	isRefreshing: false,
	isInitialized: false,
	userInfo: {
		userContext: undefined,
		contractId: undefined,
	},
	email: undefined,
	antiForgeryToken: undefined,
	uiLanguage: 'fi',
};

const parseProfile = (oldState: UserContext | undefined, user: User): UserContext => {
	const impersonatingUserId = user.profile['Kojamo:ImpersonatingUser:UserId'];
	const impersonatingUserFullName = user.profile['Kojamo:ImpersonatingUser:FullName'];

	return {
		...oldState,
		customerId: user.profile.customer_id,
		firstName: user.profile.given_name!,
		lastName: user.profile.family_name!,
		userId: user.profile.sub,
		accessToken: user.access_token,
		//email: user.profile.email!, // This comes from sign-in token and doesn't update if user changes (sets) email. Use one from backend instead.
		expiresAt: user.expires_at,
		impersonatingUser: impersonatingUserId ? `${impersonatingUserFullName} (${impersonatingUserId})` : undefined,
	};
};

export const userReducer: Reducer<UserState, AuthActionTypes | AppStateActionTypes> = (
	state = initialState,
	action,
) => {
	switch (action.type) {
		case getType(authActions.refresh.request): {
			return { ...state, isRefreshing: true };
		}
		case getType(authActions.refresh.success): {
			return {
				...state,
				isRefreshing: false,
				isInitialized: true,
				userInfo: {
					...state.userInfo,
					userContext: parseProfile(state.userInfo.userContext, action.payload.user),
				},
			};
		}
		case getType(authActions.refresh.failure): {
			// Payload contains exception but it's not stored to redux now
			return { ...state, isRefreshing: false, isInitialized: true };
		}
		case getType(appStateActions.setContractId): {
			return {
				...state,
				userInfo: { ...state.userInfo, contractId: action.payload },
			};
		}
		case getType(appStateActions.getUserInfo.success): {
			return {
				...state,
				businessEntityId: action.payload.businessEntityId,
				email: action.payload.emailAddress,
				antiForgeryToken: action.payload.antiForgeryToken,
			};
		}

		case getType(appStateActions.setLanguage.success): {
			return {
				...state,
				uiLanguage: action.payload,
			};
		}
	}
	return state;
};
