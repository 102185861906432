import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { appRoutes } from '../appRoutes';
import { Spinner } from '../Components/Spinner';
import { searchWatchActions } from '../Data/searchWatchActions';
import { WatchAM } from '../Data/searchWatchApiClient';
import { useOperationComplete } from '../hooks/useOperationComplete';
import { OperationState, isAlreadyRequested } from '../operationState';
import { AppRootState } from '../Store/appRootState';

/*** Shows the authenticated user's parking space watch for editing, or watch create page if watch not yet exists. */
export default function SearchWatchPage() {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const currentSearchWatch = useSelector<AppRootState, OperationState<WatchAM> | undefined>(
		(appRootState) => appRootState.searchWatch.currentSearchWatch,
	);

	useEffect(() => {
		if (!isAlreadyRequested(currentSearchWatch)) {
			dispatch(searchWatchActions.fetchSearchWatch.request());
		}
	}, [currentSearchWatch, dispatch]);

	useOperationComplete(currentSearchWatch, {
		onSuccess: () => {
			navigate(appRoutes.editSearchWatchPage + location.search);
		},
		onError: () => {
			navigate(appRoutes.createSearchWatchPage + location.search);
		},
	});

	return <Spinner />;
}
