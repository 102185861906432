import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getShellInlineStyles, getShellStylePathFromAddress, isDevelopmentMode } from './injectShellContent';
import { AppRootState } from './Store/appRootState';
import { Helmet } from 'react-helmet';
import { isOperationSuccess } from './operationState';
import StickyRental from './Components/StickyRental';
import { UserState } from './Data/userReducer';
import { rentalActions } from './Data/rentalActions';
import { Spinner } from './Components/Spinner';
import ScrollToTop from './Components/ScrollToTop';
import { HeadScripts } from './Components/HeadScripts/HeadScripts';

type Params = { children: React.ReactElement; extraClasses?: string };
type StateProps = {
	user: UserState;
	styleSheetUrl: string | undefined;
	shellBaseUrl: string | undefined;
	isRentalInProgress: boolean;
};

const dispatch = {
	getCurrentRental: rentalActions.getCurrentRental.request,
};

type DispatchProps = typeof dispatch;

type Props = Params & StateProps & DispatchProps;

function Layout(props: Props) {
	const { children, extraClasses, styleSheetUrl, shellBaseUrl, user, getCurrentRental, isRentalInProgress } = props;
	const [inlineStyles, setInlineStyles] = useState<string | undefined>();
	const [styleUrl, setStyleUrl] = useState<string | undefined>();

	useEffect(() => {
		const shellStylePath = getShellStylePathFromAddress();

		if (shellStylePath && shellBaseUrl) {
			setStyleUrl(`${shellBaseUrl}${shellStylePath}`);
		} else if (isDevelopmentMode) {
			getShellInlineStyles((s) => {
				setInlineStyles(s);
			});
		} else {
			// Not development mode, didn't get style url from current path. Use settings from backend
			setStyleUrl(styleSheetUrl);
		}
	}, [shellBaseUrl, styleSheetUrl]);

	useEffect(() => {
		if (!user.isInitialized) {
			return;
		}
		if (user.userInfo.userContext) {
			getCurrentRental();
		}
	}, [user.isInitialized, user.userInfo.userContext, getCurrentRental]);

	if (!user.isInitialized) {
		return <Spinner />;
	}

	return (
		<>
			<Helmet>
				{styleUrl ? <link rel="stylesheet" href={styleUrl}></link> : ''}
				{!styleUrl && inlineStyles ? <style type="text/css">{inlineStyles}</style> : ''}
			</Helmet>

			<HeadScripts shellBaseUrl={shellBaseUrl} />
			<ScrollToTop />
			<div className={'container body-content parkingspace' + (extraClasses ? ' ' + extraClasses : '')}>
				<main className="main-parkingspace">
					{isRentalInProgress ? (
						<StickyRental
							showTimedoutDialog={() => {
								return;
							}}
						/>
					) : null}
					{children}
				</main>
			</div>
		</>
	);
}
export default connect<StateProps, DispatchProps, Params, AppRootState>(
	({ user, appState, rental }: AppRootState) => ({
		user,
		styleSheetUrl: isOperationSuccess(appState.settings) ? appState.settings.result.styleSheetUrl : undefined,
		shellBaseUrl: isOperationSuccess(appState.settings) ? appState.settings.result.shellBaseUrl : undefined,
		isRentalInProgress: rental.isRentalInProgress,
	}),
	dispatch,
)(Layout);
