import { ResourceTextInline, ResourceText } from '@kojamo/react-utils';
import { Overlay } from './Overlays';

export function ParkingSpaceNotFoundOverlay(props: { onClose: () => void }) {
	return (
		<Overlay
			onClose={props.onClose}
			heading={<ResourceTextInline resourceKey="Overlay_SpaceNotFound_Heading" />}
			body={
				<>
					<ResourceText resourceKey={'Overlay_SpaceNotFound_Body'} />
					<a className="link" onClick={props.onClose}>
						<ResourceTextInline resourceKey="Overlay_Close" />
						&nbsp;›
					</a>
				</>
			}
		/>
	);
}
