import { Link } from 'react-router-dom';
import { appRoutes } from '../appRoutes';
import { ResourceTextInline } from '@kojamo/react-utils';

export function BackToBusinessEntityPage({ businessEntityId }: { businessEntityId: string }) {
	return (
		<div className="mod-backLink">
			<Link to={appRoutes.businessEntityPage(businessEntityId)} className="mod-backLink__link">
				<svg width="12" height="10" viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg">
					<path
						fill="#F05183"
						d="M12 5c0 .4-.3.7-.7.7h-9L5 8.5c.3.3.3.7 0 1l-.4.2-.5-.2-4-4-.1-.2v-.6l.1-.2 4-4c.3-.2.7-.2 1 0 .2.3.2.7 0 1l-3 2.8h9.1c.4 0 .7.3.7.7z"
					></path>
				</svg>
				<ResourceTextInline resourceKey="BackToBusinessEntityPage_LinkText" />
			</Link>
		</div>
	);
}
