import RcTooltip from 'rc-tooltip';

type Props = { text: string | JSX.Element; additionalClasses?: string };

export function InfoButton(props: Props) {
	return (
		<RcTooltip overlay={<span>{props.text}</span>} placement="top" align={{ offset: [0, -10] }}>
			<button
				type="button"
				className={'infoButton' + (props.additionalClasses ? ' ' + props.additionalClasses : '')}
			/>
		</RcTooltip>
	);
}
