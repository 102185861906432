import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import parseISO from 'date-fns/parseISO';
import addMinutes from 'date-fns/addMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import getServerTimeUtc from './serverTime';
import { ResourceTextInline } from '@kojamo/react-utils';
import { AppRootState } from '../Store/appRootState';
import { appRoutes } from '../appRoutes';
import { isOperationSuccess } from '../operationState';
import { RentalTimeoutDialog } from './Overlays';
import { rentalActions } from '../Data/rentalActions';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

declare global {
	interface Window {
		rentalTimeOut?: () => void;
		rentalTimeLowNotice?: () => void;
	}
}

export interface IStickyRentalParameterProps {
	showTimedoutDialog: () => void;
}

interface IStateProps {
	currentRentalId: string | undefined;
	expirationTime: Date | undefined;
}

const ShowTimeoutDialogWhenRemainingMinutes = 5;

const StickyUpdateIntervalInSeconds = 5;

export default function StickyRental({ showTimedoutDialog }: IStickyRentalParameterProps) {
	const [lastTimerId, setLastTimerId] = useState<number | undefined>();
	const [minutesToExpiration, setMinutesToExpiration] = useState(-1);
	const [showTimeoutDialog, setShowTimeoutDialog] = useState(false);

	const dispatch = useDispatch();
	const { currentRentalId, expirationTime } = useSelector<AppRootState, IStateProps>(({ rental: parkingSpaces }) => {
		const rental =
			parkingSpaces.isRentalInProgress && isOperationSuccess(parkingSpaces.currentRental)
				? parkingSpaces.currentRental.result
				: undefined;

		return {
			currentRentalId: rental?.id,
			expirationTime: rental?.expirationTime,
		};
	});

	const location = useLocation();
	const navigate = useNavigate();

	const isRentalPage = location.pathname === appRoutes.rentalPage;

	// make sure we interpret the time as a UTC Date object. If we get anything else, try to compensate

	const interpretTime = useCallback((time: any) => {
		// strings are interpreted as ISO standard datetime strings
		if (typeof time === 'string') {
			return parseISO(time);
		}

		// otherwise we check if we have a timezone, and move it into UTC
		const timestamp = time as Date;
		if (timestamp.getTimezoneOffset() !== 0) {
			return addMinutes(timestamp, timestamp.getTimezoneOffset());
		}

		// should be UTC timestamp already
		return timestamp;
	}, []);
	const rentalProcessTimedOut = useCallback(() => {
		if (!currentRentalId) {
			return;
		}

		dispatch(rentalActions.abortRental.request(currentRentalId));
		if (isRentalPage) {
			navigate(appRoutes.rentalTimeOut);
		} else {
			showTimedoutDialog();
		}
	}, [currentRentalId, dispatch, isRentalPage, navigate, showTimedoutDialog]);

	const updateRemainingTime = useCallback(
		(initial = false) => {
			const utcnow = getServerTimeUtc();
			const rentalExpirationTime = interpretTime(expirationTime);
			const updatedSecondsRemaining = differenceInSeconds(rentalExpirationTime, utcnow);
			let updatedMinutesRemaining = Math.ceil(updatedSecondsRemaining / 60);
			if (updatedMinutesRemaining < 1) {
				updatedMinutesRemaining = 0;
			}

			if (!initial) {
				if (
					minutesToExpiration === ShowTimeoutDialogWhenRemainingMinutes + 1 &&
					updatedMinutesRemaining === ShowTimeoutDialogWhenRemainingMinutes
				) {
					setShowTimeoutDialog(true);
				}
			}

			// check if we timed out
			if (differenceInSeconds(rentalExpirationTime, utcnow) < 0) {
				rentalProcessTimedOut();
				return false;
			}

			setMinutesToExpiration(updatedMinutesRemaining);

			return true;
		},
		[
			expirationTime,
			interpretTime,
			minutesToExpiration,
			setShowTimeoutDialog,
			rentalProcessTimedOut,
			setMinutesToExpiration,
		],
	);

	const onCloseDialog = () => {
		setShowTimeoutDialog(false);
	};

	const timedUpdate = useCallback(() => {
		if (updateRemainingTime()) {
			setLastTimerId(window.setTimeout(timedUpdate, StickyUpdateIntervalInSeconds * 1000));
		}
	}, [updateRemainingTime, setLastTimerId]);

	useEffect(() => {
		if (minutesToExpiration == -1) {
			updateRemainingTime(true);
			setLastTimerId(window.setTimeout(timedUpdate, StickyUpdateIntervalInSeconds * 1000));
		}

		window.rentalTimeOut = () => {
			rentalProcessTimedOut();
		};
		window.rentalTimeLowNotice = () => {
			setShowTimeoutDialog(true);
		};

		return () => {
			if (lastTimerId) {
				clearTimeout(lastTimerId);
			}
			setLastTimerId(undefined);

			delete window.rentalTimeOut;
			delete window.rentalTimeLowNotice;
		};
	}, [lastTimerId, minutesToExpiration, rentalProcessTimedOut, timedUpdate, updateRemainingTime]);

	useEffect(() => {
		updateRemainingTime();
	}, [expirationTime, updateRemainingTime]);

	return (
		<>
			{isRentalPage ? (
				<StickyWithoutLink minutesToExpiration={minutesToExpiration} />
			) : (
				<StickyWithLink minutesToExpiration={minutesToExpiration} />
			)}
			{showTimeoutDialog && (
				<RentalTimeoutDialog minutesToExpiration={minutesToExpiration} onClose={onCloseDialog} />
			)}
		</>
	);
}

function StickyWithoutLink({ minutesToExpiration }: { minutesToExpiration: number }) {
	return (
		<div className="sticky-continue-rent-flow">
			<div className="sticky-continue-rent-flow__link">
				<span className="sticky-continue-rent-flow__text">
					{isNaN(minutesToExpiration) ? (
						<ResourceTextInline resourceKey="StickyRental_ApplicationInProgressNoMinutes" />
					) : (
						<ResourceTextInline
							resourceKey="StickyRental_ApplicationInProgress"
							parameters={[minutesToExpiration.toString()]}
						/>
					)}{' '}
				</span>
			</div>
		</div>
	);
}

function StickyWithLink({ minutesToExpiration }: { minutesToExpiration: number }) {
	return (
		<div className="sticky-continue-rent-flow">
			<Link to={appRoutes.rentalPage} className="sticky-continue-rent-flow__link">
				<span className="sticky-continue-rent-flow__text">
					{isNaN(minutesToExpiration) ? (
						<ResourceTextInline resourceKey="StickyRental_ApplicationInProgressNoMinutes" />
					) : (
						<ResourceTextInline
							resourceKey="StickyRental_ApplicationInProgress"
							parameters={[minutesToExpiration.toString()]}
						/>
					)}{' '}
					<strong>
						<ResourceTextInline resourceKey="StickyRental_ReturnToApplication" />
						&nbsp;›
					</strong>
				</span>
			</Link>
		</div>
	);
}
