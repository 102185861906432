import { ActionType, createAction } from 'typesafe-actions';
//import { TelemetryPageType } from './TelemetryManager';

//export type TelemetryPageType = 'apartment' | 'searchResults' | 'article' | 'notFound' | undefined;

export const pageViewTelemetryActions = {
	navigatedToNewUrl: createAction('pageViewTelemetry/NEW_URL')<string>(),
	pageComplete: createAction('pageViewTelemetry/PAGE_COMPLETE')<IPageViewParams>(),
	eventSent: createAction('pageViewTelemetry/EVENT_SENT')<void>(),
};

export interface IPageViewParams {
	businessEntityNumber?: number;
	pageType?: string; // TelemetryPageType;
	data?: any;
}

export type PageViewTelemetryActionTypes = ActionType<typeof pageViewTelemetryActions>;
