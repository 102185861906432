import { fork } from 'redux-saga/effects';
import { appInitSaga } from '../Data/appInitSaga';
import { authSaga } from '../Authentication/authSaga';
import appStateSaga from '../Data/appStateSaga';
import businessEntitySaga from '../Data/businessEntitySaga';
import parkingSpaceSaga from '../Data/parkingSpaceSaga';
import rentalSaga from '../Data/rentalSaga';
import terminationSaga from '../Data/terminationSaga';
import searchWatchSaga from '../Data/searchWatchSaga';
import { pageViewTelemetrySaga } from '../Telemetry/pageViewTelemetrySaga';

export default function* rootSaga() {
	yield fork(authSaga);
	yield fork(appInitSaga);
	yield fork(appStateSaga);
	yield fork(rentalSaga);
	yield fork(terminationSaga);
	yield fork(businessEntitySaga);
	yield fork(parkingSpaceSaga);
	yield fork(searchWatchSaga);
	yield fork(pageViewTelemetrySaga);
}
