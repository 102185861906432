import React, { useEffect, useState } from 'react';
import { BusinessEntityTitleAM } from '../Data/businessEntityApiClient';
import { isAlreadyRequested, isOperationSuccess, OperationState } from '../operationState';
import { AppRootState } from '../Store/appRootState';
import { connect } from 'react-redux';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { useNavigate } from 'react-router';
import { ResourceTextInline, useResource } from '@kojamo/react-utils';
import { Spinner } from '../Components/Spinner';
import { businessEntityActions } from '../Data/businessEntityActions';
import { appRoutes } from '../appRoutes';

type StateProps = {
	businessEntities: OperationState<BusinessEntityTitleAM[]> | undefined;
};

const dispatchProps = {
	fetchBusinessEntities: businessEntityActions.fetchBusinessEntities.request,
};

type DispatchProps = typeof dispatchProps;

type Props = StateProps & DispatchProps;

function BusinessEntitySelection(props: Props) {
	const { businessEntities, fetchBusinessEntities } = props;
	const { getResourceText } = useResource();
	useEffect(() => {
		if (!isAlreadyRequested(businessEntities)) {
			fetchBusinessEntities();
		}
	}, [fetchBusinessEntities, businessEntities]);

	const navigate = useNavigate();

	const [municipality, setMunicipality] = useState<string | undefined>(undefined);

	const [businessEntityId, setBusinessEntityId] = useState<string>('');

	const onMunicipalityChanged = (ev: React.ChangeEvent<HTMLSelectElement>) => {
		setMunicipality(ev.target.value);
		setBusinessEntityId('');
	};

	const onBusinessEntityChanged = (ev: React.ChangeEvent<HTMLSelectElement>) => {
		setBusinessEntityId(ev.target.value);
	};

	const onShowParkingSpacesClick = () => {
		if (!businessEntityId || businessEntityId.length == 0) {
			return;
		}
		navigate(appRoutes.businessEntityPage(businessEntityId));
	};

	const businessEntitiesByMunicipality = isOperationSuccess(businessEntities)
		? groupBy(businessEntities.result, (r) => r.municipality)
		: {};

	const municipalities = Object.keys(businessEntitiesByMunicipality).sort();

	const businessEntitiesList = !!municipality
		? sortBy(businessEntitiesByMunicipality[municipality], (b) => b.name)
		: [];

	if (!isOperationSuccess(businessEntities)) {
		return <Spinner />;
	}

	return (
		<>
			<form id="locationform">
				<div className="input">
					<label htmlFor="city">
						<ResourceTextInline resourceKey="LocationSelect_City_Label" />
					</label>
					<div className="select">
						<select
							name="city"
							form="locationform"
							required
							onChange={onMunicipalityChanged}
							defaultValue={''}
						>
							<option value="" disabled hidden key={'default'}>
								{getResourceText('LocationSelect_City_Placeholder')}
							</option>
							{municipalities.map((m) => (
								<option key={m} value={m}>
									{m}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="input">
					<label htmlFor="address">
						<ResourceTextInline resourceKey="LocationSelect_BusinessEntity_Label" />
					</label>
					<div className={'select' + (municipality == undefined ? ' disabled' : '')}>
						<select
							name="address"
							form="locationform"
							required
							onChange={onBusinessEntityChanged}
							value={businessEntityId}
						>
							<option value={''} disabled hidden key={'default'}>
								{getResourceText('LocationSelect_BusinessEntity_Placeholder')}
							</option>
							{businessEntitiesList.map((be) => (
								<option key={be.id} value={be.id}>
									{be.name}
								</option>
							))}
						</select>
					</div>
				</div>
			</form>
			<div className="button__container">
				<button
					className="button"
					type="submit"
					form="locationform"
					value="Näytä autopaikat"
					onClick={onShowParkingSpacesClick}
				>
					<ResourceTextInline resourceKey="LocationSelect_ButtonText" />
				</button>
			</div>
		</>
	);
}

export default connect<StateProps, DispatchProps, unknown, AppRootState>(
	({ businessEntity }) => ({
		businessEntities: businessEntity.businessEntities,
	}),
	dispatchProps,
)(BusinessEntitySelection);
