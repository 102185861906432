import { ReactNode } from 'react';
import { formatDate } from '../dateUtils';
import { CurrentParkingSpaceAM } from '../Data/parkingSpaceApiClient';
import { ParkingSpaceAM } from '../Data/ParkingSpaceAM';

import { ElectricChargingIcon } from './ElectricChargingIcon';

import { ParkingSpaceBasicInformation, ParkingSpacePriceDetails } from './ParkingSpace/';
import { ResourceText, useResource, ResourceTextInline } from '@kojamo/react-utils';

export interface ParkingSpaceInformationProps {
	space: ParkingSpaceAM;
	title: string | ReactNode;
	subtitle?: string | ReactNode;
	children?: ReactNode;
	electricChargingInfoFormat: 'Compact' | 'Detailed';
}

export function ParkingSpaceInformation(props: ParkingSpaceInformationProps) {
	const { getResourceText } = useResource();
	const { space, title, subtitle, electricChargingInfoFormat, children } = props;
	return (
		<>
			<div className="atom-singleParkingSpace__iconBadge"></div>
			<h3 className="atom-singleParkingSpace__title">{title}</h3>
			{subtitle ? <p>{subtitle}</p> : ''}

			<ParkingSpaceBasicInformation
				space={space}
				children={children}
				electricChargingInfoFormat={electricChargingInfoFormat}
			/>

			{space.supportsElectricCharging && electricChargingInfoFormat === 'Detailed' && (
				<>
					<h3 className="atom-singleParkingSpace__title">
						<ResourceTextInline resourceKey="ParkingSpace_NewParkingSpace_ElectricCharging_Title" />
						<ElectricChargingIcon />
					</h3>
					{space.electricChargingCompany && (
						<ResourceText
							resourceKey="ParkingSpace_NewParkingSpace_ElectricCharging_Description"
							parameters={[
								getResourceText(
									'ElectricChargingCompany_ContactUrl_' +
										space.electricChargingCompany.replaceAll(' ', ''),
								),
							]}
							linkOpenMode="blank"
						/>
					)}

					<ParkingSpacePriceDetails space={space} />
				</>
			)}
		</>
	);
}

export const getContractStartDateResourceKey = (startDate: Date) =>
	startDate < new Date() ? 'ParkingSpace_ContractStartDate_Past' : 'ParkingSpace_ContractStartDate_Future';

export function CurrentParkingSpaceInformation(props: {
	space: CurrentParkingSpaceAM;
	onTerminateClick?: (space: CurrentParkingSpaceAM) => void;
}) {
	const { space } = props;
	const onTerminateButtonClick = () => {
		props.onTerminateClick!(props.space as CurrentParkingSpaceAM);
		return false;
	};
	return (
		<div className="atom-singleParkingSpace">
			<div className="atom-singleParkingSpace__iconBadge"></div>
			<ParkingSpaceInformation title={''} space={space} electricChargingInfoFormat="Compact">
				<>
					<li className="atom-singleParkingSpace__singleDetail">
						<span className="detailTitle">
							<ResourceTextInline
								resourceKey={getContractStartDateResourceKey(space.contractStartDate)}
							/>
						</span>
						<span>{formatDate(space.contractStartDate)}</span>
					</li>
					{space.contractEndDate && (
						<li className="atom-singleParkingSpace__singleDetail">
							<span className="detailTitle">
								<ResourceTextInline resourceKey="ParkingSpace_ContractEndDate" />
							</span>
							<span>{formatDate(space.contractEndDate)}</span>
						</li>
					)}
					{space.terminationAllowed && typeof props.onTerminateClick !== 'undefined' && (
						<a href="#" className="atom-singleParkingSpace__link" onClick={onTerminateButtonClick}>
							{space.contractEndDate ? (
								<ResourceTextInline resourceKey="ParkingSpace_Terminate_ToEndEarlier" />
							) : (
								<ResourceTextInline resourceKey="ParkingSpace_Terminate" />
							)}
						</a>
					)}
				</>
			</ParkingSpaceInformation>
		</div>
	);
}
