import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { appRoutes } from '../appRoutes';

import { TextHero } from '../Components/TextHero';
import { terminationActions } from '../Data/terminationActions';
import { formatDate } from '../dateUtils';
import { ApiOperationState, isAlreadyRequested, isOperationSuccess, OperationState } from '../operationState';
import { TerminationCompletionInfoAM } from '../Data/rentalApiClient';
import { AppRootState } from '../Store/appRootState';
import { notifyShellNavigatedTo } from '../shellIntegration';
import { pageViewTelemetryActions } from '../Telemetry/pageViewTelemetryActions';
import { BusinessEntityFullAM } from '../Data/businessEntityApiClient';
import { businessEntityActions } from '../Data/businessEntityActions';
import { addTelemetryEventGA4, GA4EventAction } from '../Telemetry/TelemetryGA4';
import { ResourceText, ResourceTextInline } from '@kojamo/react-utils';
import { useAppLanguage } from '../hooks/useAppLanguage';

interface StateProps {
	terminationCompletionInfo: OperationState<TerminationCompletionInfoAM> | undefined;
	userEmail: string;
	usersBusinessEntityId: string | undefined;
	businessEntity: OperationState<BusinessEntityFullAM> | undefined;
}

export default function TerminationCompletePage() {
	const { language } = useAppLanguage();
	const dispatch = useDispatch();
	const stateProps = useSelector<AppRootState, StateProps>(({ termination, user, businessEntity }) => ({
		userEmail: user.email!,
		terminationCompletionInfo: termination.terminationCompletionInfo,
		usersBusinessEntityId: user.businessEntityId,
		businessEntity: businessEntity.businessEntity,
	}));

	const [telemetryEventSent, setTelemetryEventSent] = useState<boolean>(false);

	useEffect(() => {
		dispatch(terminationActions.finalizeTermination.request());
	}, [dispatch]);

	useEffect(() => {
		if (
			stateProps.usersBusinessEntityId &&
			!isAlreadyRequested(stateProps.businessEntity, stateProps.usersBusinessEntityId)
		) {
			dispatch(businessEntityActions.fetchBusinessEntity.request(stateProps.usersBusinessEntityId));
		}
	}, [stateProps.usersBusinessEntityId, dispatch, stateProps.businessEntity]);

	useEffect(() => {
		notifyShellNavigatedTo(appRoutes.terminationComplete[language]);
	}, [language]);

	useEffect(() => {
		if (isOperationSuccess(stateProps.businessEntity, stateProps.usersBusinessEntityId)) {
			dispatch(
				pageViewTelemetryActions.pageComplete({
					businessEntityNumber: stateProps.businessEntity.result.number,
					pageType: 'TerminationComplete',
				}),
			);
		}
	}, [stateProps.businessEntity, dispatch, stateProps.usersBusinessEntityId]);

	useEffect(() => {
		if (
			isOperationSuccess(stateProps.businessEntity, stateProps.usersBusinessEntityId) &&
			isOperationSuccess(stateProps.terminationCompletionInfo) &&
			!telemetryEventSent
		) {
			addTelemetryEventGA4({ action: GA4EventAction.parkingspace_terminationcomplete });
			setTelemetryEventSent(true);
		}
	}, [
		stateProps.businessEntity,
		stateProps.terminationCompletionInfo,
		telemetryEventSent,
		stateProps.usersBusinessEntityId,
		language,
	]);

	const lastDayOfContractTerm = isOperationSuccess(stateProps.terminationCompletionInfo)
		? stateProps.terminationCompletionInfo.result.lastDayOfContractTerm
		: undefined;

	return (
		<>
			<TextHero
				icon="success"
				title={<ResourceTextInline resourceKey={'TerminationCompletePage_Heading'} />}
				text={
					<ResourceText
						resourceKey={'TerminationCompletePage_Subheading'}
						parameters={[stateProps.userEmail]}
					/>
				}
			/>
			<div className="mod atom-boxedContent -alignCenter">
				{stateProps.terminationCompletionInfo?.status === ApiOperationState.Succeeded && (
					<ResourceText
						resourceKey="TerminationCompletePage_TerminationDateInfo"
						parameters={[formatDate(lastDayOfContractTerm!)]}
					/>
				)}
			</div>
			<div className="mod-ctaGroup">
				<Link
					to={appRoutes.businessEntityPage(stateProps.usersBusinessEntityId)}
					className={'mod-ctaGroup__link'}
				>
					<ResourceTextInline resourceKey="TerminationCompletePage_ReturnToFrontPageLink" />
				</Link>
			</div>
		</>
	);
}
