import { useEffect, useState, FormEvent } from 'react';
import { ResourceTextInline } from '@kojamo/react-utils';
import { getParkingSpaceCharacteristics } from '../Common/getParkingSpaceCharacteristics';

function handleTypeChange(typeSelection: string[], isChecked: boolean, type: string) {
	if (isChecked) {
		typeSelection.push(type);
	} else {
		const index = getTypeIndex(typeSelection, type);
		if (index > -1) {
			typeSelection.splice(index, 1);
		}
	}

	return typeSelection;
}

function isTypeSelected(selectedTypes: string[] | undefined, type: string) {
	if (selectedTypes) {
		const index = getTypeIndex(selectedTypes, type);
		return index > -1;
	}

	return false;
}

function getTypeIndex(types: string[], type: string) {
	return types.findIndex((t: string) => {
		return t === type;
	});
}

export function SearchWatchTypeSelector({
	options,
	initialSelection,
	onChange,
}: {
	options: string[];
	initialSelection: string[] | undefined;
	onChange: (typeSelection: string[]) => void;
}) {
	const [typeSelection, setTypeSelection] = useState<string[]>([]);

	useEffect(() => {
		if (initialSelection) {
			const selectedOptions = initialSelection.filter((type) => options.includes(type));
			setTypeSelection(selectedOptions);
		}
	}, [initialSelection, options]);

	const typeChanged = (event: FormEvent<HTMLInputElement>) => {
		const target = event.currentTarget;
		const isChecked = target.checked;
		const type = target.id;
		setTypeSelection(handleTypeChange(typeSelection, isChecked, type));

		if (onChange) {
			onChange(typeSelection);
		}
	};

	return (
		<fieldset className="atom-boxedContent__inputGroup">
			{options.sort().map((type) => (
				<div className="input checkbox" key={type}>
					<input
						id={type}
						className="input"
						type="checkbox"
						defaultChecked={isTypeSelected(initialSelection, type)}
						onChange={typeChanged}
					/>
					<label htmlFor={type}>
						<ResourceTextInline resourceKey={getParkingSpaceCharacteristics(type)} />
					</label>
				</div>
			))}
		</fieldset>
	);
}
