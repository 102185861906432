import { ResourceText, ResourceTextInline } from '@kojamo/react-utils';
import { Overlay } from './Overlays';

export function RentalBlockedDebtCheckOverlay(props: { rentalPaymentLink: string; onClose: () => void }) {
	return (
		<Overlay
			onClose={props.onClose}
			heading={<ResourceTextInline resourceKey="Overlay_RentalBlockedDebtCheck_Heading" />}
			body={
				<>
					<ResourceText resourceKey="Overlay_RentalBlockedDebtCheck_Body" />
					<a className="button" href={props.rentalPaymentLink} target="_top">
						<ResourceTextInline resourceKey="Overlay_RentalBlockedDebtCheck_PayRent" />
					</a>
					<a className="link" onClick={props.onClose}>
						<ResourceTextInline resourceKey="Overlay_Close" />
						&nbsp;›
					</a>
				</>
			}
		/>
	);
}

export function RentalBlockedOverlay(props: { reasonCode: string; rentalPaymentLink: string; onClose: () => void }) {
	const { reasonCode, ...rest } = props;
	if (reasonCode === 'DebtCheckDenied') {
		return <RentalBlockedDebtCheckOverlay {...rest} />;
	}

	return (
		<Overlay
			onClose={props.onClose}
			heading={<ResourceTextInline resourceKey="Overlay_RentalBlocked_Heading" />}
			body={
				<>
					<ResourceText resourceKey={'Overlay_RentalBlocked_Body'} />
					<a className="link" onClick={props.onClose}>
						<ResourceTextInline resourceKey="Overlay_Close" />
						&nbsp;›
					</a>
				</>
			}
		/>
	);
}
