import { appStateActions, AppStateActionTypes } from './appStateActions';
import { getType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { OperationFailed, OperationRequested, OperationState, OperationSuccess } from '../operationState';
import { SettingsAM } from './settingsApiClient';

export interface ParkingSpaceAppState {
	settings: OperationState<SettingsAM> | undefined;
}

const initialState: ParkingSpaceAppState = {
	settings: undefined,
};

export const appStateReducer: Reducer<ParkingSpaceAppState, AppStateActionTypes> = (state = initialState, action) => {
	switch (action.type) {
		case getType(appStateActions.fetchAppSettings.request): {
			return { ...state, settings: OperationRequested() };
		}
		case getType(appStateActions.fetchAppSettings.success): {
			return { ...state, settings: OperationSuccess(action.payload) };
		}
		case getType(appStateActions.fetchAppSettings.failure): {
			return { ...state, settings: OperationFailed(action.payload) };
		}
	}
	return state;
};
