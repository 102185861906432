import { AnyObject } from '../Data/anyObject';

export enum GA4EventAction {
	parkingspace_reservation = 'parkingspace_reservation',
	parkingspace_saverental = 'parkingspace_saverental',
	parkingspace_rentalcomplete = 'parkingspace_rentalcomplete',
	parkingspace_searchwatch_created = 'parkingspace_searchwatch_created',
	parkingspace_terminationcomplete = 'parkingspace_terminationcomplete',
	parkingspace_login_businessentitypage = 'parkingspace_login_businessentitypage',
	parkingspace_searchwatch_edited = 'parkingspace_searchwatch_edited',
	parkingspace_changebusinessentitysticky_open = 'parkingspace_changebusinessentitysticky_open',
	parkingspace_searchwatch_removed = 'parkingspace_searchwatch_removed',
	parkingspace_login_homepage = 'parkingspace_login_homepage',
}

interface GA4Event extends AnyObject {
	action: GA4EventAction;
}

export function addTelemetryEventGA4(event: GA4Event) {
	const eventObject = {
		event: 'parkingspace',
		category: 'autopaikat',
		...event,
		label: `${location.pathname}${location.search}`,
		ga4_event: true,
	};

	const dataLayer = (window as any).dataLayer;
	if (dataLayer) {
		dataLayer.push(eventObject);
	}
}
