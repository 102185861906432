import { ReactNode } from 'react';
import { ParkingSpaceInformationElectricCharging } from '.';
import { ParkingSpaceAM } from '../../Data/ParkingSpaceAM';
import { ResourceTextInline } from '@kojamo/react-utils';
import { getParkingSpaceCharacteristics } from '../../Common/getParkingSpaceCharacteristics';

export function ParkingSpaceBasicInformation(props: {
	space: ParkingSpaceAM;
	children?: ReactNode;
	electricChargingInfoFormat: 'Compact' | 'Detailed';
}) {
	const { space, electricChargingInfoFormat, children } = props;

	return (
		<ul className="atom-singleParkingSpace__details">
			<li className="atom-singleParkingSpace__singleDetail">
				<span className="detailTitle">
					<ResourceTextInline resourceKey="ParkingSpace_Number" />
				</span>
				<span>{space.name}</span>
			</li>
			<li className="atom-singleParkingSpace__singleDetail">
				<span className="detailTitle">
					<ResourceTextInline resourceKey="ParkingSpace_StreetAddress" />
				</span>
				<span>{space.streetAddress}</span>
			</li>
			<li className="atom-singleParkingSpace__singleDetail">
				<span className="detailTitle">
					<ResourceTextInline resourceKey="ParkingSpace_Type" />
				</span>
				<span>
					<ResourceTextInline resourceKey={getParkingSpaceCharacteristics(space.characteristics)} />
				</span>
			</li>
			<li className="atom-singleParkingSpace__singleDetail">
				<span className="detailTitle">
					<ResourceTextInline resourceKey="ParkingSpace_Rent" />
				</span>
				<span>
					{space.rent} <ResourceTextInline resourceKey="ParkingSpace_RentAmountSuffix" />
				</span>
			</li>
			{space.supportsElectricCharging && electricChargingInfoFormat === 'Compact' && (
				<ParkingSpaceInformationElectricCharging space={space} />
			)}
			{children}
		</ul>
	);
}
