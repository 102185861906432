import { UILanguage } from '../Data/userReducer';

const LOGIN_PATHS = {
	fi: '/autopaikat-login',
	en: '/en/parking-space-login',
};

export function triggerParkingSpaceLogin(shellBaseUrl: string, language: UILanguage) {
	const path = LOGIN_PATHS[language];
	window.parent.location.href = shellBaseUrl + path;
}
