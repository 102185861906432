import { IdentificationContext } from './Data/rentalApiClient';

export const appRoutes = {
	homePage: '/',
	rentalPage: '/rental',
	terminationPage: '/terminate',
	businessEntityPage: (id: string | undefined) => `/business-entity/${id || ':businessEntityId'}`,
	startIdentification: (context: IdentificationContext | undefined) =>
		`/aloita-tunnistautuminen/${context || ':identificationContext'}`,
	rentalComplete: { fi: '/vuokraus-valmis', en: '/rental-ready' },
	rentalTimeOut: '/rental-expired',
	searchWatchPage: '/watch',
	createSearchWatchPage: '/watch/create',
	editSearchWatchPage: '/watch/edit',
	searchWatchRemovePage: `/watch/delete`,
	searchWatchRemovalCompleted: '/watch-removed',
	terminationComplete: { fi: '/irtisanominen-valmis', en: '/termination-ready' },
};
