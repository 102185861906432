import { ParkingSpaceAM } from '../../Data/ParkingSpaceAM';
import { ResourceTextInline } from '@kojamo/react-utils';

import {
	ParkingSpaceElectricChargingInformationWithCompany,
	ParkingSpaceElectricChargingInformationNoCompany,
} from './';

export function ParkingSpaceInformationElectricCharging(props: { space: ParkingSpaceAM }) {
	const { space } = props;

	return (
		<li className="atom-singleParkingSpace__singleDetail">
			<span className="detailTitle">
				<ResourceTextInline resourceKey="ParkingSpace_ElectricCharging_Compact" />
			</span>
			{space.electricChargingPrice &&
			space.electricChargingCompany &&
			space.electricChargingType !== 'NotInUse' ? (
				<ParkingSpaceElectricChargingInformationWithCompany space={space} />
			) : (
				<ParkingSpaceElectricChargingInformationNoCompany space={space} />
			)}
		</li>
	);
}
