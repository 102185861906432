import * as React from 'react';
import fi from 'date-fns/locale/fi';
import Datepicker, { registerLocale } from 'react-datepicker';
import { connect } from 'react-redux';
import { AppRootState } from '../Store/appRootState';
import { UILanguage } from '../Data/userReducer';

registerLocale('fi', fi);

interface ILumoDatepickerProps {
	selectedDate?: Date;
	minDate?: Date;
	maxDate?: Date;
	includeDates?: Date[];
	onSetDate?: (selectedDate: Date) => any;
}

export type LumoDatepickerProps = ILumoDatepickerProps & ILumoDatepickerPropsStateProps;

export interface ILumoDatepickerPropsStateProps {
	language: UILanguage;
}
interface ILumoDatepickerState {
	selectedDate?: Date;
}

/*** Component for selecting dates without times (selected date always corresponds to 0:00 in UTC timezone) */
class LumoDatepicker extends React.Component<LumoDatepickerProps, ILumoDatepickerState> {
	constructor(props: LumoDatepickerProps, context?: any) {
		super(props, context);
		this.state = { selectedDate: props.selectedDate || new Date(Date.now()) };
	}

	componentDidUpdate(prevProps: LumoDatepickerProps) {
		if (prevProps.selectedDate != this.props.selectedDate) {
			this.setState({ selectedDate: this.props.selectedDate });
		}
	}

	private onChange = (date: Date) => {
		// If datepicker's selected date is changed over the summer time limit, the time and offset are not in sync (changes by an hour).
		// For example if selected date is first 2021-03-19 (2:00 +2) and then changed to first of april, the date comes out as 2021-04-01 2:00 +3.
		// That's why we construct a new date in utc timezone here.
		// NOTICE: this destroys possible time information, so date picker can now only be used for dates without time.
		const utcDateWithoutTime = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

		this.setState({ selectedDate: utcDateWithoutTime });

		if (this.props.onSetDate) {
			this.props.onSetDate(utcDateWithoutTime);
		}
	};

	render() {
		return (
			<Datepicker
				className="input-date__input"
				selected={this.state.selectedDate}
				onChange={this.onChange}
				minDate={this.props.minDate}
				maxDate={this.props.maxDate}
				locale={this.props.language}
				dateFormat="d.M.yyyy"
				includeDates={this.props.includeDates}
				onFocus={(e) => e.target.blur()}
			/>
		);
	}
}
const mapStateToProps = ({ user }: AppRootState): ILumoDatepickerPropsStateProps => ({
	language: user.uiLanguage,
});
export default connect(mapStateToProps)(LumoDatepicker);
