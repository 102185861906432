import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { appRoutes } from '../appRoutes';
import { getContractStartDateResourceKey, ParkingSpaceInformation } from '../Components/ParkingSpaceInformation';
import { ResourceTextInline } from '@kojamo/react-utils';
import { TextHero } from '../Components/TextHero';
import { ApiOperationState, isAlreadyRequested, isOperationSuccess, OperationState } from '../operationState';
import { ParkingSpaceRentalAM } from '../Data/rentalApiClient';
import { AppRootState } from '../Store/appRootState';
import { Spinner } from '../Components/Spinner';
import { notifyShellNavigatedTo } from '../shellIntegration';
import { addDays, formatDate } from '../dateUtils';
import { pageViewTelemetryActions } from '../Telemetry/pageViewTelemetryActions';
import { BusinessEntityFullAM } from '../Data/businessEntityApiClient';
import { businessEntityActions } from '../Data/businessEntityActions';
import { addTelemetryEventGA4, GA4EventAction } from '../Telemetry/TelemetryGA4';
import { useAppLanguage } from '../hooks/useAppLanguage';

interface StateProps {
	currentRental: OperationState<ParkingSpaceRentalAM | null> | undefined;
	userEmail: string;
	businessEntity: OperationState<BusinessEntityFullAM> | undefined;
}

const dispatchProps = {
	fetchBusinessEntity: businessEntityActions.fetchBusinessEntity.request,
	pageComplete: pageViewTelemetryActions.pageComplete,
};

type DispatchProps = typeof dispatchProps;
type Props = StateProps & DispatchProps;

function RentalCompletePage({ currentRental, userEmail, businessEntity, fetchBusinessEntity, pageComplete }: Props) {
	const { language } = useAppLanguage();
	const rental = currentRental?.status === ApiOperationState.Succeeded ? currentRental.result : null;

	useEffect(() => {
		if (currentRental?.status === ApiOperationState.Requested) {
			return;
		}
		if (currentRental?.status !== ApiOperationState.Succeeded || rental === null) {
			return;
		}
		notifyShellNavigatedTo(appRoutes.rentalComplete[language]);
		if (isOperationSuccess(businessEntity)) {
			addTelemetryEventGA4({ action: GA4EventAction.parkingspace_rentalcomplete });
		}
	}, [currentRental, businessEntity, rental, language]);

	useEffect(() => {
		if (rental?.businessEntityId && !isAlreadyRequested(businessEntity, rental.businessEntityId)) {
			fetchBusinessEntity(rental.businessEntityId);
		}
	}, [rental, businessEntity, fetchBusinessEntity]);

	useEffect(() => {
		if (isOperationSuccess(businessEntity) && businessEntity?.result?.id === rental?.businessEntityId) {
			pageComplete({
				businessEntityNumber: businessEntity?.result.number,
				pageType: 'RentalComplete',
			});
		}
	}, [businessEntity, rental, pageComplete]);

	if (currentRental?.status == ApiOperationState.Requested) {
		return <Spinner />;
	} else if (currentRental?.status != ApiOperationState.Succeeded) {
		return null;
	} else if (rental == null) {
		// Rental not in progress
		return null;
	}

	return (
		<>
			<TextHero
				icon="success"
				title={<ResourceTextInline resourceKey={'RentalCompletePage_Heading'} />}
				text={
					<ResourceTextInline
						allowHtml={true}
						resourceKey={'RentalCompletePage_Subheading'}
						parameters={[userEmail]}
					/>
				}
			/>
			<div className="mod">
				<div className="atom-singleParkingSpace">
					<ParkingSpaceInformation
						title={<ResourceTextInline resourceKey="ParkingSpace_RentedParkingSpace_Title" />}
						space={rental.parkingSpace}
						electricChargingInfoFormat="Detailed"
					>
						<li className="atom-singleParkingSpace__singleDetail">
							<span className="detailTitle">
								<ResourceTextInline
									resourceKey={getContractStartDateResourceKey(rental.rentalStartDate!)}
								/>
							</span>
							<span>{formatDate(rental.rentalStartDate!)}</span>
						</li>
					</ParkingSpaceInformation>
					{!!rental.terminatedParkingSpaceId ? (
						<p className="atom-singleParkingSpace__note -icon">
							<ResourceTextInline
								resourceKey="ParkingSpaceConfirmation_OldSpaceInfo"
								parameters={[formatDate(addDays(rental.rentalStartDate!, -1))]}
							/>
						</p>
					) : null}
				</div>
			</div>
			<div className="mod-ctaGroup">
				<Link
					to={appRoutes.businessEntityPage(currentRental.result?.businessEntityId)}
					className={'mod-ctaGroup__link'}
				>
					<ResourceTextInline resourceKey="RentalCompletePage_ReturnToFrontPageLink" />
				</Link>
			</div>
		</>
	);
}

export default connect<StateProps, DispatchProps, unknown, AppRootState>(
	({ rental, user, businessEntity }) => ({
		currentRental: rental.currentRental,
		userEmail: user.email!,
		businessEntity: businessEntity.businessEntity,
	}),
	dispatchProps,
)(RentalCompletePage);
