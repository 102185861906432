import { connect } from 'react-redux';
import { isAlreadyRequested, isOperationSuccess, OperationState } from '../operationState';
import { WatchAM } from '../Data/searchWatchApiClient';
import { CurrentParkingSpaceAM } from '../Data/parkingSpaceApiClient';
import { ResourceTextInline } from '@kojamo/react-utils';
import { HighlightText } from '../Components/HighlightText';
import { TextHero } from '../Components/TextHero';
import { SearchWatchTypeSelector } from '../Components/SearchWatchTypeSelector';
import { AppRootState } from '../Store/appRootState';
import { localizedLinks } from '../localizedLinks';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { searchWatchActions } from '../Data/searchWatchActions';
import { parkingSpaceActions } from '../Data/parkingSpaceActions';
import { useOperationComplete } from '../hooks/useOperationComplete';
import { appRoutes } from '../appRoutes';
import { businessEntityActions } from '../Data/businessEntityActions';
import { BusinessEntityFullAM } from '../Data/businessEntityApiClient';
import { BackToBusinessEntityPage } from '../Components/BackToBusinessEntityPage';
import { pageViewTelemetryActions } from '../Telemetry/pageViewTelemetryActions';
import { notifyShellNavigatedTo } from '../shellIntegration';
import { addTelemetryEventGA4, GA4EventAction } from '../Telemetry/TelemetryGA4';
import { getParkingSpaceCharacteristics } from '../Common/getParkingSpaceCharacteristics';
import { useAppLanguage } from '../hooks/useAppLanguage';

interface StateProps {
	userEmail: string | undefined;
	isLoggedIn: boolean;
	shellBaseUrl: string | undefined;
	businessEntityId: string;
	businessEntity: OperationState<BusinessEntityFullAM> | undefined;
	currentSpaces: OperationState<CurrentParkingSpaceAM[]> | undefined;
	createSearchWatchOperation: OperationState<WatchAM> | undefined;
}

const dispatchProps = {
	fetchBusinessEntity: businessEntityActions.fetchBusinessEntity.request,
	getCurrentParkingSpaces: parkingSpaceActions.getCurrentParkingSpaces.request,
	createSearchWatch: searchWatchActions.createSearchWatch.request,
	resetSearchWatchCreationState: searchWatchActions.resetSearchWatchCreationState,
	pageComplete: pageViewTelemetryActions.pageComplete,
};

type DispatchProps = typeof dispatchProps;
type Props = StateProps & DispatchProps;

enum ViewMode {
	Create,
	Complete,
	Error,
}

function CreateSearchWatchPage(props: Props) {
	const {
		userEmail,
		isLoggedIn,
		shellBaseUrl,
		businessEntityId,
		businessEntity,
		currentSpaces,
		getCurrentParkingSpaces,
		fetchBusinessEntity,
		createSearchWatchOperation,
		resetSearchWatchCreationState,
		createSearchWatch,
		pageComplete,
	} = props;
	const { language } = useAppLanguage();
	const [isSwapping, setSwappingState] = useState<boolean>(true);
	const [typeSelection, setTypeSelection] = useState<string[]>([]);
	const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Create);
	const [allowSubmit, setAllowSubmit] = useState<boolean>(false);
	const [completedTypes, setCompletedTypes] = useState<string[]>([]);
	const customersCurrentNonTerminatedSpacesCount = isOperationSuccess(currentSpaces)
		? currentSpaces.result.filter((s) => !s.contractEndDate).length
		: 0;
	const typeOptions: string[] =
		isOperationSuccess(businessEntity) && businessEntity.result.id === businessEntityId
			? businessEntity.result.existingParkingSpaceCharacteristics
			: [];

	useEffect(() => {
		if (businessEntityId) {
			if (!isAlreadyRequested(businessEntity, businessEntityId)) {
				fetchBusinessEntity(businessEntityId);
			}
			if (isLoggedIn) {
				getCurrentParkingSpaces(businessEntityId);
			}
		}
	}, [businessEntity, businessEntityId, isLoggedIn, fetchBusinessEntity, getCurrentParkingSpaces]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (isOperationSuccess(businessEntity, businessEntityId)) {
			const result = businessEntity.result;
			pageComplete({ businessEntityNumber: result.number, pageType: 'CreateSearchWatch' });
		}
	}, [businessEntity, businessEntityId, pageComplete]);

	useEffect(() => {
		notifyShellNavigatedTo(appRoutes.createSearchWatchPage);
	}, []);

	useOperationComplete(createSearchWatchOperation, {
		onSuccess: (result) => {
			setViewMode(ViewMode.Complete);
			setCompletedTypes([...result.allowedTypes]);
			addTelemetryEventGA4({ action: GA4EventAction.parkingspace_searchwatch_created });
			resetSearchWatchCreationState();
			window.scrollTo(0, 0);
		},
		onError: () => {
			setViewMode(ViewMode.Error);
			resetSearchWatchCreationState();
		},
	});

	const swappedChanged = (event: React.FormEvent<HTMLInputElement>) => {
		const target = event.currentTarget;
		switch (target.id) {
			case 'rentNew':
				setSwappingState(false);
				break;
			default:
				setSwappingState(true);
				break;
		}
	};

	const onCreateClick = () => {
		createSearchWatch({
			isSwapping: isSwapping,
			allowedTypes: typeSelection,
		});
	};

	const completedTypesList = completedTypes
		? completedTypes.map((type) => (
				<li key={type}>
					<ResourceTextInline resourceKey={getParkingSpaceCharacteristics(type)} />
				</li>
		  ))
		: undefined;

	const typeSelectionChanged = (types: string[]) => {
		setTypeSelection(types);
		setAllowSubmit(!!types.length);
	};

	const renderContents = () => {
		switch (viewMode) {
			case ViewMode.Create:
				return (
					<>
						<BackToBusinessEntityPage businessEntityId={businessEntityId} />

						<div className="mod-textHero -parkingSpaceSearchWatch">
							<div className="mod-textHero__icon"></div>
							<h1 className="mod-textHero__title">
								<ResourceTextInline resourceKey="CreateSearchWatchPage_CreateSearchWatch_Title" />
							</h1>
							<p className="mod-textHero__text">
								<ResourceTextInline resourceKey="CreateSearchWatchPage_CreateSearchWatch_Text" />
							</p>
						</div>
						<div className="mod">
							<div className="atom-boxedContent">
								<h3 className="atom-boxedContent__title">
									<ResourceTextInline resourceKey="CreateSearchWatchPage_SearchWatchType_Title" />
								</h3>
								<SearchWatchTypeSelector
									options={typeOptions}
									initialSelection={undefined}
									onChange={typeSelectionChanged}
								/>
							</div>

							{customersCurrentNonTerminatedSpacesCount == 1 && (
								<div className="atom-boxedContent">
									<h3 className="atom-boxedContent__title">
										<ResourceTextInline resourceKey="CreateSearchWatchPage_ChangeOrRentNew_Title" />
									</h3>
									<fieldset className="atom-boxedContent__inputGroup">
										<div className="input radio">
											<input
												type="radio"
												id="changeCurrent"
												name="changeOrNew"
												defaultChecked
												onChange={swappedChanged}
											/>
											<label htmlFor="changeCurrent">
												<ResourceTextInline resourceKey="CreateSearchWatchPage_ChangeOrRentNew_ChangeCurrent" />
											</label>
										</div>
										<div className="input radio">
											<input
												type="radio"
												id="rentNew"
												name="changeOrNew"
												onChange={swappedChanged}
											/>
											<label htmlFor="rentNew">
												<ResourceTextInline resourceKey="CreateSearchWatchPage_ChangeOrRentNew_RentNew" />
											</label>
										</div>
									</fieldset>
								</div>
							)}

							{customersCurrentNonTerminatedSpacesCount > 1 && (
								<div className="atom-boxedContent">
									<p className="atom-boxedContent__text">
										<ResourceTextInline resourceKey="CreateSearchWatchPage_MaximumSpacesRented_Text" />
									</p>
								</div>
							)}
						</div>
						<div className="mod-ctaGroup">
							{!!userEmail ? (
								<>
									<button
										className="mod-ctaGroup__button -primary"
										onClick={onCreateClick}
										disabled={!allowSubmit}
									>
										<ResourceTextInline resourceKey="CreateSearchWatchPage_CreateSearchButton_Text" />
									</button>
									<Link
										to={appRoutes.businessEntityPage(businessEntityId)}
										className={'mod-ctaGroup__link'}
									>
										<ResourceTextInline resourceKey="CreateSearchWatchPage_Back" />
									</Link>
									<p className="mod-ctaGroup__text">
										<ResourceTextInline
											resourceKey="CreateSearchWatchPage_NotificationEmailText"
											parameters={[userEmail]}
										/>
									</p>
									<a
										href={`${shellBaseUrl}${localizedLinks.customerContact[language]}`}
										target="_top"
										className="mod-ctaGroup__link"
									>
										<ResourceTextInline resourceKey="CreateSearchWatchPage_ChangeContactInfo_LinkText" />
									</a>
								</>
							) : (
								<HighlightText
									version="error"
									text={
										<>
											<span className="content">
												<ResourceTextInline resourceKey="SearchWatchPage_EmailMissing_Text" />
												<a
													href={`${shellBaseUrl}${localizedLinks.customerContact[language]}`}
													target="_top"
												>
													<ResourceTextInline resourceKey="SearchWatchPage_EmailMissing_LinkText" />
												</a>
											</span>
										</>
									}
								/>
							)}
						</div>
					</>
				);
			case ViewMode.Error:
				return (
					<>
						<HighlightText
							version="error"
							text={
								<>
									<span className="content">
										<ResourceTextInline resourceKey="CreateSearchWatchPage_CreateSearchWatchError_Text" />
									</span>
									<Link
										to={appRoutes.businessEntityPage(businessEntityId)}
										className={'mod-ctaGroup__link'}
									>
										<ResourceTextInline resourceKey="CreateSearchWatchPage_Completed_ReturnToFrontPageLink" />
									</Link>
								</>
							}
						/>
					</>
				);
			case ViewMode.Complete:
				return (
					<>
						<TextHero
							icon="success"
							title={<ResourceTextInline resourceKey={'CreateSearchWatchPage_Completed_Heading'} />}
							text={
								<ResourceTextInline
									resourceKey={'CreateSearchWatchPage_Completed_Subheading'}
									parameters={[userEmail!]}
								/>
							}
						/>

						{completedTypesList && (
							<div className="mod">
								<div className="atom-boxedContent">
									<ul className="atom-boxedContent__list -checked">{completedTypesList}</ul>
								</div>
							</div>
						)}

						<div className="mod-ctaGroup">
							<Link to={appRoutes.businessEntityPage(businessEntityId)} className={'mod-ctaGroup__link'}>
								<ResourceTextInline resourceKey="CreateSearchWatchPage_Completed_ReturnToFrontPageLink" />
							</Link>
						</div>
					</>
				);
		}
	};

	return renderContents();
}

export default connect<StateProps, DispatchProps, unknown, AppRootState>((appRootState: AppRootState) => {
	const { parkingSpace, user, searchWatch, businessEntity } = appRootState;
	const settings = isOperationSuccess(appRootState.appState.settings)
		? appRootState.appState.settings.result
		: undefined;

	return {
		createSearchWatch: searchWatch.createSearchWatch,
		userEmail: user.email,
		isLoggedIn: !!user.userInfo?.userContext,
		shellBaseUrl: settings?.shellBaseUrl,
		businessEntityId: user.businessEntityId!,
		businessEntity: businessEntity.businessEntity,
		currentSpaces: parkingSpace.currentSpaces,
		createSearchWatchOperation: searchWatch.createSearchWatch,
	};
}, dispatchProps)(CreateSearchWatchPage);
