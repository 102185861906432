import { ActionType, createAsyncAction, createAction } from 'typesafe-actions';
import {
	IdentificationContext,
	IdentificationFormDataAM,
	ParkingSpaceRentalAM,
	SaveRentalRequest,
} from './rentalApiClient';
import { ParkingSpaceAM } from './ParkingSpaceAM';

export const rentalActions = {
	startRental: createAsyncAction(
		'rental/START_RENTAL_REQUEST',
		'rental/START_RENTAL_SUCCESS',
		'rental/START_RENTAL_FAILURE',
	)<StartRentalRequest, ParkingSpaceRentalAM, Error>(),
	getIdentificationForm: createAsyncAction(
		'rental/GET_IDENTIFICATION_FORM_REQUEST',
		'rental/GET_IDENTIFICATION_FORM_SUCCESS',
		'rental/GET_IDENTIFICATION_FORM_FAILURE',
	)<IdentificationContext, IdentificationFormDataAM, Error>(),
	getCurrentRental: createAsyncAction(
		'rental/GET_CURRENT_RENTAL_REQUEST',
		'rental/GET_CURRENT_RENTAL_SUCCESS',
		'rental/GET_CURRENT_RENTAL_FAILURE',
	)<void, ParkingSpaceRentalAM, Error>(),
	getAllowedRentalDays: createAsyncAction(
		'rental/GET_ALLOWED_DAYS_REQUEST',
		'rental/GET_ALLOWED_DAYS_SUCCESS',
		'rental/GET_ALLOWED_DAYS_FAILURE',
	)<GetAllowedRentalDaysRequest, Date[], Error>(),
	saveRental: createAsyncAction(
		'rental/SAVE_RENTAL_REQUEST',
		'rental/SAVE_RENTAL_SUCCESS',
		'rental/SAVE_RENTAL_FAILURE',
	)<SaveRentalRequest, void, Error>(),
	abortRental: createAsyncAction(
		'rental/ABORT_RENTAL_REQUEST',
		'rental/ABORT_RENTAL_SUCCESS',
		'rental/ABORT_RENTAL_FAILURE',
	)<string, void, Error>(),
	clearRentalState: createAction('rental/CLEAR_RENTAL')(),
};

export type RentalActionTypes = ActionType<typeof rentalActions>;

export interface StartRentalRequest {
	businessEntityId: string;
	parkingSpace: ParkingSpaceAM;
}

export interface GetAllowedRentalDaysRequest {
	reservationId: string;
	spaceIdToSwapAway: string | undefined;
}
