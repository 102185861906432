import { ActionType, getType } from 'typesafe-actions';
import { takeLatest, select, put } from 'redux-saga/effects';
import { pageViewTelemetryActions } from './pageViewTelemetryActions';
import { AppRootState } from '../Store/appRootState';
import { addTelemetryEventForPageView } from './Telemetry';
function* navigatedToNewUrlSaga() {
	const oldUnsentUrl: string | undefined = yield select((s: AppRootState) => s.pageViewTelemetry.oldUnsentUrl);
	if (oldUnsentUrl) {
		console.log('PageViewTelemetry.NotSent: ' + oldUnsentUrl);
	}
}

function* pageCompleteSaga(action: ActionType<typeof pageViewTelemetryActions.pageComplete>) {
	const appState: AppRootState = yield select((s: AppRootState) => s);
	const { eventHasBeenSent } = appState.pageViewTelemetry;

	if (!eventHasBeenSent) {
		addTelemetryEventForPageView(
			action.payload.pageType,
			action.payload.businessEntityNumber,
			action.payload.data,
			appState.user.uiLanguage,
		);
		yield put(pageViewTelemetryActions.eventSent());
	}
}

export function* pageViewTelemetrySaga() {
	yield takeLatest(getType(pageViewTelemetryActions.navigatedToNewUrl), navigatedToNewUrlSaga);
	yield takeLatest(getType(pageViewTelemetryActions.pageComplete), pageCompleteSaga);
}
