import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { configureStore } from './Store/configureStore';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { getType } from 'typesafe-actions';
import QueryString from 'query-string';
import { appStateActions } from './Data/appStateActions';
import { ResourceTextProvider } from './Localization/ResourceTextProvider';

const store = configureStore();
addContractIdToStore();

ReactDOM.render(
	<Provider store={store}>
		<ResourceTextProvider>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</ResourceTextProvider>
	</Provider>,
	document.getElementById('parkingspace-root'),
);

function addContractIdToStore() {
	if (!window.location.search.length) {
		return;
	}

	const queryParams = QueryString.parse(window.location.search.substring(1));
	/* tslint:disable-next-line:no-string-literal */
	let contractId = queryParams['contractId'];

	if (contractId) {
		if (Array.isArray(contractId)) {
			contractId = contractId[0];
		}
		store.dispatch({
			type: getType(appStateActions.setContractId),
			payload: contractId,
		});
	}
}
