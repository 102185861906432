import { useEffect } from 'react';
import './Styles/style.scss';
import { Route, Routes, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Layout from './Layout';
import HomePage from './Pages/HomePage';
import BusinessEntityPage from './Pages/BusinessEntityPage';
import { appActions } from './Data/appActions';
import { AppRootState } from './Store/appRootState';
import OidcCallback from './OidcCallback';
import TempHtmlPage from './Pages/TempHtmlPage';
import RentalPage from './Pages/RentalPage';
import { appRoutes } from './appRoutes';
import BankSelectionPage from './Pages/BankSelectionPage';
import RentalCompletePage from './Pages/RentalCompletePage';
import TerminationPage from './Pages/TerminationPage';
import RentalTimedOutPage from './Pages/RentingTimedOutPage';
import TerminationCompletePage from './Pages/TerminationCompletePage';
import NotFoundPage from './Pages/NotFoundPage';
import RemoveSearchWatchPage from './Pages/RemoveSearchWatchPage';
import RemoveSearchWatchCompletedPage from './Pages/RemoveSearchWatchCompletedPage';
import SearchWatchPage from './Pages/SearchWatchPage';
import CreateSearchWatchPage from './Pages/CreateSearchWatchPage';
import EditSearchWatchPage from './Pages/EditSearchWatchPage';
import { pageViewTelemetryActions } from './Telemetry/pageViewTelemetryActions';
import '../node_modules/@kojamo/lumo-dls/css/lumo-dls.css';

export default function App() {
	const dispatch = useDispatch();
	const isLoggedIn = useSelector<AppRootState, boolean>(({ user }) => Boolean(user.userInfo.userContext));

	useEffect(() => {
		dispatch(appActions.init());
	}, [dispatch]);

	useUrlListener();

	return (
		<Routes>
			<Route path="/openid-connect/silent" element={<OidcCallback silent />} />
			<Route
				path="/"
				element={
					<Layout extraClasses="homepage">
						<HomePage />
					</Layout>
				}
			/>
			<Route
				path="/html/:name"
				element={
					<Layout extraClasses="single-page">
						<TempHtmlPage />
					</Layout>
				}
			/>
			<Route
				path="*"
				element={
					<Layout extraClasses={'business-entity-page' + (isLoggedIn ? '' : ' no-login')}>
						<>
							<Routes>
								<Route
									path={appRoutes.businessEntityPage(undefined)}
									element={<BusinessEntityPage />}
								/>
							</Routes>
							{isLoggedIn && <LoggedInRoutes />}
							<Routes>
								<Route element={<NotFoundPage />} />
							</Routes>
						</>
					</Layout>
				}
			/>
		</Routes>
	);
}

function LoggedInRoutes() {
	return (
		<Routes>
			<Route path={appRoutes.rentalPage} element={<RentalPage />} />
			<Route path={appRoutes.startIdentification(undefined)} element={<BankSelectionPage />} />
			<Route path={appRoutes.rentalComplete.fi} element={<RentalCompletePage />} />
			<Route path={appRoutes.rentalComplete.en} element={<RentalCompletePage />} />
			<Route path={appRoutes.rentalTimeOut} element={<RentalTimedOutPage />} />
			<Route path={appRoutes.terminationPage} element={<TerminationPage />} />
			<Route path={appRoutes.terminationComplete.fi} element={<TerminationCompletePage />} />
			<Route path={appRoutes.terminationComplete.en} element={<TerminationCompletePage />} />
			<Route path={appRoutes.searchWatchPage} element={<SearchWatchPage />} />
			<Route path={appRoutes.createSearchWatchPage} element={<CreateSearchWatchPage />} />
			<Route path={appRoutes.editSearchWatchPage} element={<EditSearchWatchPage />} />
			<Route path={appRoutes.searchWatchRemovePage} element={<RemoveSearchWatchPage />} />
			<Route path={appRoutes.searchWatchRemovalCompleted} element={<RemoveSearchWatchCompletedPage />} />
		</Routes>
	);
}

function useUrlListener() {
	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(pageViewTelemetryActions.navigatedToNewUrl(location.pathname + location.search));
	}, [location, dispatch]);
}
