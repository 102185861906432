import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { appRoutes } from '../appRoutes';
import { ResourceTextInline } from '@kojamo/react-utils';
import { TextHero } from '../Components/TextHero';
import { AppRootState } from '../Store/appRootState';

interface StateProps {
	usersBusinessEntityId: string;
}

function RemoveSearchWatchCompletedPage(props: StateProps) {
	return (
		<>
			<TextHero
				icon="success"
				title={<ResourceTextInline resourceKey={'RemoveSearchWatchCompletedPage_Heading'} />}
				text={<ResourceTextInline resourceKey={'RemoveSearchWatchCompletedPage_Subheading'} />}
			/>
			<div className="mod-ctaGroup">
				<Link to={appRoutes.businessEntityPage(props.usersBusinessEntityId)} className={'mod-ctaGroup__link'}>
					<ResourceTextInline resourceKey="RemoveSearchWatchCompletedPage_ReturnToFrontPageLink" />
				</Link>
			</div>
		</>
	);
}
export default connect<StateProps, unknown, unknown, AppRootState>(({ user }) => ({
	usersBusinessEntityId: user.businessEntityId!,
}))(RemoveSearchWatchCompletedPage);
