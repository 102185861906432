import { ResourceTextInline, useResource } from '@kojamo/react-utils';
import { IconBanner } from './IconBanner';
import { appRoutes } from '../appRoutes';
import { WatchAM } from '../Data/searchWatchApiClient';
import { getParkingSpaceCharacteristics } from '../Common/getParkingSpaceCharacteristics';

const icon = (
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
		<path
			fill="#fff"
			fillRule="evenodd"
			d="M14.7953 20.6H6.83333V22.36C6.83333 22.7135 6.53486 23 6.16667 23H3.33333C2.96514 23 2.66667 22.7135 2.66667 22.36V19.568C2.13191 19.0062 1.83475 18.273 1.83333 17.512V13.288C1.8378 12.3466 2.29634 11.4603 3.075 10.888C2.94815 10.6713 2.85016 10.4402 2.78333 10.2H1.725C1.32459 10.2 1 9.88839 1 9.504V9.296C1 9.11141 1.07638 8.93438 1.21235 8.80385C1.34831 8.67333 1.53272 8.6 1.725 8.6H2.775C3.17541 8.6 3.5 8.91161 3.5 9.296V9.4C3.50492 9.67338 3.55847 9.94394 3.65833 10.2L4.63333 5.576C4.94326 4.07951 6.31064 3.00123 7.9 3H19.1C20.4873 3.00107 21.7054 3.82272 22.1989 5.02681C22.1134 5.00924 22.0249 5 21.9343 5C21.7214 5 21.5204 5.05092 21.3428 5.14124C20.9309 4.33626 20.0704 3.80038 19.1 3.79995H7.90001C6.70928 3.80048 5.68419 4.60717 5.45001 5.72795L4.68334 9.39994H17.4471C17.1877 9.64682 16.9491 9.91448 16.7338 10.2H4.51674L4.40841 10.704L4.33341 11.088L3.97507 11.264C3.17598 11.6475 2.66996 12.4303 2.66674 13.288V17.512C2.66674 18.7756 3.7338 19.8 5.05007 19.8H15.1732C15.1232 19.9331 15.0657 20.0637 15.0009 20.1911L14.7953 20.6ZM25.9112 8.96216C25.8783 8.90452 25.8369 8.85114 25.7877 8.80385C25.6844 8.70469 25.5531 8.63854 25.4117 8.61248C25.584 8.72148 25.7507 8.83822 25.9112 8.96216ZM15.4187 16.6V18.4133C15.4204 18.6075 15.4074 18.801 15.3801 18.992H9.975C9.60154 19.0107 9.26083 18.7883 9.14167 18.448L8.86666 17.648C8.78439 17.4059 8.82718 17.1405 8.98187 16.9336C9.13656 16.7266 9.38467 16.6028 9.65 16.6H15.4187ZM7.66659 14.1999C7.66659 15.0835 6.9204 15.7999 5.99992 15.7999C5.07945 15.7999 4.33326 15.0835 4.33326 14.1999C4.33326 13.3162 5.07945 12.5999 5.99992 12.5999C6.9204 12.5999 7.66659 13.3162 7.66659 14.1999Z"
			clipRule="evenodd"
		/>
		<path
			fill="#fff"
			fillRule="evenodd"
			d="M30.3521 23.1422L28.8676 20.1645C28.5874 19.6141 28.4441 19.0042 28.4498 18.3867V14.1289C28.4501 11.0324 26.2739 8.36215 23.2409 7.73778V6.30667C23.2409 5.58501 22.6559 5 21.9343 5C21.2126 5 20.6276 5.58501 20.6276 6.30667V7.73778C17.5947 8.36215 15.4184 11.0324 15.4187 14.1289V18.4133C15.4241 19.0309 15.2808 19.6406 15.0009 20.1911L13.5165 23.1422C13.3589 23.4471 13.3744 23.8125 13.5571 24.1029C13.7397 24.3934 14.0624 24.5655 14.4054 24.5556H29.5165C29.85 24.5469 30.1555 24.3673 30.3253 24.0801C30.4951 23.7929 30.5052 23.4386 30.3521 23.1422ZM21.9343 29C23.407 29 24.6009 27.8061 24.6009 26.3333H19.2676C19.2676 27.8061 20.4615 29 21.9343 29ZM29.4809 23.6667C29.5118 23.6689 29.5405 23.6509 29.552 23.6222V23.6667C29.5757 23.6558 29.5915 23.633 29.5934 23.6071C29.5952 23.5812 29.5828 23.5563 29.5609 23.5422L28.0676 20.5644C27.7372 19.8985 27.5638 19.1656 27.5609 18.4222V14.1289C27.5609 12.1187 26.4885 10.2611 24.7476 9.25604C23.0067 8.25093 20.8618 8.25093 19.1209 9.25604C17.38 10.2611 16.3076 12.1187 16.3076 14.1289V18.4133C16.3057 19.1597 16.1324 19.8957 15.8009 20.5644L14.3165 23.5867C14.3001 23.6108 14.3001 23.6425 14.3165 23.6667C14.3336 23.6736 14.3527 23.6736 14.3698 23.6667H29.4809Z"
			clipRule="evenodd"
		/>
	</svg>
);

export function NewSearchWatchBanner() {
	return (
		<IconBanner
			icon={icon}
			title={<ResourceTextInline resourceKey="NewSearchWatchBanner_Title" />}
			body={<ResourceTextInline resourceKey="NewSearchWatchBanner_Body" />}
			buttonText={<ResourceTextInline resourceKey="NewSearchWatchBanner_ButtonText" />}
			buttonLinkTarget={appRoutes.createSearchWatchPage}
			iconMode="Turqouise"
		/>
	);
}

export function EditSearchWatchBanner({ watch }: { watch: WatchAM }) {
	const { getResourceText } = useResource();
	return (
		<IconBanner
			icon={icon}
			title={<ResourceTextInline resourceKey="ModifySearchWatchBanner_Title" />}
			body={
				<span>
					{watch.allowedTypes
						.map((type) => getResourceText(getParkingSpaceCharacteristics(type), { optional: true }))
						.filter((t) => !!t)
						.join(', ')}
				</span>
			}
			buttonText={<ResourceTextInline resourceKey="ModifySearchWatchBanner_ButtonText" />}
			buttonLinkTarget={appRoutes.editSearchWatchPage}
			iconMode="Turqouise"
		/>
	);
}
