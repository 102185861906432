import { ActionType, getType } from 'typesafe-actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { businessEntityActions } from './businessEntityActions';
import { BusinessEntityApiClient, BusinessEntityFullAM, BusinessEntityTitleAM } from './businessEntityApiClient';
import { AppRootState } from '../Store/appRootState';
import { UILanguage, UserInfo } from './userReducer';

function* fetchBusinessEntities() {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userInfo: UserInfo = yield select((state: AppRootState) => state.user.userInfo);
		const api = new BusinessEntityApiClient(language, userInfo);
		const entities: BusinessEntityTitleAM[] = yield call([api, api.fetchBusinessEntities]);
		yield put(businessEntityActions.fetchBusinessEntities.success(entities));
	} catch (e: any) {
		yield put(businessEntityActions.fetchBusinessEntities.failure(e));
	}
}

function* fetchBusinessEntity(action: ActionType<typeof businessEntityActions.fetchBusinessEntity.request>) {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userInfo: UserInfo = yield select((state: AppRootState) => state.user.userInfo);
		const api = new BusinessEntityApiClient(language, userInfo);
		const entities: BusinessEntityFullAM = yield call([api, api.fetchBusinessEntity], action.payload);
		yield put(businessEntityActions.fetchBusinessEntity.success(entities));
	} catch (e: any) {
		yield put(businessEntityActions.fetchBusinessEntity.failure(e));
	}
}

export default function* businessEntitySaga() {
	yield takeLatest(getType(businessEntityActions.fetchBusinessEntities.request), fetchBusinessEntities);
	yield takeLatest(getType(businessEntityActions.fetchBusinessEntity.request), fetchBusinessEntity);
}
