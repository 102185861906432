import { ParkingSpaceAM } from '../Data/ParkingSpaceAM';
import { formatDate } from '../dateUtils';
import { formatWattage } from '../electricChargingUtils';
import { ElectricChargingIcon } from './ElectricChargingIcon';

import { ResourceText, ResourceTextInline, useResource } from '@kojamo/react-utils';
import { useAppLanguage } from '../hooks/useAppLanguage';
import { getElectricChargingTypeKey } from '../Common/getElectricChargingTypeKey';
import { getParkingSpaceCharacteristics } from '../Common/getParkingSpaceCharacteristics';

export function ParkingSpaceCard(props: {
	parkingSpace: ParkingSpaceAM;
	allowRental: boolean;
	isHighLighted?: boolean;
	onRentClick: (p: ParkingSpaceAM) => void;
}) {
	const { parkingSpace, allowRental, onRentClick } = props;
	const { getResourceText } = useResource();
	const { language } = useAppLanguage();
	return (
		<li className={'atom-parkingSpaceCard' + (props.isHighLighted ? ' -highlight' : '')}>
			<div className="atom-parkingSpaceCard__content">
				<h3 className="atom-parkingSpaceCard__address">{parkingSpace.streetAddress}</h3>
				<div className="atom-parkingSpaceCard__icons">
					<span className="atom-parkingSpaceCard__spaceName">{parkingSpace.name}</span>
					{parkingSpace.supportsElectricCharging && <ElectricChargingIcon />}
				</div>
				<span className="atom-parkingSpaceCard__spaceType">
					<span className="single">
						<ResourceText
							textType="MarkdownInline"
							resourceKey={getParkingSpaceCharacteristics(parkingSpace.characteristics)}
						/>
					</span>
					{parkingSpace.supportsElectricCharging && (
						<span className="single">
							<ResourceText
								textType="MarkdownInline"
								resourceKey={'ParkingSpaceCard_ElectricCharging'}
								parameters={[
									formatWattage(parkingSpace.electricChargingWattage, language),
									getResourceText(getElectricChargingTypeKey(parkingSpace.electricChargingType)),
								]}
							/>
						</span>
					)}
					<span className="single availability">
						{!parkingSpace.futureAvailabilityDate ? (
							<ResourceText
								textType="MarkdownInline"
								resourceKey="BusinessEntityPage_SingleSpace_AlreadyAvailable"
							/>
						) : (
							<ResourceText
								textType="MarkdownInline"
								resourceKey="BusinessEntityPage_SingleSpace_AvailabilityDate"
								parameters={[formatDate(parkingSpace.futureAvailabilityDate)]}
							/>
						)}
					</span>
				</span>

				{allowRental ? (
					<a
						href="#"
						className="button"
						onClick={(e) => {
							e.preventDefault();
							onRentClick(parkingSpace);
						}}
					>
						<ResourceTextInline resourceKey="BusinessEntityPage_SingleSpace_RentButton" />
					</a>
				) : (
					''
				)}
			</div>
			<div className="atom-parkingSpaceCard__spacePriceWrapper">
				<div className="atom-parkingSpaceCard__spacePrice">
					<span className="number">{parkingSpace.rent}</span>
					<ResourceTextInline resourceKey="BusinessEntityPage_SingleSpace_RentUnit" />
				</div>
				{parkingSpace.supportsElectricCharging && (
					<span className="electricChargingPrice">
						<ResourceTextInline resourceKey="ParkingSpaceCard_PlusElectricityPrice" />
					</span>
				)}
			</div>
		</li>
	);
}
