import { ApiClientBase } from './apiClientBase';
import { ParkingSpaceAM } from './ParkingSpaceAM';

export interface BusinessEntityTitleAM {
	id: string;
	name: string;
	municipality: string;
}

export interface BusinessEntityFullAM extends BusinessEntityTitleAM {
	parkingSpaceImages: ImageMetadata[];
	availableParkingSpaces: ParkingSpaceAM[];
	isRentalAllowedForUser: boolean;
	parkingSpacesReservableInMyLumo: boolean;
	externalParkingSpaceManagementDescription: string | undefined;
	/**
	 * List of all parking space existing in business entity. True if places are available.
	 */
	parkingSpaceKinds: { [key in ParkingSpaceKind]: boolean };
	existingParkingSpaceCharacteristics: string[];
	number: number | undefined;
}

export type ParkingSpaceKind =
	| 'ParkingSpace'
	| 'ParkingHall'
	| 'ParkingGarage'
	| 'Shelter'
	| 'HandicappedParking'
	| 'MotorcycleParking'
	| 'SupportsElectricCharging'
	| 'Heated'
	| 'HeatingPlug';

export interface ImageMetadata {
	rootUrl: string;
	caption: string;
}

export class BusinessEntityApiClient extends ApiClientBase {
	public async fetchBusinessEntities(): Promise<BusinessEntityTitleAM[]> {
		return await this.fetchJson<BusinessEntityTitleAM[]>('/api/business-entities');
	}

	public async fetchBusinessEntity(id: string): Promise<BusinessEntityFullAM> {
		return await this.fetchJson<BusinessEntityFullAM>(`/api/business-entities/${id}`);
	}
}
