import { ResourceTextInline } from '@kojamo/react-utils';
import { IconBanner } from './IconBanner';
import { triggerParkingSpaceLogin } from './triggerParkingSpaceLogin';
import { useAppLanguage } from '../hooks/useAppLanguage';

export function LoginBanner({ shellBaseUrl, loginClicked }: { shellBaseUrl: string; loginClicked: () => void }) {
	const { language } = useAppLanguage();
	const onLoginClick = () => {
		triggerParkingSpaceLogin(shellBaseUrl, language);
		loginClicked();
	};
	return (
		<IconBanner
			icon={
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 24">
					<path
						stroke="#fff"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						d="M3 11.1V6C3 3.2 5.2 1 8 1C10.8 1 13 3.2 13 6"
					></path>
					<path
						stroke="#fff"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						d="M8 23C11.866 23 15 19.866 15 16C15 12.134 11.866 8.99998 8 8.99998C4.13401 8.99998 1 12.134 1 16C1 19.866 4.13401 23 8 23Z"
					></path>
					<path
						stroke="#fff"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						d="M8 17C9.10457 17 10 16.1046 10 15 10 13.8954 9.10457 13 8 13 6.89543 13 6 13.8954 6 15 6 16.1046 6.89543 17 8 17zM8 17V19"
					></path>
				</svg>
			}
			title={<ResourceTextInline resourceKey="LoginBanner_Title" />}
			body={<ResourceTextInline resourceKey="LoginBanner_Body" />}
			buttonText={<ResourceTextInline resourceKey="LoginBanner_ButtonText" />}
			onButtonClick={onLoginClick}
		/>
	);
}
