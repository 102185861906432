import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { SettingsAM } from './settingsApiClient';
import { UserInfoAM } from './userApiClient';
import { UILanguage } from './userReducer';

export const appStateActions = {
	fetchAppSettings: createAsyncAction(
		'appState/APP_SETTINGS_REQUEST',
		'appState/APP_SETTINGS_SUCCESS',
		'appState/APP_SETTINGS_FAILURE',
	)<void, SettingsAM, Error>(),
	setContractId: createAction('appState/SET_CONTRACTID')<string>(),
	getUserInfo: createAsyncAction(
		'appState/USER_INFO_REQUEST',
		'appState/USER_INFO_SUCCESS',
		'appState/USER_INFO_FAILURE',
	)<void, UserInfoAM, Error>(),
	setLanguage: createAsyncAction(
		'appState/SET_LANGUAGE_REQUEST',
		'appState/SET_LANGUAGE_SUCCESS',
		'appState/SET_LANGUAGE_FAILURE',
	)<undefined, UILanguage, Error>(),
};

export type AppStateActionTypes = ActionType<typeof appStateActions>;
