import { ApiClientBase } from './apiClientBase';

export interface CreateSearchWatchAM {
	isSwapping: boolean;
	allowedTypes: string[];
}

export interface EditSearchWatchAM extends CreateSearchWatchAM {
	id: string;
}

export interface WatchAM extends EditSearchWatchAM {
	businessEntityId: string;
	createdAt: Date;
}

export class SearchWatchApiClient extends ApiClientBase {
	public async createSearchWatch(req: CreateSearchWatchAM): Promise<WatchAM> {
		return await this.postJson<CreateSearchWatchAM, WatchAM>('/api/parking-space-watch', req);
	}

	public async editSearchWatch(req: EditSearchWatchAM): Promise<WatchAM> {
		return await this.putJson<CreateSearchWatchAM, WatchAM>(`/api/parking-space-watch/${req.id}`, {
			isSwapping: req.isSwapping,
			allowedTypes: req.allowedTypes,
		});
	}

	public async fetchSearchWatch(): Promise<WatchAM> {
		return await this.fetchJson<WatchAM>(`/api/parking-space-watch`);
	}

	public async deleteSearchWatch(watchId: string): Promise<void> {
		await this.delete(`/api/parking-space-watch/${watchId}`);
	}
}
