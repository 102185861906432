import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { User } from 'oidc-client';
import { ApiCallError } from '../Data/apiCallError';

export interface IRefreshSuccess {
	user: User;
}

export const authActions = {
	refresh: createAsyncAction(
		'AUTH/REFRESH_LOGIN_REQUEST',
		'AUTH/REFRESH_LOGIN_SUCCESS',
		'AUTH/REFRESH_LOGIN_FAILURE',
	)<void, IRefreshSuccess, Error>(),
	error: createAction('AUTH/ERROR')<ApiCallError>(),
	authenticationTimeout: createAction('AUTH/TIMEOUT')<string>(),
};

export type AuthActionTypes = ActionType<typeof authActions>;
