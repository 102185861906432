import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

interface CarouselImage {
	url: string;
	caption?: string;
}

interface ImageCarouselProps {
	images: CarouselImage[];
	selectedItem?: number;
	onSelectionChanged?: (idx: number) => void;
	alternativePresets?: AlternativeImageSize[];
	fullImagePreset: string;
}

interface CarouselSlideProps {
	index: number;
	caption?: string;
}

interface AlternativeImageSize {
	maxWidth: number;
	path: string;
}

interface PictureProps {
	path: string;
	title?: string;
	alternativeSizes?: AlternativeImageSize[];
	lazyLoad?: boolean;
	inViewOverride?: boolean;
}

type ImageSlideProps = {
	urlRoot: string;
	caption: string | undefined;
	fullSizePreset: string;
	alternativePresets?: AlternativeImageSize[];
} & CarouselSlideProps & { type: 'image' };

function ImageSlide(props: ImageSlideProps) {
	function alternativeImageSizeWithFullPath(alt: AlternativeImageSize): AlternativeImageSize {
		return {
			path: `${props.urlRoot}/${alt.path}`,
			maxWidth: alt.maxWidth,
		};
	}

	return (
		<div className="wrapper">
			<Picture
				path={`${props.urlRoot}/${props.fullSizePreset}`}
				alternativeSizes={
					props.alternativePresets ? props.alternativePresets.map(alternativeImageSizeWithFullPath) : []
				}
			/>
			{props.caption && (
				<p className="legend">
					<span className="legendText">{props.caption}</span>
				</p>
			)}
		</div>
	);
}

export function Picture(props: PictureProps & React.ImgHTMLAttributes<HTMLImageElement>) {
	const { path, alternativeSizes, ...imageProps } = props;

	if (alternativeSizes && alternativeSizes.length) {
		return (
			<picture {...imageProps}>
				{alternativeSizes.map((alt) => (
					<source key={alt.maxWidth} media={`(max-width: ${alt.maxWidth}px)`} srcSet={alt.path} />
				))}
				<img src={path} alt="" />
			</picture>
		);
	}

	return <img {...imageProps} src={path} alt="" />;
}

export function ImageCarousel(props: ImageCarouselProps) {
	const selectedItemIndex = props.selectedItem || 0;

	const elements = props.images.map((i, idx) => (
		<ImageSlide
			key={idx}
			index={idx}
			urlRoot={i.url}
			alternativePresets={props.alternativePresets}
			fullSizePreset={props.fullImagePreset}
			type="image"
			caption={i.caption}
		/>
	));

	const onSlideChange = (idx: number) => {
		props.onSelectionChanged && props.onSelectionChanged(idx);
	};

	const hasMultipleElements = elements.length > 1;
	return (
		<Carousel
			showIndicators={hasMultipleElements}
			showThumbs={false}
			showArrows={hasMultipleElements}
			showStatus={false}
			infiniteLoop={hasMultipleElements}
			dynamicHeight={false}
			selectedItem={selectedItemIndex}
			onChange={onSlideChange}
		>
			{elements}
		</Carousel>
	);
}
