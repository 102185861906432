import * as React from 'react';

export class Spinner extends React.Component {
	public render() {
		return (
			<div className="verifying-login">
				<div className="loader-spinner" />
			</div>
		);
	}
}
