import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { CreateSearchWatchAM, EditSearchWatchAM, WatchAM } from './searchWatchApiClient';

export const searchWatchActions = {
	createSearchWatch: createAsyncAction(
		'searchWatch/CREATE_WATCH_REQUEST',
		'searchWatch/CREATE_WATCH_SUCCESS',
		'searchWatch/CREATE_WATCH_FAILURE',
	)<CreateSearchWatchAM, WatchAM, Error>(),
	resetSearchWatchCreationState: createAction('searchWatch/RESET_WATCH_CREATE_STATE')<void>(),
	editSearchWatch: createAsyncAction(
		'searchWatch/EDIT_WATCH_REQUEST',
		'searchWatch/EDIT_WATCH_SUCCESS',
		'searchWatch/EDIT_WATCH_FAILURE',
	)<EditSearchWatchAM, WatchAM, Error>(),
	resetSearchWatchEditState: createAction('searchWatch/RESET_WATCH_EDIT_STATE')<void>(),
	fetchSearchWatch: createAsyncAction(
		'searchWatch/FETCH_WATCH_REQUEST',
		'searchWatch/FETCH_WATCH_SUCCESS',
		'searchWatch/FETCH_WATCH_FAILURE',
	)<void, WatchAM, Error>(),
	deleteSearchWatch: createAsyncAction(
		'searchWatch/DELETE_WATCH_REQUEST',
		'searchWatch/DELETE_WATCH_SUCCESS',
		'searchWatch/DELETE_WATCH_FAILURE',
	)<string, void, Error>(),
	resetSearchWatchRemovalState: createAction('searchWatch/RESET_WATCH_REMOVAL_STATE')<void>(),
};

export type SearchWatchActionTypes = ActionType<typeof searchWatchActions>;
