import { ParkingSpaceAM } from '../../Data/ParkingSpaceAM';
import { ResourceText, ResourceTextInline } from '@kojamo/react-utils';
import { InfoButton } from '../InfoButton';
import { formatWattage } from '../../electricChargingUtils';
import { useResource } from '@kojamo/react-utils';
import { useAppLanguage } from '../../hooks/useAppLanguage';
import { getElectricChargingTypeKey } from '../../Common/getElectricChargingTypeKey';

export function ParkingSpaceElectricChargingInformationWithCompany(props: { space: ParkingSpaceAM }) {
	const { getResourceText } = useResource();
	const { language } = useAppLanguage();
	const { space } = props;

	return (
		<span>
			<ResourceTextInline
				resourceKey="ParkingSpace_ElectricCharging_Compact_Value"
				parameters={[
					space.electricChargingCompany || '',
					formatWattage(space.electricChargingWattage, language),
					getResourceText(getElectricChargingTypeKey(space.electricChargingType)),
				]}
			/>
			<InfoButton
				text={
					<ResourceText
						resourceKey="ParkingSpace_ElectricCharging_Compact_Tooltip"
						parameters={[
							space.electricChargingCompany || '',
							formatWattage(space.electricChargingWattage, language),
							getResourceText(getElectricChargingTypeKey(space.electricChargingType)),
							space.electricChargingCompany
								? getResourceText(
										'ElectricChargingCompany_ContactUrl_' +
											space.electricChargingCompany.replaceAll(' ', ''),
								  )
								: '',
						]}
						linkOpenMode="blank"
					/>
				}
			/>
		</span>
	);
}
