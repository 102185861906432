import { ApiClientBase } from './apiClientBase';
import { StartRentalRequest } from './rentalActions';
import { ParkingSpaceAM } from './ParkingSpaceAM';

type ParkingSpaceReservationState =
	| 'None'
	| 'RentalStarted'
	| 'IdentificationStarted'
	| 'IdentificationFailed'
	| 'IdentificationComplete'
	| 'CustomerHasCreditDebt'
	| 'Aborted'
	| 'RentalComplete';

export interface ParkingSpaceRentalAM {
	businessEntityId: string;
	id: string;
	parkingSpace: ParkingSpaceAM;
	reservedAt: Date;
	state: ParkingSpaceReservationState;
	expirationTime: Date;
	rentalStartDate: Date | undefined;
	terminatedParkingSpaceId: string | undefined;
}

export interface IdentificationFormDataAM {
	identificationForm: string;
}

export type IdentificationContext = 'Rental' | 'Termination';

export interface RentalDataAIM {
	parkingSpaceId: string;
	startDate: Date;
	oldParkingSpaceIdToTerminate: string | undefined;
	sendSmsConfirmation: boolean;
}

export interface SaveRentalRequest {
	reservationId: string;
	data: RentalDataAIM;
}
export interface TerminationDataAIM {
	parkingSpaceId: string;
	terminationDate: Date;
	sendSmsConfirmation: boolean;
	businessEntityId: string;
}

export interface AllowedTerminationDatesAM {
	terminationIsAllowed: boolean;
	allowedDates: Date[];
}

export interface TerminationCompletionInfoAM {
	businessEntityId: string;
	parkingSpaceId: string;
	lastDayOfContractTerm: Date;
}

export class RentalApiClient extends ApiClientBase {
	public async startRental(req: StartRentalRequest): Promise<ParkingSpaceRentalAM> {
		const rental = await this.postJson<void, ParkingSpaceRentalAM>(
			`/api/rentals/start?businessEntityId=${req.businessEntityId}&parkingSpaceId=${req.parkingSpace.id}`,
			undefined,
		);
		return rental;
	}

	public async getCurrentRental(): Promise<ParkingSpaceRentalAM | null> {
		return await this.fetchJsonMaybe<ParkingSpaceRentalAM>(`/api/rentals/current`);
	}

	public async saveRental(req: SaveRentalRequest): Promise<void> {
		await this.post(`/api/rentals/${req.reservationId}/save`, {
			...req.data,
			startDate: req.data.startDate.toISOString(),
		});
	}

	public async getAllowedRentalStartDates(
		reservationId: string,
		spaceIdToSwapAway: string | undefined,
	): Promise<Date[]> {
		let url = `/api/rentals/${reservationId}/allowed-dates`;
		if (spaceIdToSwapAway) {
			url += `?spaceIdToSwapAway=${spaceIdToSwapAway}`;
		}

		return await this.fetchJson<Date[]>(url);
	}

	public async abortRental(reservationId: string): Promise<void> {
		await this.post(`/api/rentals/${reservationId}/abort`);
	}

	public async getIdentificationForm(context: IdentificationContext) {
		return await this.fetchJson<IdentificationFormDataAM>(`/api/rentals/identification/${context}`);
	}

	public async startTermination(data: TerminationDataAIM) {
		return await this.post<any>(`/api/rentals/termination/start`, {
			...data,
			terminationDate: data.terminationDate.toISOString(),
		});
	}

	public async finalizeTermination(): Promise<TerminationCompletionInfoAM> {
		return await this.postJson<void, TerminationCompletionInfoAM>('/api/rentals/termination/finalize/', undefined);
	}

	public async getAllowedTerminationDates(parkingSpaceId: string) {
		return await this.fetchJson<AllowedTerminationDatesAM>(
			`/api/rentals/termination/allowed-dates/${parkingSpaceId}`,
		);
	}
}
