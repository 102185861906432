import { useEffect, useState } from 'react';
import BusinessEntitySelection from '../Pages/BusinessEntitySelection';
import { ResourceTextInline } from '@kojamo/react-utils';

export function ChangeBusinessEntitySticky(props: { onOpened: () => void }) {
	const { onOpened } = props;
	const [isOpen, setIsOpen] = useState<boolean>(false);

	useEffect(() => {
		if (isOpen) {
			onOpened();
		}
	}, [isOpen, onOpened]);

	return (
		<div className={'sticky-changeLocation' + (isOpen ? ' -isOpen' : '')}>
			{isOpen ? (
				<div className="sticky-changeLocation__content">
					<a className="close-button" onClick={() => setIsOpen(false)}>
						<span className="lines"></span>
					</a>
					<BusinessEntitySelection />
				</div>
			) : (
				<button className="sticky-changeLocation__link" onClick={() => setIsOpen(true)}>
					<span className="sticky-changeLocation__text">
						<ResourceTextInline resourceKey="LocationChanger_Heading" />
					</span>
				</button>
			)}
		</div>
	);
}
