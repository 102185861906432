import { applyMiddleware, combineReducers, compose, createStore, Store, AnyAction } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './rootSaga';
import { appStateReducer } from '../Data/appStateReducer';
import { rentalReducer } from '../Data/rentalReducer';
import { userReducer } from '../Data/userReducer';
import { AppRootState } from './appRootState';
import { businessEntityReducer } from '../Data/businessEntityReducer';
import { parkingSpaceReducer } from '../Data/parkingSpaceReducer';
import { searchWatchReducer } from '../Data/searchWatchReducer';
import { terminationReducer } from '../Data/terminationReducer';
import { pageViewTelemetryReducer } from '../Telemetry/pageViewTelemetryReducer';

const global = window as any;

export function configureStore(): Store<AppRootState, AnyAction> {
	const saga = createSagaMiddleware();
	const composeEnhancers = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const middleware = [saga];

	const rootReducer = combineReducers<AppRootState>({
		appState: appStateReducer,
		rental: rentalReducer,
		termination: terminationReducer,
		parkingSpace: parkingSpaceReducer,
		user: userReducer,
		businessEntity: businessEntityReducer,
		searchWatch: searchWatchReducer,
		pageViewTelemetry: pageViewTelemetryReducer,
	});

	const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

	saga.run(rootSaga);

	return store;
}
