import { ApiCallError } from './apiCallError';
import { BadRequestError } from './BadRequestError';
import { UILanguage, UserInfo } from './userReducer';

const AuthorizationHeaderName = 'Authorization';
const UiLanguageHeaderName = 'ui-language';
export class ApiClientBase {
	private accessToken: string | null;
	private contractId?: string;

	public static deserialize<T>(data: string): any {
		const parsed = JSON.parse(data, ApiClientBase.reviveDateTime);

		const result: T = parsed;
		return result;
	}

	private static dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{1,7})?(Z|([+-]\d\d:\d0))?$/;
	private static reviveDateTime(key: any, value: any): any {
		if (typeof value === 'string' && ApiClientBase.dateFormat.test(value)) {
			return new Date(value);
		}

		return value;
	}

	constructor(private language: UILanguage, context?: UserInfo, private csrfToken?: string) {
		if (context) {
			this.accessToken = context.userContext?.accessToken || null;
			this.contractId = context.contractId;
		} else {
			this.accessToken = null;
		}
	}

	protected async fetchJson<T>(url: string): Promise<T> {
		const fullUrl = this.getUrl(url);

		const response = await this.fetchString(fullUrl);
		return ApiClientBase.deserialize(response);
	}

	protected async fetchJsonMaybe<T>(url: string): Promise<T | null> {
		const fullUrl = this.getUrl(url);

		const response = await this.fetchString(fullUrl);
		if (response == '' || response == null) {
			return null;
		}
		return ApiClientBase.deserialize(response);
	}

	protected async postJson<TIn, TOut>(url: string, payload: TIn): Promise<TOut> {
		const response = await this.fetchString(
			this.getUrl(url),
			'POST',
			{
				'Content-Type': 'application/json; charset=utf-8',
				'X-CSRF-TOKEN': this.csrfToken || '',
			},
			!!payload ? JSON.stringify(payload) : undefined,
		);

		return ApiClientBase.deserialize(response);
	}

	protected async putJson<TIn, TOut>(url: string, payload: TIn): Promise<TOut> {
		const response = await this.fetchString(
			this.getUrl(url),
			'PUT',
			{
				'Content-Type': 'application/json; charset=utf-8',
				'X-CSRF-TOKEN': this.csrfToken || '',
			},
			!!payload ? JSON.stringify(payload) : undefined,
		);

		return ApiClientBase.deserialize(response);
	}

	protected async post<T = unknown>(url: string, data?: T) {
		const body = data ? JSON.stringify(data) : undefined;
		return this.fetchString(
			this.getUrl(url),
			'POST',
			{
				'Content-Type': 'application/json; charset=utf-8',
				'X-CSRF-TOKEN': this.csrfToken || '',
			},
			body,
		);
	}

	protected async put<T = unknown>(url: string, data?: T) {
		const body = data ? JSON.stringify(data) : undefined;
		return this.fetchString(
			this.getUrl(url),
			'PUT',
			{
				'Content-Type': 'application/json; charset=utf-8',
				'X-CSRF-TOKEN': this.csrfToken || '',
			},
			body,
		);
	}

	protected async delete<T = unknown>(url: string, data?: T) {
		const body = data ? JSON.stringify(data) : undefined;
		return this.fetchString(
			this.getUrl(url),
			'DELETE',
			{
				'Content-Type': 'application/json; charset=utf-8',
				'X-CSRF-TOKEN': this.csrfToken || '',
			},
			body,
		);
	}

	protected async fetchString(
		url: string,
		method = 'GET',
		headers: Record<string, string> = {},
		body: string | undefined = undefined,
	): Promise<string> {
		if (this.accessToken) {
			headers[AuthorizationHeaderName] = `Bearer ${this.accessToken}`;
		}
		headers[UiLanguageHeaderName] = this.language;
		const response = await fetch(url, {
			credentials: 'same-origin',
			method: method,
			headers,
			body,
		});

		if (response.ok) {
			return await response.text();
		} else {
			if (response.status === 400) {
				const errorResponse: {
					error: string;
				} = await response.json();
				throw new BadRequestError(
					`Received Bad Request response from ${method} to '${url}'`,
					errorResponse.error,
				);
			} else {
				throw new ApiCallError(
					`Unexpected status code ${response.status} from ${method} to '${url}'`,
					response.status,
				);
			}
		}
	}

	private getUrl(url: string) {
		return this.contractId ? ApiClientBase.addContractId(url, this.contractId) : url;
	}

	private static addContractId(url: string, contractId: string): string {
		return url + (url.includes('?') ? '&' : '?') + 'contractId=' + contractId;
	}
}
