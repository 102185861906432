import { getType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { rentalActions, RentalActionTypes } from './rentalActions';
import { IdentificationFormDataAM, ParkingSpaceRentalAM } from './rentalApiClient';
import { OperationFailed, OperationRequested, OperationState, OperationSuccess } from '../operationState';

export interface RentalState {
	isRentalInProgress: boolean;
	startRental?: OperationState<ParkingSpaceRentalAM>;
	currentRental?: OperationState<ParkingSpaceRentalAM>;
	saveRental?: OperationState<void>;
	identificationForm?: OperationState<IdentificationFormDataAM>;
	allowedRentalDays?: OperationState<Date[]>;
}

const initialState: RentalState = { isRentalInProgress: false };

export const rentalReducer: Reducer<RentalState, RentalActionTypes> = (state = initialState, action) => {
	switch (action.type) {
		case getType(rentalActions.startRental.request): {
			return {
				...state,
				startRental: OperationRequested(action.payload.parkingSpace.id),
			};
		}
		case getType(rentalActions.startRental.success): {
			return {
				...state,
				isRentalInProgress: true,
				startRental: OperationSuccess(action.payload),
				currentRental: OperationSuccess(action.payload),
			};
		}
		case getType(rentalActions.startRental.failure): {
			return {
				...state,
				isRentalInProgress: false,
				startRental: OperationFailed(action.payload),
			};
		}

		case getType(rentalActions.getIdentificationForm.request): {
			return {
				...state,
				identificationForm: OperationRequested(action.payload),
			};
		}
		case getType(rentalActions.getIdentificationForm.success): {
			return {
				...state,
				identificationForm: OperationSuccess(action.payload),
			};
		}
		case getType(rentalActions.getIdentificationForm.failure): {
			return {
				...state,
				identificationForm: OperationFailed(action.payload),
			};
		}

		case getType(rentalActions.getCurrentRental.request): {
			return {
				...state,
				currentRental: OperationRequested(),
			};
		}
		case getType(rentalActions.getCurrentRental.success): {
			return {
				...state,
				isRentalInProgress: action.payload != null && action.payload?.state != 'RentalComplete',
				currentRental: OperationSuccess(action.payload),
			};
		}
		case getType(rentalActions.getCurrentRental.failure): {
			return {
				...state,
				isRentalInProgress: false,
				currentRental: OperationFailed(action.payload),
			};
		}

		case getType(rentalActions.getAllowedRentalDays.request): {
			return {
				...state,
				allowedRentalDays: OperationRequested(action.payload),
			};
		}
		case getType(rentalActions.getAllowedRentalDays.success): {
			return {
				...state,
				allowedRentalDays: OperationSuccess(action.payload),
			};
		}
		case getType(rentalActions.getAllowedRentalDays.failure): {
			return {
				...state,
				allowedRentalDays: OperationFailed(action.payload),
			};
		}

		case getType(rentalActions.saveRental.request): {
			return {
				...state,
				saveRental: OperationRequested(action.payload),
			};
		}
		case getType(rentalActions.saveRental.success): {
			return {
				...state,
				saveRental: OperationSuccess(undefined),
			};
		}
		case getType(rentalActions.saveRental.failure): {
			return {
				...state,
				saveRental: OperationFailed(action.payload),
			};
		}

		case getType(rentalActions.abortRental.request): {
			return { ...initialState };
		}
		case getType(rentalActions.abortRental.success):
		case getType(rentalActions.abortRental.failure): {
			return {
				...state,
			};
		}

		case getType(rentalActions.clearRentalState): {
			return {
				...state,
				startRental: undefined,
			};
		}
	}
	return state;
};
