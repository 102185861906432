import { ApiClientBase } from './apiClientBase';

export class UserApiClient extends ApiClientBase {
	public async getUserInfo(): Promise<UserInfoAM> {
		return await this.fetchJson<UserInfoAM>('/api/user/me');
	}
}

export interface UserInfoAM {
	businessEntityId: string;
	antiForgeryToken: string;
	emailAddress: string;
}
