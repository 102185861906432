import { ActionType, getType } from 'typesafe-actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { IdentificationFormDataAM, ParkingSpaceRentalAM, RentalApiClient } from './rentalApiClient';
import { rentalActions } from './rentalActions';
import { AppRootState } from '../Store/appRootState';
import { UILanguage, UserInfo, UserState } from './userReducer';

function* getIdentificationForm(action: ActionType<typeof rentalActions.getIdentificationForm.request>) {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userInfo: UserInfo = yield select((state: AppRootState) => state.user.userInfo);
		const api = new RentalApiClient(language, userInfo);
		const result: IdentificationFormDataAM = yield call([api, api.getIdentificationForm], action.payload);
		yield put(rentalActions.getIdentificationForm.success(result));
	} catch (e: any) {
		yield put(rentalActions.getIdentificationForm.failure(e));
	}
}

function* startRental(action: ActionType<typeof rentalActions.startRental.request>) {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userState: UserState = yield select((state: AppRootState) => state.user);
		const api = new RentalApiClient(language, userState.userInfo, userState.antiForgeryToken);
		const result: ParkingSpaceRentalAM = yield call([api, api.startRental], action.payload);
		yield put(rentalActions.startRental.success(result));
	} catch (e: any) {
		yield put(rentalActions.startRental.failure(e));
	}
}

function* getCurrentRental() {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userInfo: UserInfo = yield select((state: AppRootState) => state.user.userInfo);
		const api = new RentalApiClient(language, userInfo);
		const result: ParkingSpaceRentalAM = yield call([api, api.getCurrentRental]);
		yield put(rentalActions.getCurrentRental.success(result));
	} catch (e: any) {
		yield put(rentalActions.getCurrentRental.failure(e));
	}
}

function* getAllowedRentalStartDays(action: ActionType<typeof rentalActions.getAllowedRentalDays.request>) {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userInfo: UserInfo = yield select((state: AppRootState) => state.user.userInfo);
		const api = new RentalApiClient(language, userInfo);
		const result: Date[] = yield call(
			[api, api.getAllowedRentalStartDates],
			action.payload.reservationId,
			action.payload.spaceIdToSwapAway,
		);
		yield put(rentalActions.getAllowedRentalDays.success(result));
	} catch (e: any) {
		yield put(rentalActions.getAllowedRentalDays.failure(e));
	}
}

function* saveRental(action: ActionType<typeof rentalActions.saveRental.request>) {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userState: UserState = yield select((state: AppRootState) => state.user);
		const api = new RentalApiClient(language, userState.userInfo, userState.antiForgeryToken);
		yield call([api, api.saveRental], action.payload);
		yield put(rentalActions.saveRental.success());
	} catch (e: any) {
		yield put(rentalActions.saveRental.failure(e));
	}
}

function* abortRental(action: ActionType<typeof rentalActions.abortRental.request>) {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userState: UserState = yield select((state: AppRootState) => state.user);
		const api = new RentalApiClient(language, userState.userInfo, userState.antiForgeryToken);
		yield call([api, api.abortRental], action.payload);
		yield put(rentalActions.abortRental.success());
	} catch (e: any) {
		yield put(rentalActions.abortRental.failure(e));
	}
}

function* rentalSaga() {
	yield takeLatest(getType(rentalActions.startRental.request), startRental);
	yield takeLatest(getType(rentalActions.getIdentificationForm.request), getIdentificationForm);
	yield takeLatest(getType(rentalActions.getCurrentRental.request), getCurrentRental);
	yield takeLatest(getType(rentalActions.saveRental.request), saveRental);
	yield takeLatest(getType(rentalActions.abortRental.request), abortRental);
	yield takeLatest(getType(rentalActions.getAllowedRentalDays.request), getAllowedRentalStartDays);
}

export default rentalSaga;
