import { getType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { OperationFailed, OperationRequested, OperationState, OperationSuccess } from '../operationState';
import { BusinessEntityFullAM, BusinessEntityTitleAM } from './businessEntityApiClient';
import { businessEntityActions, BusinessEntityActionTypes } from './businessEntityActions';

export interface BusinessEntityState {
	businessEntities: OperationState<BusinessEntityTitleAM[]> | undefined;
	businessEntity: OperationState<BusinessEntityFullAM, string> | undefined;
	businessEntityLastFetched?: Date;
}

const initialState: BusinessEntityState = {
	businessEntities: undefined,
	businessEntity: undefined,
};

export const businessEntityReducer: Reducer<BusinessEntityState, BusinessEntityActionTypes> = (
	state = initialState,
	action,
) => {
	switch (action.type) {
		case getType(businessEntityActions.fetchBusinessEntities.request): {
			return { ...state, businessEntities: OperationRequested() };
		}
		case getType(businessEntityActions.fetchBusinessEntities.success): {
			return {
				...state,
				businessEntities: OperationSuccess(action.payload),
			};
		}
		case getType(businessEntityActions.fetchBusinessEntities.failure): {
			return {
				...state,
				businessEntities: OperationFailed(action.payload),
			};
		}
		case getType(businessEntityActions.fetchBusinessEntity.request): {
			return {
				...state,
				businessEntityLastFetched: new Date(),
				businessEntity: OperationRequested(action.payload),
			};
		}
		case getType(businessEntityActions.fetchBusinessEntity.success): {
			return {
				...state,
				businessEntity: OperationSuccess(action.payload, state.businessEntity!.param),
			};
		}
		case getType(businessEntityActions.fetchBusinessEntity.failure): {
			return {
				...state,
				businessEntity: OperationFailed(action.payload, state.businessEntity!.param),
			};
		}
	}
	return state;
};
