import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export default function TempHtmlPage() {
	const { name: path } = useParams<{ name: string }>();
	const [html, setHtml] = useState<string | null>(null);

	useEffect(() => {
		fetch(`/tempHtml/${path}.html`)
			.then((r) => {
				if (!r.ok && r.status === 404) {
					throw r.status;
				}
				return r.text();
			})
			.then((text) => {
				// If first line starts with #, take the contents as main element's css classes
				const firstLine = text.split('\n', 1)[0];
				if (firstLine.startsWith('#')) {
					text = text.substring(firstLine.length);
				}

				setHtml(text);
			});
	}, [path]);

	if (!html) {
		return null;
	}

	return <div dangerouslySetInnerHTML={{ __html: html }} />;
}
