import { ResourceTextInline } from '@kojamo/react-utils';
import { Overlay } from './Overlays';
import { IdentificationFormDataAM } from '../Data/rentalApiClient';

interface StateProps {
	identificationForm: IdentificationFormDataAM | undefined;
}

export function BankSelectionOverlay(props: StateProps & { onClose: () => void }) {
	return (
		<Overlay
			onClose={props.onClose}
			heading={<ResourceTextInline resourceKey="Overlay_BankSelection_Heading" />}
			body={
				<>
					{props.identificationForm && (
						<div
							className="mod-text"
							dangerouslySetInnerHTML={{ __html: props.identificationForm.identificationForm }}
						></div>
					)}
				</>
			}
		/>
	);
}
