import { delay, fork, take, takeLatest, call, put, select, race } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { authActions } from './authenticationActions';
import { AuthenticationClient } from './authenticationClient';
import { User } from 'oidc-client';
import { ApiCallError } from '../Data/apiCallError';
import { AppRootState } from '../Store/appRootState';
import { ApiOperationState, isOperationSuccess } from '../operationState';
import { appStateActions } from '../Data/appStateActions';
import { UserContext } from '../Data/userReducer';

function* withAuthClient(action: keyof AuthenticationClient, args?: any) {
	const settingsLoaded: boolean = yield select(
		(s: AppRootState) => s.appState.settings?.status === ApiOperationState.Succeeded,
	);

	if (!settingsLoaded) {
		yield take(getType(appStateActions.fetchAppSettings.success));
	}

	const authority: string = yield select((s: AppRootState) => {
		if (isOperationSuccess(s.appState.settings)) {
			return s.appState.settings.result.ssoAuthority;
		}
		// This should never happen because of the yield-take above but compiler disagrees
		return undefined;
	});

	const client = new AuthenticationClient({ authority });

	const { result, timeout } = yield race({
		result: call([client, action], args),
		timeout: delay(10000),
	});
	if (timeout) {
		yield put(authActions.authenticationTimeout(action));
	}

	return result;
}

function* refreshSaga() {
	try {
		const user: User = yield call(withAuthClient, 'refresh');
		if (!user) {
			throw new Error('No User data!');
		}

		yield put(authActions.refresh.success({ user }));
	} catch (e: any) {
		if (e instanceof ApiCallError && e.statusCode >= 500) {
			yield put(authActions.error(e));
		} else {
			yield put(authActions.refresh.failure(e));
		}
	}
}

function* watchRefreshSaga() {
	yield takeLatest(getType(authActions.refresh.request), refreshSaga);
}

function* refreshLoginSaga() {
	while (true) {
		yield delay(30000);

		const userContext: UserContext = yield select((s: AppRootState) => s.user.userInfo.userContext);
		if (userContext) {
			const currentTimeInEpochSeconds = Math.ceil(new Date().valueOf() / 1000);
			const ttlSeconds = userContext.expiresAt - currentTimeInEpochSeconds;
			if (ttlSeconds < 30) {
				yield put(authActions.refresh.request());
			}
		}
	}
}

export function* authSaga() {
	yield fork(watchRefreshSaga);
	yield fork(refreshLoginSaga);
}
