import { useEffect } from 'react';
import { ApiOperationState, isOperationSuccess, OperationState } from '../operationState';
import { usePrevious } from './usePrevious';

export function useOperationComplete<T>(op: OperationState<T> | undefined, config: OperationCallbacks<T>) {
	const prev = usePrevious(op);

	useEffect(() => {
		if (isOperationSuccess(op) && prev?.status !== ApiOperationState.Succeeded) {
			config.onSuccess(op.result);
		} else if (op?.status === ApiOperationState.Failed && prev?.status !== ApiOperationState.Failed) {
			config.onError?.(op.error);
		}
	}, [op, config, prev?.status]);
}

interface OperationCallbacks<T> {
	onSuccess: (result: T) => void;
	onError?: (error: Error) => void;
}
