import { UserManager, UserManagerSettings } from 'oidc-client';

export interface IUserProfile {
	given_name: string;
	family_name: string;
	customer_id: string;
	name: string;
	idp: string;
	sub: string;
}

const getLocalUrl = (relativeUrl: string): string => `${window.location.origin}${relativeUrl}`;

const userManagerSettings: UserManagerSettings = {
	client_id: 'kojamo-customer-dashboard-parkingspace-app',
	redirect_uri: getLocalUrl('/openid-connect/interactive'),
	silent_redirect_uri: getLocalUrl('/openid-connect/silent'),
	response_type: 'id_token token',
	scope: 'openid profile email kojamo-customer-dashboard-apps',
	post_logout_redirect_uri: getLocalUrl('/openid-connect/logout'),
	popup_post_logout_redirect_uri: getLocalUrl('/openid-connect/logout'),
};

export interface IAuthenticationClientSettings {
	authority: string;
}

export interface ISignInParams {
	returnUrl?: string;
	culture?: string;
}

export class AuthenticationClient {
	private client: UserManager;
	constructor(settings: IAuthenticationClientSettings) {
		this.client = new UserManager({
			...userManagerSettings,
			...settings,
		});
	}

	public refresh(url?: string) {
		return this.client.signinSilent(url);
	}

	public receiveSilent(url?: string) {
		return this.client.signinSilentCallback(url);
	}
}
