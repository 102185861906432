import { useState } from 'react';
import { ParkingSpaceAM } from '../Data/ParkingSpaceAM';
import { ResourceText, ResourceTextInline } from '@kojamo/react-utils';
import { Overlay } from './Overlays';

/*** Shown when user tries to start another rental while they already have an ongoing rental */
export function OngoingRentalOverlay(props: {
	currentReservedSpace: ParkingSpaceAM;
	onConfirm: () => void;
	onClose: () => void;
}) {
	const [isVisible, setIsVisible] = useState<boolean>(true);
	return isVisible ? (
		<Overlay
			onClose={props.onClose}
			heading={<ResourceText resourceKey="Overlay_RentalConfirmation_Heading" />}
			body={
				<>
					<ResourceText
						resourceKey="Overlay_RentalConfirmation_Body"
						parameters={[props.currentReservedSpace.name]}
					/>
					<a className="button" onClick={props.onConfirm} href="#">
						<ResourceTextInline resourceKey="Overlay_RentalConfirmation_Confirm" />
					</a>
					<a className="link" href="#" onClick={() => setIsVisible(false)}>
						<ResourceTextInline resourceKey="Overlay_RentalConfirmation_Cancel" />
						&nbsp;›
					</a>
				</>
			}
		/>
	) : null;
}
