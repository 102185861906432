import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { rentalActions } from '../Data/rentalActions';
import { ApiOperationState, OperationState } from '../operationState';
import { IdentificationContext, IdentificationFormDataAM } from '../Data/rentalApiClient';
import { AppRootState } from '../Store/appRootState';

type RouteParams = { identificationContext: IdentificationContext };

export default function BankSelectionPage() {
	const { identificationContext } = useParams<RouteParams>();
	const dispatch = useDispatch();
	const identificationForm = useSelector<AppRootState, OperationState<IdentificationFormDataAM> | undefined>(
		({ rental: { identificationForm } }) => identificationForm,
	);

	useEffect(() => {
		dispatch(rentalActions.getIdentificationForm.request(identificationContext as IdentificationContext));
	}, [dispatch, identificationContext]);

	return (
		<>
			{identificationForm && identificationForm.status === ApiOperationState.Succeeded && (
				<div
					className="mod mod-text"
					dangerouslySetInnerHTML={{ __html: identificationForm.result.identificationForm }}
				></div>
			)}
		</>
	);
}
