import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { UILanguage } from '../Data/userReducer';

export function addTelemetryEvent(eventName: string, data: any, language: UILanguage) {
	if (data) {
		const eventObject = { event: eventName, language: language, ...data };

		const utmParams =
			location.search && location.search.length
				? location.search
						.substr(1)
						.split('&')
						.filter((p) => p.toLowerCase().startsWith('utm_'))
				: undefined;
		if (utmParams) {
			utmParams.forEach((p) => {
				const parts = p.split('=');
				if (parts.length > 1) {
					eventObject[parts[0]] = parts[1];
				}
			});
		}

		const dataLayer = (window as any).dataLayer;
		if (dataLayer) {
			dataLayer.push(eventObject);
		}
	}
}

export interface IErrorTrackingOptions {
	appInsightsInstrumentationKey?: string;
}

const appInsightsContainer: { instance: ApplicationInsights | undefined } = {
	instance: undefined,
};

export function setCurrentTelemetryUserId(userId: string | null) {
	if (userId) {
		if (appInsightsContainer.instance) {
			appInsightsContainer.instance.setAuthenticatedUserContext(userId);
		}
	} else {
		if (appInsightsContainer.instance) {
			appInsightsContainer.instance.clearAuthenticatedUserContext();
		}
	}
}

const ignoreDependenciesForDomains: RegExp[] = [
	/\.giosg\.com\//,
	/^www\.google-analytics\.com\//,
	/^\.visualwebsiteoptimizer\.com\//,
	/\.hubapi\.com\//,
];

export function setupErrorReporting(options: IErrorTrackingOptions) {
	if (options.appInsightsInstrumentationKey) {
		const appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: options.appInsightsInstrumentationKey,
				disableFetchTracking: false,
				enableCorsCorrelation: true,
				correlationHeaderDomains: ['*.lumo.fi'],
			},
		});

		appInsights.loadAppInsights();

		// Add telemetry filter. https://docs.microsoft.com/en-us/azure/azure-monitor/app/api-filtering-sampling#javascript-web-applications
		appInsights.addTelemetryInitializer((item: any) => {
			if (item.baseType === 'RemoteDependencyData' && item.baseData) {
				const host = item.baseData.target;
				const ignore = ignoreDependenciesForDomains.filter((d) => d.test(host)).length > 0;
				return !ignore;
			}

			return true;
		});

		appInsightsContainer.instance = appInsights;
	}
}

export function reportError(e: Error) {
	if (appInsightsContainer.instance) {
		appInsightsContainer.instance.trackException({ exception: e });
	}
}

export function trackPageView() {
	if (appInsightsContainer.instance) {
		appInsightsContainer.instance.trackPageView();
	}
}

export function addTelemetryEventForPageView(
	pageType: string | undefined,
	businessEntityNumber: number | undefined,
	data: any,
	language: UILanguage,
) {
	addTelemetryEvent(
		'pageView',
		{ pageType: pageType, businessEntityNumber: businessEntityNumber, ...data },
		language,
	);
}
