import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { appRoutes } from '../appRoutes';
import { HighlightText } from '../Components/HighlightText';
import LumoDatepicker from '../Components/LumoDatepicker';
import { RentalPageSpaceSummary } from '../Components/RentalPageSpaceSummary';
import { ResourceText, ResourceTextInline } from '@kojamo/react-utils';
import { TextHero } from '../Components/TextHero';
import { rentalActions } from '../Data/rentalActions';
import { useOperationComplete } from '../hooks/useOperationComplete';
import { ApiOperationState, isAlreadyRequested, isOperationSuccess, OperationState } from '../operationState';
import { IdentificationFormDataAM, ParkingSpaceRentalAM } from '../Data/rentalApiClient';
import { CurrentParkingSpaceAM } from '../Data/parkingSpaceApiClient';
import { localizedLinks } from '../localizedLinks';
import { AppRootState } from '../Store/appRootState';
import { Spinner } from '../Components/Spinner';
import { BankSelectionOverlay } from '../Components/BankSelectionOverlay';
import QueryString from 'query-string';
import { ErrorMessageDisplay } from '../Components/ErrorMessageDisplay';
import { parkingSpaceActions } from '../Data/parkingSpaceActions';
import { BackToBusinessEntityPage } from '../Components/BackToBusinessEntityPage';
import { pageViewTelemetryActions } from '../Telemetry/pageViewTelemetryActions';
import { BusinessEntityFullAM } from '../Data/businessEntityApiClient';
import { addTelemetryEventGA4, GA4EventAction } from '../Telemetry/TelemetryGA4';
import { useAppLanguage } from '../hooks/useAppLanguage';

interface StateProps {
	currentSpaces: OperationState<CurrentParkingSpaceAM[], string> | undefined;
	saveRentalOp: OperationState<void> | undefined;
	currentRental: ParkingSpaceRentalAM | undefined;
	userEmail: string | undefined;
	shellBaseUrl: string | undefined;
	allowedRentalDays: OperationState<Date[]> | undefined;
	identificationFormOp: OperationState<IdentificationFormDataAM> | undefined;
	businessEntity: OperationState<BusinessEntityFullAM> | undefined;
}

const dispatchProps = {
	saveRental: rentalActions.saveRental.request,
	abortRental: rentalActions.abortRental.request,
	getAllowedRentalDays: rentalActions.getAllowedRentalDays.request,
	getIdentificationForm: rentalActions.getIdentificationForm.request,
	getCurrentParkingSpaces: parkingSpaceActions.getCurrentParkingSpaces.request,
	pageComplete: pageViewTelemetryActions.pageComplete,
};

type DispatchProps = typeof dispatchProps;

type Props = StateProps & DispatchProps;

function RentalPage({
	currentSpaces,
	saveRentalOp,
	currentRental,
	userEmail,
	shellBaseUrl,
	allowedRentalDays,
	identificationFormOp,
	businessEntity,
	saveRental,
	abortRental,
	getAllowedRentalDays,
	getIdentificationForm,
	getCurrentParkingSpaces,
	pageComplete,
}: Props) {
	const { language } = useAppLanguage();
	const allowedDates: Date[] | undefined =
		isOperationSuccess(allowedRentalDays) && allowedRentalDays.result.length ? allowedRentalDays.result : undefined;
	const [sendSms, setSendSms] = useState<boolean>(false);
	const [startDate, setStartDate] = useState<Date | undefined>(allowedDates ? allowedDates[0] : undefined);
	const [spaceIdToTerminate, setSpaceIdToTerminate] = useState<string | undefined>(undefined);
	const [identificationFormClosed, setIdentificationFormClosed] = useState<boolean>(false);

	const identificationForm = isOperationSuccess(identificationFormOp) ? identificationFormOp.result : undefined;

	const identificationFormOperationStatus = identificationFormOp ? identificationFormOp.status : undefined;
	const navigate = useNavigate();
	const queryParams = QueryString.parse(window.location.search.substring(1));
	let errorCode = queryParams['errorCode'];

	useOperationComplete(allowedRentalDays, {
		onSuccess: (result) => setStartDate(result[0]),
	});

	useOperationComplete(saveRentalOp, {
		onSuccess: () => {
			addTelemetryEventGA4({ action: GA4EventAction.parkingspace_saverental, success: true });
			getIdentificationForm('Rental');
		},
		onError: () => {
			if (!currentRental) {
				return;
			}
			addTelemetryEventGA4({ action: GA4EventAction.parkingspace_saverental, success: false });
		},
	});

	useEffect(() => {
		// Update allowed rental start dates
		if (!currentRental) {
			return;
		}
		getAllowedRentalDays({ reservationId: currentRental?.id, spaceIdToSwapAway: spaceIdToTerminate });

		// Get current spaces if not available/requested (might be the case when returning from failed identification)
		if (!isAlreadyRequested(currentSpaces, currentRental.businessEntityId)) {
			getCurrentParkingSpaces(currentRental.businessEntityId);
		}
	}, [spaceIdToTerminate, currentRental, currentSpaces, getAllowedRentalDays, getCurrentParkingSpaces]);

	useEffect(() => {
		if (
			currentRental &&
			currentRental.businessEntityId &&
			isOperationSuccess(businessEntity, currentRental.businessEntityId)
		) {
			const result = businessEntity.result;
			pageComplete({ businessEntityNumber: result.number, pageType: 'Rental' });
		}
	}, [currentRental, businessEntity, pageComplete]);

	const onRentClick = () => {
		if (identificationForm) {
			setIdentificationFormClosed(false); // reopen
		} else {
			saveRental({
				data: {
					sendSmsConfirmation: sendSms,
					startDate: startDate!,
					oldParkingSpaceIdToTerminate: spaceIdToTerminate,
					parkingSpaceId: currentRental!.parkingSpace.id,
				},
				reservationId: currentRental!.id!,
			});
		}
	};

	const onAbortRental = () => {
		abortRental(currentRental!.id);
		navigate(appRoutes.businessEntityPage(currentRental!.businessEntityId));
	};

	if (errorCode && Array.isArray(errorCode)) {
		errorCode = errorCode[0];
	}

	if (currentRental?.parkingSpace == undefined) {
		// Should never happen, redirect to home page
		const isAbortRentalFlow = !!abortRental;
		if (!isAbortRentalFlow) {
			navigate(appRoutes.homePage);
		}
		return null;
	}
	return (
		<>
			{identificationForm && !identificationFormClosed && (
				<BankSelectionOverlay
					identificationForm={identificationForm}
					onClose={() => {
						setIdentificationFormClosed(true);
					}}
				/>
			)}

			<BackToBusinessEntityPage businessEntityId={currentRental!.businessEntityId} />

			{!!errorCode && (
				<ErrorMessageDisplay resourceKey="ErrorMessageDisplay_RentalFailed" resourceParameters={[errorCode]} />
			)}

			<TextHero
				title={<ResourceTextInline resourceKey="RentalPage_Hero_Title" />}
				text={<ResourceTextInline resourceKey="RentalPage_Hero_Text" />}
				smallText={<ResourceTextInline resourceKey="RentalPage_Hero_TextSmall" />}
			/>
			{!isOperationSuccess(currentSpaces) ? (
				<Spinner />
			) : (
				<RentalPageSpaceSummary
					space={currentRental.parkingSpace}
					currentSpaces={currentSpaces.result.filter((r) => !r.contractEndDate)}
					onTerminatedSpaceSelected={(s) => setSpaceIdToTerminate(s?.id)}
				/>
			)}

			<div className="mod mod-datepicker">
				<label className="">
					<ResourceTextInline resourceKey="RentalPage_StartDate" />
				</label>
				<div className="input-date">
					{allowedRentalDays?.status === ApiOperationState.Requested && <Spinner />}
					{allowedDates ? (
						<>
							<LumoDatepicker
								selectedDate={startDate}
								onSetDate={(d) => setStartDate(d)}
								includeDates={allowedDates}
							/>
							<button className="input-date__button-activate">
								<span className="input-date__button-activate-label">
									<span>
										<ResourceTextInline resourceKey="RentalPage_StartDate_Button" />
									</span>
								</span>
							</button>
							<small>
								<ResourceTextInline resourceKey="RentalPage_StartDate_Description" />
							</small>
						</>
					) : (
						<ResourceText resourceKey="RentalPage_StartDate_NoAvailableDates" />
					)}
				</div>
			</div>

			<div className="mod mod-text">
				<h2>
					<ResourceTextInline resourceKey="RentalPage_Contact" />
				</h2>
				{!!userEmail ? (
					<ResourceText allowHtml={true} resourceKey="RentalPage_Contact_Text" parameters={[userEmail]} />
				) : (
					<HighlightText
						version="error"
						text={
							<>
								<span className="content">
									<ResourceTextInline resourceKey="RentalPage_EmailMissing_Text" />
									<a
										href={`${shellBaseUrl}${localizedLinks.customerContact[language]}`}
										target="_top"
									>
										<ResourceTextInline resourceKey="RentalPage_EmailMissing_LinkText" />
									</a>
								</span>
							</>
						}
					/>
				)}
				<div className="input checkbox">
					<input id="sms" className="input" type="checkbox" onChange={(v) => setSendSms(v.target.checked)} />
					<label htmlFor="sms">
						<ResourceTextInline resourceKey="RentalPage_SmsConfirmation" />
					</label>
				</div>
			</div>
			<HighlightText text={<ResourceTextInline resourceKey="RentalPage_Identification_InfoText" />} />

			<div className="mod-ctaGroup">
				<button
					className="mod-ctaGroup__button -primary"
					disabled={!userEmail || !startDate}
					onClick={onRentClick}
				>
					<ResourceTextInline resourceKey="RentalPage_RentButton" />
				</button>
				{identificationFormOperationStatus !== ApiOperationState.Requested && (
					<a href="#" className="mod-ctaGroup__link -back" onClick={onAbortRental}>
						<ResourceTextInline resourceKey="RentalPage_CancelRentalButton" />
					</a>
				)}

				<small>
					<ResourceText resourceKey="RentalPage_ContractPartyNotification_InfoText" />
				</small>
			</div>
		</>
	);
}

export default connect<StateProps, DispatchProps, unknown, AppRootState>((appRootState: AppRootState) => {
	const { rental, parkingSpace, user, businessEntity } = appRootState;
	const currentRental =
		rental.isRentalInProgress && isOperationSuccess(rental.currentRental) ? rental.currentRental.result : undefined;

	const settings = isOperationSuccess(appRootState.appState.settings)
		? appRootState.appState.settings.result
		: undefined;

	return {
		// Navigation to here should be prevented until rental has been started succesfully
		isRentalInProgress:
			rental.isRentalInProgress &&
			rental.currentRental != null &&
			rental.currentRental.status != ApiOperationState.Failed,
		currentSpaces: parkingSpace.currentSpaces,
		userEmail: user.email,
		shellBaseUrl: settings?.shellBaseUrl,
		saveRentalOp: rental.saveRental,
		currentRental: currentRental,
		allowedRentalDays: rental.allowedRentalDays,
		identificationFormOp: rental.identificationForm,
		businessEntity: businessEntity.businessEntity,
	};
}, dispatchProps)(RentalPage);
