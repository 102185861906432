import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { PageViewTelemetryState } from './PageViewTelemetryState';
import { PageViewTelemetryActionTypes, pageViewTelemetryActions } from './pageViewTelemetryActions';

const initialState: PageViewTelemetryState = {};

export const pageViewTelemetryReducer: Reducer<PageViewTelemetryState, PageViewTelemetryActionTypes> = (
	state = initialState,
	action,
) => {
	switch (action.type) {
		case getType(pageViewTelemetryActions.navigatedToNewUrl):
			if (action.payload === state.currentUrl) {
				return state;
			}
			return {
				...state,
				oldUnsentUrl: state.currentUrl && !state.eventHasBeenSent ? state.currentUrl : undefined,
				currentUrl: action.payload,
				eventHasBeenSent: false,
			};

		case getType(pageViewTelemetryActions.eventSent):
			return {
				...state,
				eventHasBeenSent: true,
			};

		default:
			return state;
	}
};
