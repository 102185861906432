import { ActionType, createAsyncAction } from 'typesafe-actions';
import { BusinessEntityFullAM, BusinessEntityTitleAM } from './businessEntityApiClient';

export const businessEntityActions = {
	fetchBusinessEntities: createAsyncAction(
		'businessEntity/BUSINESS_ENTITIES_REQUEST',
		'businessEntity/BUSINESS_ENTITIES_SUCCESS',
		'businessEntity/BUSINESS_ENTITIES_FAILURE',
	)<void, BusinessEntityTitleAM[], Error>(),
	fetchBusinessEntity: createAsyncAction(
		'businessEntity/BUSINESS_ENTITY_REQUEST',
		'businessEntity/BUSINESS_ENTITY_SUCCESS',
		'businessEntity/BUSINESS_ENTITY_FAILURE',
	)<string, BusinessEntityFullAM, Error>(),
};

export type BusinessEntityActionTypes = ActionType<typeof businessEntityActions>;
