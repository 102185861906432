import { ApiClientBase } from './apiClientBase';

export interface SettingsAM {
	ssoAuthority: any;
	cdnRootUrl: string;
	styleSheetUrl: string;
	shellBaseUrl: string;
	serverTime: Date;
}

export class SettingsApiClient extends ApiClientBase {
	public async fetchAppSettings(): Promise<SettingsAM> {
		return await this.fetchJson<SettingsAM>('/api/settings');
	}
}
