import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { appRoutes } from '../appRoutes';
import { HighlightText } from '../Components/HighlightText';
import LumoDatepicker from '../Components/LumoDatepicker';
import { TextHero } from '../Components/TextHero';
import { formatDate } from '../dateUtils';
import { ApiOperationState, isOperationSuccess, OperationState } from '../operationState';
import { AllowedTerminationDatesAM, IdentificationFormDataAM, TerminationDataAIM } from '../Data/rentalApiClient';
import { CurrentParkingSpaceAM } from '../Data/parkingSpaceApiClient';
import { AppRootState } from '../Store/appRootState';
import { ParkingSpaceInformation } from '../Components/ParkingSpaceInformation';
import { terminationActions } from '../Data/terminationActions';
import { Spinner } from '../Components/Spinner';
import { BusinessEntityFullAM } from '../Data/businessEntityApiClient';
import { BankSelectionOverlay } from '../Components/BankSelectionOverlay';
import { Link } from 'react-router-dom';
import { localizedLinks } from '../localizedLinks';
import { BackToBusinessEntityPage } from '../Components/BackToBusinessEntityPage';
import { pageViewTelemetryActions } from '../Telemetry/pageViewTelemetryActions';
import { ResourceText, ResourceTextInline } from '@kojamo/react-utils';
import { useAppLanguage } from '../hooks/useAppLanguage';

interface TerminationPageStateProps {
	space: CurrentParkingSpaceAM;
	businessEntity: BusinessEntityFullAM;
	terminationOperationState: OperationState<void> | undefined;
	allowedTerminationDates: OperationState<AllowedTerminationDatesAM> | undefined;
}

export default function TerminationPage() {
	const dispatch = useDispatch();
	const { space, terminationOperationState, allowedTerminationDates, businessEntity } = useSelector<
		AppRootState,
		TerminationPageStateProps
	>(({ termination, businessEntity }) => {
		return {
			space: termination.spaceToTerminate!,
			businessEntity: isOperationSuccess(businessEntity.businessEntity)
				? businessEntity.businessEntity.result
				: null!,
			terminationOperationState: termination.terminationOperationState,
			allowedTerminationDates: termination.allowedTerminationDates,
		};
	});

	const navigate = useNavigate();

	const allowedTerminationDatesOperationState = allowedTerminationDates?.status;
	const allowedDatesResult = isOperationSuccess(allowedTerminationDates) ? allowedTerminationDates.result : undefined;
	const terminationIsAllowed = allowedDatesResult ? allowedDatesResult.terminationIsAllowed : undefined;

	useEffect(() => {
		dispatch(terminationActions.getAllowedTerminationDates.request(space.id));
	}, [space.id, dispatch]);

	useEffect(() => {
		if (terminationOperationState?.status === ApiOperationState.Succeeded) {
			dispatch(terminationActions.getIdentificationForm.request('Termination'));
		}
	}, [terminationOperationState?.status, dispatch]);

	useEffect(() => {
		dispatch(
			pageViewTelemetryActions.pageComplete({
				businessEntityNumber: businessEntity.number,
				pageType: 'Termination',
			}),
		);
	}, [businessEntity, dispatch]);

	if (space == undefined) {
		// Should never happen, reboot app
		navigate(appRoutes.homePage);
		return null;
	}

	return (
		<>
			<BackToBusinessEntityPage businessEntityId={businessEntity.id} />
			<TextHero
				title={<ResourceTextInline resourceKey="TerminationPage_TextHero_Title" />}
				text={<ResourceTextInline resourceKey="TerminationPage_TextHero_Text" />}
			/>
			<TerminatedParkingSpaceInformation space={space} />
			{allowedTerminationDatesOperationState === ApiOperationState.Requested && <Spinner />}
			{allowedTerminationDatesOperationState === ApiOperationState.Succeeded && terminationIsAllowed && (
				<TerminationActions />
			)}
			{allowedTerminationDatesOperationState === ApiOperationState.Succeeded && !terminationIsAllowed && (
				<HighlightText
					text={<ResourceTextInline resourceKey="TerminationPage_TerminationNotAllowedMessage" />}
				/>
			)}
		</>
	);
}

interface StateProps {
	space: CurrentParkingSpaceAM;
	businessEntity: BusinessEntityFullAM;
	currentSpaces: OperationState<CurrentParkingSpaceAM[]> | undefined;
	terminationOperationState: OperationState<void> | undefined;
	allowedTerminationDates: OperationState<AllowedTerminationDatesAM> | undefined;
	userEmail: string;
	identificationFormOp: OperationState<IdentificationFormDataAM> | undefined;
	shellBaseUrl: string | undefined;
}

function TerminationActions() {
	const { language } = useAppLanguage();
	const dispatch = useDispatch();
	const { space, allowedTerminationDates, businessEntity, identificationFormOp, userEmail, shellBaseUrl } =
		useSelector<AppRootState, StateProps>(({ parkingSpace, termination, user, businessEntity, appState }) => {
			const settings = isOperationSuccess(appState.settings) ? appState.settings.result : undefined;

			return {
				space: termination.spaceToTerminate!,
				businessEntity: isOperationSuccess(businessEntity.businessEntity)
					? businessEntity.businessEntity.result
					: null!,
				currentSpaces: parkingSpace.currentSpaces,
				terminationOperationState: termination.terminationOperationState,
				allowedTerminationDates: termination.allowedTerminationDates,
				userEmail: user.email!,
				identificationFormOp: termination.identificationForm,
				shellBaseUrl: settings?.shellBaseUrl,
			};
		});

	const tomorrow = new Date(new Date().getDate() + 1);
	const earliestAvailableDate = space.futureAvailabilityDate || tomorrow;

	const [sendSms, setSendSms] = useState<boolean>(false);
	const [terminationDate, setStartDate] = useState<Date>(earliestAvailableDate);
	const [identificationFormClosed, setIdentificationFormClosed] = useState<boolean>(false);

	const allowedDatesResult = isOperationSuccess(allowedTerminationDates) ? allowedTerminationDates.result : undefined;
	const allowedDates = allowedDatesResult ? allowedDatesResult.allowedDates : undefined;
	useEffect(() => {
		if (allowedDates && allowedDates.length > 0) {
			setStartDate(allowedDates[0]);
		}
	}, [allowedDates]);

	const identificationForm = isOperationSuccess(identificationFormOp) ? identificationFormOp.result : undefined;

	const identificationFormOperationStatus = identificationFormOp ? identificationFormOp.status : undefined;

	const onTerminateClick = () => {
		if (identificationForm) {
			setIdentificationFormClosed(false); // reopen
		} else {
			const data: TerminationDataAIM = {
				parkingSpaceId: space.id,
				sendSmsConfirmation: sendSms,
				terminationDate: terminationDate,
				businessEntityId: businessEntity.id,
			};
			dispatch(terminationActions.startTermination.request(data));
		}
	};

	return (
		<>
			{identificationForm && !identificationFormClosed && (
				<BankSelectionOverlay
					identificationForm={identificationForm}
					onClose={() => {
						setIdentificationFormClosed(true);
					}}
				/>
			)}
			<div className="mod mod-datepicker">
				<label className="">
					{space.contractEndDate ? (
						<ResourceTextInline resourceKey="TerminationPage_TerminationDateTitle_ToEndEarlier" />
					) : (
						<ResourceTextInline resourceKey="TerminationPage_TerminationDateTitle" />
					)}
				</label>
				<div className="input-date">
					<LumoDatepicker
						selectedDate={terminationDate}
						includeDates={allowedDates}
						onSetDate={(d) => setStartDate(d)}
					/>
					<button className="input-date__button-activate">
						<span className="input-date__button-activate-label">
							<span>
								<ResourceText resourceKey="TerminationPage_TerminationDatePickDate" />
							</span>
						</span>
					</button>
				</div>
				<small>
					<ResourceTextInline resourceKey="TerminationPage_TerminationDateInfoText" />
				</small>
			</div>

			<div className="mod mod-text">
				<h2>
					<ResourceTextInline resourceKey="TerminationPage_Notification_Heading" />
				</h2>

				{!!userEmail ? (
					<ResourceText
						allowHtml={true}
						resourceKey="TerminationPage_Notification_InfoText"
						parameters={[userEmail]}
					/>
				) : (
					<HighlightText
						version="error"
						text={
							<>
								<span className="content">
									<ResourceTextInline resourceKey="RentalPage_RentalBlocked_Text" />
									<a
										href={`${shellBaseUrl}${localizedLinks.customerContact[language]}`}
										target="_top"
									>
										<ResourceTextInline resourceKey="RentalPage_RentalBlocked_LinkText" />
									</a>
								</span>
							</>
						}
					/>
				)}

				<div className="input checkbox">
					<input id="sms" className="input" type="checkbox" onChange={(v) => setSendSms(v.target.checked)} />
					<label htmlFor="sms">
						<ResourceTextInline resourceKey="TerminationPage_Notification_SendSmsLabel" />
					</label>
				</div>
			</div>
			<HighlightText text={<ResourceTextInline resourceKey="TerminationPage_Identification_InfoText" />} />

			<div className="mod-ctaGroup">
				<button className="mod-ctaGroup__button -primary" onClick={onTerminateClick}>
					<ResourceTextInline resourceKey="TerminationPage_TerminateButton" />
				</button>
				{identificationFormOperationStatus !== ApiOperationState.Requested && (
					<Link to={appRoutes.businessEntityPage(businessEntity.id)} className="mod-ctaGroup__link -back">
						<ResourceTextInline resourceKey="TerminationPage_CancelTerminationButton" />
					</Link>
				)}
				<small>
					<ResourceText resourceKey="TerminationPage_ContractPartyNotification_InfoText" />
				</small>
			</div>
		</>
	);
}

const getContractStartDateResourceKey = (startDate: Date) =>
	startDate < new Date()
		? 'TerminationPage_TerminatedSpace_ContractStart_Past'
		: 'TerminationPage_TerminatedSpace_ContractStart_Future';

function TerminatedParkingSpaceInformation(props: { space: CurrentParkingSpaceAM }) {
	return (
		<div className="mod mod-parkingSpaceRental">
			<div className="atom-singleParkingSpace">
				<ParkingSpaceInformation
					title={<ResourceTextInline resourceKey="TerminationPage_RentedParkingSpace_Title" />}
					space={props.space}
					electricChargingInfoFormat="Compact"
				>
					<>
						<li className="atom-singleParkingSpace__singleDetail">
							<span className="detailTitle">
								<ResourceTextInline
									resourceKey={getContractStartDateResourceKey(props.space.contractStartDate)}
								/>
							</span>
							<span>{formatDate(props.space.contractStartDate)}</span>
						</li>
						{props.space.contractEndDate && (
							<li className="atom-singleParkingSpace__singleDetail">
								<span className="detailTitle">
									<ResourceTextInline resourceKey="ParkingSpace_ContractEndDate" />
								</span>
								<span>{formatDate(props.space.contractEndDate)}</span>
							</li>
						)}
					</>
				</ParkingSpaceInformation>
			</div>
		</div>
	);
}
