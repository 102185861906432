/*** Formats the given value as decimal number. Always shows all decimals, does not truncate any digits.
 * - For finnish users, replaces the separator dot with comma
 * - Minimum amount of decimal places can be given (for example for using at least 2 decimals for prices).
 * - Optionally the minimum decimal requirement may be ignored when the value does not have any decimal digits (for example when price is full integer) */
export function formatDecimalNumber(
	language: string,
	value: number | undefined,
	minDecimalPlaces?: number,
	allowValueWithoutDecimals?: boolean,
) {
	if (!value) {
		return '';
	}

	let formatted = value.toString();

	if (minDecimalPlaces) {
		const integer = Math.trunc(value).toString();

		const decimalPlaces = integer.length === formatted.length ? 0 : formatted.substring(integer.length + 1).length;

		if (decimalPlaces < minDecimalPlaces && !(decimalPlaces == 0 && allowValueWithoutDecimals)) {
			formatted = value.toFixed(minDecimalPlaces);
		}
	}

	return language === 'fi' ? formatted.replace('.', ',') : formatted;
}

export function formatWattage(wattage: number | undefined, language: string) {
	if (!wattage) {
		return '';
	}

	const kiloWats = wattage / 1000;
	const isInteger = Math.trunc(kiloWats) === kiloWats;
	return (isInteger ? kiloWats.toFixed(0) : formatDecimalNumber(language, kiloWats, 1)) + ' kW';
}
