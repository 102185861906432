import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { AuthenticationClient } from './Authentication/authenticationClient';
import { UserContext } from './Data/userReducer';
import { AppRootState } from './Store/appRootState';
import { isOperationSuccess } from './operationState';
import { appRoutes } from './appRoutes';
import { ResourceText } from '@kojamo/react-utils';

export interface IOidcCallbackProps {
	silent?: boolean;
}

export interface IOidcCallbackStateProps {
	authority?: string;
	refreshingUser: boolean;
	user: UserContext | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOidcCallbackDispatchProps {}

export type OidcCallbackProps = IOidcCallbackProps & IOidcCallbackStateProps & IOidcCallbackDispatchProps;

export interface IOidcCallbackState {
	authenticationStarted: boolean;
	authenticationFailed: boolean;
}

class OidcCallback extends React.Component<OidcCallbackProps, IOidcCallbackState> {
	constructor(props: OidcCallbackProps) {
		super(props);
		this.state = {
			authenticationStarted: false,
			authenticationFailed: false,
		};

		this.refreshAuthenticationState();
	}

	public async componentDidUpdate() {
		this.refreshAuthenticationState();
	}

	public render() {
		const { user, refreshingUser } = this.props;
		const { authenticationFailed } = this.state;

		if (authenticationFailed && user) {
			return (
				<div className="loading-message">
					<p>
						<ResourceText resourceKey="OidcCallback_Heading_LoginAlreadyProcessed" />
					</p>
					<p>
						<Link to={appRoutes.homePage}>
							<ResourceText resourceKey="OidcCallback_Link_GoToFrontPage" />
						</Link>
					</p>
				</div>
			);
		}

		if (authenticationFailed && !refreshingUser) {
			return (
				<div className="loading-message">
					<p>
						<ResourceText resourceKey="OidcCallback_Heading_LoginFailed" />
					</p>
					<p>
						<Link to={appRoutes.homePage}>
							<ResourceText resourceKey="OidcCallback_Link_GoToFrontPage" />
						</Link>
					</p>
				</div>
			);
		}

		return (
			<div className="loading-message">
				<ResourceText resourceKey="OidcCallback_Heading_ProcessingLogin" />
			</div>
		);
	}

	private async refreshAuthenticationState() {
		const { authority, silent } = this.props;
		if (!authority || this.state.authenticationStarted) {
			return;
		}

		this.setState({ authenticationStarted: true });

		const authenticationClient = new AuthenticationClient({ authority });

		if (silent) {
			await authenticationClient.receiveSilent();
			return;
		}

		// TODO
		// If silent auth fails, should redirect parent window to SSO. Shell should take care of that though.

		// try {
		// 	const user = await authenticationClient.receiveInteractive();

		// 	// todo: unauthenticated page, that receives query parameters
		// 	// todo: get source and contractId from cookie, and pass then to logAndCompleteLogin action on the next line
		// 	logAndCompleteLogin({ user });
		// 	history.push(authenticationClient.getPostLoginRedirectUrl());
		// } catch (e) {
		// 	reportError(e);
		// 	this.setState({ authenticationFailed: true });
		// }
	}
}
const mapStateToProps = ({ appState, user }: AppRootState): IOidcCallbackStateProps => ({
	authority: isOperationSuccess(appState.settings) ? appState.settings.result.ssoAuthority : undefined,
	user: user.userInfo.userContext || null,
	refreshingUser: user.isRefreshing,
});

export default connect(mapStateToProps)(OidcCallback);
