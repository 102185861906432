import { ActionType, createAsyncAction } from 'typesafe-actions';
import { CurrentParkingSpaceAM } from './parkingSpaceApiClient';

export const parkingSpaceActions = {
	getCurrentParkingSpaces: createAsyncAction(
		'parkingSpace/GET_CURRENT_SPACES_REQUEST',
		'parkingSpace/GET_CURRENT_SPACES_SUCCESS',
		'parkingSpace/GET_CURRENT_SPACES_FAILURE',
	)<string, CurrentParkingSpaceAM[], Error>(),
};

export type ParkingSpaceActionTypes = ActionType<typeof parkingSpaceActions>;
