import { ElectricChargingPriceAM } from '../Data/ParkingSpaceAM';
import { useResource } from '@kojamo/react-utils';
import { trim } from 'lodash';
import { formatDecimalNumber } from '../electricChargingUtils';
import { useAppLanguage } from '../hooks/useAppLanguage';

const ChargingPrice = ({ price }: { price: ElectricChargingPriceAM | undefined }) => {
	const { language } = useAppLanguage();
	const { getResourceText } = useResource();
	if (!price) {
		return null;
	}

	let result = '';
	if (price.pricePerMonth) {
		const suffix = getResourceText('ElectricCharging_MonthlyPriceAmountSuffix');
		result = formatDecimalNumber(language, price.pricePerMonth, 2, true) + '\u00A0' + suffix;
		if (price.descriptionForPricePerMonth && trim(price.descriptionForPricePerMonth)) {
			result += ' (' + price.descriptionForPricePerMonth + ')';
		}

		if (price.pricePerKWh) {
			result += ' + ';
		}
	}

	if (price.pricePerKWh) {
		const suffix = getResourceText('ElectricCharging_KwhPriceAmountSuffix');
		result += formatDecimalNumber(language, price.pricePerKWh, 2) + '\u00A0' + suffix;
		if (price.descriptionForPricePerKWh && trim(price.descriptionForPricePerKWh)) {
			result += ' (' + price.descriptionForPricePerKWh + ')';
		}
	}

	return <span>{result}</span>;
};

export default ChargingPrice;
