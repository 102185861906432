import { ActionType, getType } from 'typesafe-actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { searchWatchActions } from './searchWatchActions';
import { SearchWatchApiClient, WatchAM } from './searchWatchApiClient';
import { AppRootState } from '../Store/appRootState';
import { UILanguage, UserInfo, UserState } from './userReducer';

function* createSearchWatch(action: ActionType<typeof searchWatchActions.createSearchWatch.request>) {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userInfo: UserInfo = yield select((state: AppRootState) => state.user.userInfo);
		const userState: UserState = yield select((state: AppRootState) => state.user);
		const api = new SearchWatchApiClient(language, userInfo, userState.antiForgeryToken);
		const result: WatchAM = yield call([api, api.createSearchWatch], action.payload);
		yield put(searchWatchActions.createSearchWatch.success(result));
	} catch (e: any) {
		yield put(searchWatchActions.createSearchWatch.failure(e));
	}
}

function* editSearchWatch(action: ActionType<typeof searchWatchActions.editSearchWatch.request>) {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userInfo: UserInfo = yield select((state: AppRootState) => state.user.userInfo);
		const userState: UserState = yield select((state: AppRootState) => state.user);
		const api = new SearchWatchApiClient(language, userInfo, userState.antiForgeryToken);
		const result: WatchAM = yield call([api, api.editSearchWatch], action.payload);
		yield put(searchWatchActions.editSearchWatch.success(result));
	} catch (e: any) {
		yield put(searchWatchActions.editSearchWatch.failure(e));
	}
}

function* fetchSearchWatch() {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userInfo: UserInfo = yield select((state: AppRootState) => state.user.userInfo);
		const api = new SearchWatchApiClient(language, userInfo);
		const result: WatchAM = yield call([api, api.fetchSearchWatch]);
		yield put(searchWatchActions.fetchSearchWatch.success(result));
	} catch (e: any) {
		yield put(searchWatchActions.fetchSearchWatch.failure(e));
	}
}

function* deleteSearchWatch(action: ActionType<typeof searchWatchActions.deleteSearchWatch.request>) {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userInfo: UserInfo = yield select((state: AppRootState) => state.user.userInfo);
		const userState: UserState = yield select((state: AppRootState) => state.user);
		const api = new SearchWatchApiClient(language, userInfo, userState.antiForgeryToken);
		yield call([api, api.deleteSearchWatch], action.payload);
		yield put(searchWatchActions.deleteSearchWatch.success());
	} catch (e: any) {
		yield put(searchWatchActions.deleteSearchWatch.failure(e));
	}
}

export default function* searchWatchSaga() {
	yield takeLatest(getType(searchWatchActions.createSearchWatch.request), createSearchWatch);
	yield takeLatest(getType(searchWatchActions.editSearchWatch.request), editSearchWatch);
	yield takeLatest(getType(searchWatchActions.fetchSearchWatch.request), fetchSearchWatch);
	yield takeLatest(getType(searchWatchActions.deleteSearchWatch.request), deleteSearchWatch);
}
