import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { rentalActions } from '../Data/rentalActions';
import { AppRootState } from '../Store/appRootState';
import { CurrentParkingSpaceAM } from '../Data/parkingSpaceApiClient';
import { BusinessEntityFullAM, ParkingSpaceKind } from '../Data/businessEntityApiClient';
import { ParkingSpaceRentalAM } from '../Data/rentalApiClient';
import { ParkingSpaceAM } from '../Data/ParkingSpaceAM';
import { ApiOperationState, isAlreadyRequested, isOperationSuccess, OperationState } from '../operationState';
import { ImageCarousel } from '../Components/ImageCarousel';
import { appRoutes } from '../appRoutes';
import { CurrentParkingSpaceInformation } from '../Components/ParkingSpaceInformation';
import { EditSearchWatchBanner, NewSearchWatchBanner } from '../Components/SearchWatchBanners';
import { LoginBanner } from '../Components/LoginBanner';
import { OngoingRentalOverlay } from '../Components/OngoingRentalOverlay';
import { RentalBlockedOverlay } from '../Components/RentalBlockedOverlay';
import { ChangeBusinessEntitySticky } from '../Components/ChangeBusinessEntitySticky';
import { localizedLinks } from '../localizedLinks';
import { useOperationComplete } from '../hooks/useOperationComplete';
import { Spinner } from '../Components/Spinner';
import { SettingsAM } from '../Data/settingsApiClient';
import { businessEntityActions } from '../Data/businessEntityActions';
import { parkingSpaceActions } from '../Data/parkingSpaceActions';
import QueryString from 'query-string';
import { ParkingSpaceCard } from '../Components/ParkingSpaceCard';
import { searchWatchActions } from '../Data/searchWatchActions';
import { WatchAM } from '../Data/searchWatchApiClient';
import { ParkingSpaceNotFoundOverlay } from '../Components/ParkingSpaceNotFoundOverlay';
import { Link } from 'react-router-dom';
import { terminationActions } from '../Data/terminationActions';
import { addMinutes } from '../dateUtils';
import { pageViewTelemetryActions } from '../Telemetry/pageViewTelemetryActions';
import { notifyShellNavigatedTo } from '../shellIntegration';
import { addTelemetryEventGA4, GA4EventAction } from '../Telemetry/TelemetryGA4';
import { ResourceText, ResourceTextInline, useResource } from '@kojamo/react-utils';
import { useAppLanguage } from '../hooks/useAppLanguage';

type StateProps = {
	businessEntity: OperationState<BusinessEntityFullAM, string> | undefined;
	businessEntityLastFetched: Date | undefined;
	settings: SettingsAM | undefined;
	usersBusinessEntityId: string | undefined;
	currentSpaces: OperationState<CurrentParkingSpaceAM[], string> | undefined;
	isLoggedIn: boolean;
	rentalRequest: OperationState<ParkingSpaceRentalAM> | undefined;
	currentRental: OperationState<ParkingSpaceRentalAM> | undefined;
	fetchSearchWatchOperation: OperationState<WatchAM> | undefined;
};
const dispatchProps = {
	fetchBusinessEntity: businessEntityActions.fetchBusinessEntity.request,
	getCurrentParkingSpaces: parkingSpaceActions.getCurrentParkingSpaces.request,
	startRental: rentalActions.startRental.request,
	clearRentalState: rentalActions.clearRentalState,
	setParkingSpaceToBeTerminated: terminationActions.setParkingSpaceToBeTerminated,
	abortRental: rentalActions.abortRental.request,
	fetchSearchWatch: searchWatchActions.fetchSearchWatch.request,
	pageComplete: pageViewTelemetryActions.pageComplete,
};
type DispatchProps = typeof dispatchProps;

type Props = StateProps & DispatchProps;
type RouteParams = { businessEntityId: string };

function BusinessEntityPage(props: Props) {
	const {
		businessEntity,
		usersBusinessEntityId,
		businessEntityLastFetched,
		fetchBusinessEntity,
		fetchSearchWatchOperation,
		fetchSearchWatch,
		clearRentalState,
		currentRental,
		currentSpaces,
		getCurrentParkingSpaces,
		setParkingSpaceToBeTerminated,
		abortRental,
		rentalRequest,
		startRental,
		pageComplete,
		isLoggedIn,
		settings,
	} = props;
	const { language } = useAppLanguage();
	const { getResourceText } = useResource();
	const { businessEntityId } = useParams<RouteParams>();
	const beOperation = businessEntity;
	const [rentalBlockedError, setRentalBlockedError] = useState<string | undefined>(undefined);
	const [rentalStartNeedsHandling, setRentalStartNeedsHandling] = useState<boolean | undefined>(undefined);
	const [selectedKind, setSelectedKind] = useState<ParkingSpaceKind | undefined>(undefined);
	const [visibleSpaces, setVisibleSpaces] = useState<ParkingSpaceAM[]>([]);
	const [requestedSpaceNotFound, setRequestedSpaceNotFound] = useState<boolean>(false);
	const [spaceNotFoundOverlayClosed, setSpaceNotFoundOverlayClosed] = useState<boolean>(false);
	const [ongoingRentalConfirmationRequiredFor, setOngoingRentalConfirmationRequiredFor] = useState<
		ParkingSpaceAM | undefined
	>(undefined);
	const navigate = useNavigate();

	const queryParams = QueryString.parse(window.location.search.substring(1));
	const spaceIdParam = queryParams['spaceId'];

	const spaceIdToHighLight = !!spaceIdParam
		? Array.isArray(spaceIdParam)
			? spaceIdParam[0]
			: spaceIdParam
		: undefined;

	const filterClicked = (kind: ParkingSpaceKind) => {
		if (kind === selectedKind) {
			setSelectedKind(undefined);
		} else {
			setSelectedKind(kind);
		}
	};

	useEffect(() => {
		setSelectedKind(undefined);
	}, [businessEntityId]);

	useEffect(() => {
		// Set business-entity specific shell url, only if not logged in
		if (!isLoggedIn) {
			notifyShellNavigatedTo(appRoutes.businessEntityPage(businessEntityId));
		}
		// If user is logged in, set home page url as that routes to business entity page (keep BE-id away from url)
		// without this he'll see search watch / rental / termination url's indefinitely
		// It's also possible that logged in user is watching other business entity's information (linked from lumo.fi)
		else {
			const isDefaultBusinessEntity = businessEntityId === usersBusinessEntityId;
			notifyShellNavigatedTo(
				isDefaultBusinessEntity ? appRoutes.homePage : appRoutes.businessEntityPage(businessEntityId),
			);
		}
	}, [businessEntityId, isLoggedIn, usersBusinessEntityId]);

	useEffect(() => {
		if (
			(!isAlreadyRequested(businessEntity, businessEntityId) ||
				businessEntityLastFetched! < addMinutes(new Date(), -1)) &&
			typeof businessEntityId === 'string'
		) {
			fetchBusinessEntity(businessEntityId);
		}

		if (isLoggedIn && usersBusinessEntityId === businessEntityId) {
			if (!isAlreadyRequested(currentSpaces, businessEntityId) && typeof businessEntityId === 'string') {
				getCurrentParkingSpaces(businessEntityId);
			}

			if (!isAlreadyRequested(fetchSearchWatchOperation)) {
				fetchSearchWatch();
			}
		}
	}, [
		businessEntityId,
		isLoggedIn,
		usersBusinessEntityId,
		businessEntity,
		businessEntityLastFetched,
		fetchBusinessEntity,
		currentSpaces,
		getCurrentParkingSpaces,
		fetchSearchWatchOperation,
		fetchSearchWatch,
	]);

	useEffect(() => {
		if (isOperationSuccess(businessEntity, businessEntityId)) {
			const result = businessEntity.result;
			if (selectedKind) {
				const spaces = result.availableParkingSpaces.filter((s) => isFilterMatch(s, selectedKind));
				setVisibleSpaces(spaces);
			} else {
				if (visibleSpaces.length != result.availableParkingSpaces.length) {
					setVisibleSpaces(result.availableParkingSpaces);
				}
			}
		} else if (businessEntity?.status === ApiOperationState.Failed && businessEntity.param == businessEntityId) {
			navigate(appRoutes.homePage);
		}
	}, [businessEntity, selectedKind, navigate, businessEntityId, visibleSpaces.length]);

	const isSpaceAvailable = (be: BusinessEntityFullAM, spaceId: string) =>
		be.availableParkingSpaces.some((s) => s.id === spaceId);

	useEffect(() => {
		if (isOperationSuccess(businessEntity, businessEntityId)) {
			const result = businessEntity.result;

			const data = spaceIdToHighLight
				? {
						isLoggedIn: isLoggedIn,
						spaceId: spaceIdToHighLight,
						isSpaceAvailable: isSpaceAvailable(result, spaceIdToHighLight),
				  }
				: {
						isLoggedIn: isLoggedIn,
				  };

			pageComplete({
				businessEntityNumber: result.number,
				pageType: 'BusinessEntity',
				data: data,
			});
		}
	}, [businessEntity, spaceIdToHighLight, isLoggedIn, pageComplete, businessEntityId]);

	useOperationComplete(businessEntity, {
		onSuccess: (result) => {
			if (spaceIdToHighLight && !isSpaceAvailable(result, spaceIdToHighLight)) {
				setRequestedSpaceNotFound(true);
			}
		},
	});

	useOperationComplete(rentalRequest, {
		onSuccess: () => {
			// check need to skip re-handling of rental start when user returns to this page during rental
			if (rentalStartNeedsHandling) {
				addTelemetryEventGA4({ action: GA4EventAction.parkingspace_reservation, success: true });
				setRentalStartNeedsHandling(false);
				navigate(appRoutes.rentalPage);
			}
		},
		onError: (error: any) => {
			if (rentalStartNeedsHandling) {
				let err;
				if ('errorCode' in error) {
					err = error['errorCode'];
				} else {
					err = 'InternalError';
				}
				addTelemetryEventGA4({ action: GA4EventAction.parkingspace_reservation, success: false });
				setRentalBlockedError(err);
			}
		},
	});

	if (!isOperationSuccess(beOperation) || currentSpaces?.status === 'Requested') {
		return <Spinner />;
	}

	const be = beOperation.result;

	const images = be.parkingSpaceImages.map((i) => ({
		url: i.rootUrl,
		caption: i.caption,
	}));

	const title = (
		<>
			{isLoggedIn && (
				<div className="mod-backLink">
					<a
						href={`${settings?.shellBaseUrl}${localizedLinks.root[language]}`}
						target="_top"
						className="mod-backLink__link"
					>
						<svg width="12" height="10" viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg">
							<path
								fill="#F05183"
								d="M12 5c0 .4-.3.7-.7.7h-9L5 8.5c.3.3.3.7 0 1l-.4.2-.5-.2-4-4-.1-.2v-.6l.1-.2 4-4c.3-.2.7-.2 1 0 .2.3.2.7 0 1l-3 2.8h9.1c.4 0 .7.3.7.7z"
							></path>
						</svg>
						<ResourceTextInline resourceKey="BackToMyLumoFrontPage_LinkText" />
					</a>
				</div>
			)}
			<div className="titleGroup">
				<h2 className="subtitle">
					<ResourceTextInline resourceKey="BusinessEntityPage_SubTitle" />
				</h2>
				<h1>{be.name}</h1>
				{isLoggedIn && usersBusinessEntityId !== businessEntityId && (
					<Link to={appRoutes.homePage} className="mod-ctaGroup__link to-own-house">
						<ResourceTextInline resourceKey="BusinessEntityPage_BackToOwnBusinessEntityLink" />
					</Link>
				)}
			</div>
		</>
	);

	const locationChangeSticky = !isLoggedIn ? (
		<ChangeBusinessEntitySticky
			onOpened={() => {
				addTelemetryEventGA4({ action: GA4EventAction.parkingspace_changebusinessentitysticky_open });
			}}
		/>
	) : null;

	const watch = isOperationSuccess(fetchSearchWatchOperation) ? fetchSearchWatchOperation.result : undefined;

	if (!be.parkingSpacesReservableInMyLumo) {
		return (
			<>
				{title}
				<ResourceText resourceKey="BusinessEntityPage_ExternalParkingSpaceManagement" />
				{be.externalParkingSpaceManagementDescription && (
					<ResourceText
						resourceKey="BusinessEntityPage_ExternalParkingSpaceManagement_Detail"
						parameters={[be.externalParkingSpaceManagementDescription]}
					/>
				)}

				{locationChangeSticky}
			</>
		);
	}

	const requestRental = (p: ParkingSpaceAM) => {
		setRentalStartNeedsHandling(true);
		if (typeof businessEntityId === 'string') {
			startRental({ parkingSpace: p, businessEntityId: businessEntityId });
		}
	};

	const onRentClick = (p: ParkingSpaceAM) => {
		if (
			isOperationSuccess(currentRental) &&
			currentRental.result &&
			currentRental.result.state !== 'RentalComplete'
		) {
			setOngoingRentalConfirmationRequiredFor(p);
		} else {
			requestRental(p);
		}
	};

	const onConfirmChangeRental = () => {
		const spaceToRent = ongoingRentalConfirmationRequiredFor;
		if (!spaceToRent) {
			// This should not have been called if it was undefined
			return;
		}
		setOngoingRentalConfirmationRequiredFor(undefined);
		const currentRentalOperation = isOperationSuccess(currentRental) && currentRental.result;
		if (!currentRentalOperation) {
			// This should never happen
			return;
		}

		abortRental(currentRentalOperation.id);
		requestRental(spaceToRent);
	};

	const onTerminateClick = (p: CurrentParkingSpaceAM) => {
		setParkingSpaceToBeTerminated(p);
		navigate(appRoutes.terminationPage);
	};

	const ownSpaces = isOperationSuccess(currentSpaces) ? currentSpaces.result : [];
	return (
		<>
			{rentalBlockedError ? (
				<RentalBlockedOverlay
					onClose={() => {
						setRentalBlockedError(undefined);
						clearRentalState();
					}}
					reasonCode={rentalBlockedError}
					rentalPaymentLink={`${settings?.shellBaseUrl || ''}${localizedLinks.rentalPayment[language]}`}
				/>
			) : null}

			{!!ongoingRentalConfirmationRequiredFor && (
				<OngoingRentalOverlay
					onClose={() => setOngoingRentalConfirmationRequiredFor(undefined)}
					onConfirm={onConfirmChangeRental}
					currentReservedSpace={
						(isOperationSuccess(currentRental) && currentRental.result.parkingSpace) || undefined! // Should never be undefined as this dialog is only shown if current rental completed with value
					}
				/>
			)}

			{requestedSpaceNotFound && !spaceNotFoundOverlayClosed && (
				<ParkingSpaceNotFoundOverlay
					onClose={() => {
						setSpaceNotFoundOverlayClosed(true);
						navigate(appRoutes.businessEntityPage(businessEntityId));
					}}
				/>
			)}

			<div className={'mod-parkingSpaceBusinessEntityHero' + (ownSpaces.length ? ' -hasSpaces' : '')}>
				{title}
				<ImageCarousel images={images} fullImagePreset={'780x520-shop-carousel'} />

				{!isLoggedIn && (
					<LoginBanner
						shellBaseUrl={settings?.shellBaseUrl || ''}
						loginClicked={() => {
							addTelemetryEventGA4({ action: GA4EventAction.parkingspace_login_businessentitypage });
						}}
					/>
				)}

				<div className="mod-parkingSpaceBusinessEntityHero__side">
					{ownSpaces.length ? (
						<>
							<h2>
								<ResourceTextInline resourceKey="BusinessEntityPage_OwnParkingSpaces" />
							</h2>

							{ownSpaces.map((p) => (
								<CurrentParkingSpaceInformation
									key={p.id}
									space={p}
									onTerminateClick={onTerminateClick}
								/>
							))}
						</>
					) : (
						''
					)}
				</div>

				{isLoggedIn && usersBusinessEntityId === businessEntityId && (
					<>
						{!watch && <NewSearchWatchBanner />}

						{watch && <EditSearchWatchBanner watch={watch} />}
					</>
				)}
			</div>
			<h2>
				<ResourceTextInline
					allowHtml={true}
					resourceKey="BusinessEntityPage_AvailableSpaceCountHeading"
					parameters={[be.availableParkingSpaces.length.toString()]}
				/>
			</h2>

			<ul className="mod-filters">
				{Object.keys(be.parkingSpaceKinds).map((k) => {
					const kind = k as ParkingSpaceKind;
					const noSpaces = !be.parkingSpaceKinds[kind];
					const isSelected = selectedKind == kind;

					return (
						<li
							tabIndex={0}
							key={kind}
							className={
								'mod-filters__tag' + (noSpaces ? ' -disabled' : '') + (isSelected ? ' -selected' : '')
							}
							onClick={() => filterClicked(kind)}
						>
							<ResourceTextInline resourceKey={'Filter_' + kind} />
						</li>
					);
				})}
			</ul>
			<ul className="mod-parkingSpaceList">
				{visibleSpaces.map((s) => (
					<ParkingSpaceCard
						parkingSpace={s}
						key={s.id}
						allowRental={be.isRentalAllowedForUser}
						onRentClick={onRentClick}
						isHighLighted={s.id === spaceIdToHighLight}
					/>
				))}
				{visibleSpaces.length === 0 && (
					<div className="no-results">
						<ResourceText resourceKey="BusinessEntityPage_NoResults" />
					</div>
				)}
			</ul>
			<div className="legal">
				<a className="atom-downloadMaterial__box" target="_new" href={getResourceText('TermsOfUseLink')}>
					<svg
						className="atom-downloadMaterial__icon"
						width="33"
						height="30"
						viewBox="0 0 33 30"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g
							stroke="#F05183"
							strokeWidth="2"
							fill="none"
							fillRule="evenodd"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path d="M9.5 28.5h-5a3 3 0 01-3-3v-24h11l3 5h16v19a3 3 0 01-3 3h-5M16.5 28.5v-14" />
							<path d="M21.5 23.5l-5 5-5-5" />
						</g>
					</svg>
					<div className="atom-downloadMaterial__link">
						<span className="atom-downloadMaterial__linkText">
							<ResourceText resourceKey="TermsOfUseLinkText" />
						</span>
					</div>
				</a>
			</div>
			{locationChangeSticky}
		</>
	);
}

function isFilterMatch(space: ParkingSpaceAM, kind: ParkingSpaceKind) {
	switch (kind) {
		case 'HandicappedParking':
			return space.isHandicappedParking;
		case 'Heated':
			return space.isHeated;
		case 'HeatingPlug':
			return space.hasHeatingPlug;
		case 'SupportsElectricCharging':
			return space.supportsElectricCharging;
		case 'MotorcycleParking':
			return space.isMotorcycleParking;
		case 'ParkingGarage':
			return space.type == 'ParkingGarage';
		case 'ParkingHall':
			return space.type == 'ParkingHall';
		case 'ParkingSpace':
			return space.type == 'ParkingSpace';
		case 'Shelter':
			return space.type == 'Shelter';
	}
}

export default connect<StateProps, DispatchProps, unknown, AppRootState>(
	({ parkingSpace, rental, appState, user, businessEntity, searchWatch }) => ({
		businessEntity: businessEntity.businessEntity,
		businessEntityLastFetched: businessEntity.businessEntityLastFetched,
		settings: isOperationSuccess(appState.settings) ? appState.settings.result : undefined,
		usersBusinessEntityId: user.businessEntityId,
		currentSpaces: parkingSpace.currentSpaces,
		isLoggedIn: !!user.userInfo?.userContext,
		rentalRequest: rental.startRental,
		currentRental: rental.currentRental,
		fetchSearchWatchOperation: searchWatch.currentSearchWatch,
	}),
	dispatchProps,
)(BusinessEntityPage);
