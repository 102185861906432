import { Link } from 'react-router-dom';

interface Props {
	title: React.ReactElement;
	body: React.ReactElement;
	icon: React.ReactElement;
	iconMode?: 'Turqouise';

	buttonText: React.ReactElement;
	buttonLinkTarget?: string;
	onButtonClick?: (event: React.MouseEvent) => void;

	linkText?: React.ReactElement;
	linkTarget?: string;
	onLinkClick?: (event: React.MouseEvent) => void;
}

export function IconBanner(props: Props) {
	const button = props.buttonText && (
		<button className="atom-iconBanner__button button" onClick={props.onButtonClick}>
			{props.buttonText}
		</button>
	);

	return (
		<div className={'atom-iconBanner' + (props.iconMode ? ` -icon${props.iconMode}` : '')}>
			<div className="atom-iconBanner__imageWrapper">{props.icon}</div>
			<div className="atom-iconBanner__textWrapper">
				<h3 className="atom-iconBanner__title">{props.title}</h3>
				<p className="atom-iconBanner__text">{props.body}</p>
			</div>
			{button &&
				(props.buttonLinkTarget ? (
					<Link to={props.buttonLinkTarget} state={null}>
						{button}
					</Link>
				) : (
					button
				))}
			{props.linkText && (
				<Link
					to={props.linkTarget || '#'}
					state={null}
					className="atom-iconBanner__link link"
					onClick={props.onLinkClick}
				>
					{props.linkText}
				</Link>
			)}
		</div>
	);
}
