import { ResourceText, ResourceTextInline } from '@kojamo/react-utils';

interface Props {
	heading: React.ReactElement;
	body: React.ReactElement;
	onClose: () => void;
}

export function Overlay(props: Props) {
	return (
		<section className="overlay">
			<div className="card">
				<a className="close-button" onClick={() => props.onClose()}>
					<span className="lines"></span>
				</a>
				<h3>{props.heading}</h3>
				{props.body}
			</div>
		</section>
	);
}

export function RentalTimeoutDialog(props: { minutesToExpiration: number; onClose: () => void }) {
	return (
		<Overlay
			onClose={props.onClose}
			heading={<ResourceTextInline resourceKey="Overlay_RentalTimeout_Heading" />}
			body={
				<>
					<ResourceText
						resourceKey="Overlay_RentalTimeout_Body"
						parameters={[props.minutesToExpiration.toString()]}
					/>
					<a className="link" href="#" onClick={props.onClose}>
						<ResourceTextInline resourceKey="Overlay_RentalTimeout_Back" />
						&nbsp;›
					</a>
				</>
			}
		/>
	);
}
