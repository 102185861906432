import { ActionType, getType } from 'typesafe-actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
	AllowedTerminationDatesAM,
	IdentificationFormDataAM,
	RentalApiClient,
	TerminationCompletionInfoAM,
} from './rentalApiClient';
import { AppRootState } from '../Store/appRootState';
import { UILanguage, UserInfo, UserState } from './userReducer';
import { terminationActions } from './terminationActions';

function* getIdentificationForm(action: ActionType<typeof terminationActions.getIdentificationForm.request>) {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userInfo: UserInfo = yield select((state: AppRootState) => state.user.userInfo);
		const api = new RentalApiClient(language, userInfo);
		const result: IdentificationFormDataAM = yield call([api, api.getIdentificationForm], action.payload);
		yield put(terminationActions.getIdentificationForm.success(result));
	} catch (e: any) {
		yield put(terminationActions.getIdentificationForm.failure(e));
	}
}

function* startTermination(action: ActionType<typeof terminationActions.startTermination.request>) {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userState: UserState = yield select((state: AppRootState) => state.user);
		const api = new RentalApiClient(language, userState.userInfo, userState.antiForgeryToken);

		yield call([api, api.startTermination], action.payload);
		yield put(terminationActions.startTermination.success());
	} catch (e: any) {
		yield put(terminationActions.startTermination.failure(e));
	}
}

function* getAllowedTerminationDates(action: ActionType<typeof terminationActions.getAllowedTerminationDates.request>) {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userInfo: UserInfo = yield select((state: AppRootState) => state.user.userInfo);
		const api = new RentalApiClient(language, userInfo);
		const result: AllowedTerminationDatesAM = yield call([api, api.getAllowedTerminationDates], action.payload);
		yield put(terminationActions.getAllowedTerminationDates.success(result));
	} catch (e: any) {
		yield put(terminationActions.getAllowedTerminationDates.failure(e));
	}
}

function* finalizeTermination() {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userState: UserState = yield select((state: AppRootState) => state.user);
		const api = new RentalApiClient(language, userState.userInfo, userState.antiForgeryToken);
		const result: TerminationCompletionInfoAM = yield call([api, api.finalizeTermination]);
		yield put(terminationActions.finalizeTermination.success(result));
	} catch (e: any) {
		yield put(terminationActions.finalizeTermination.failure(e));
	}
}

function* terminationSaga() {
	yield takeLatest(getType(terminationActions.getAllowedTerminationDates.request), getAllowedTerminationDates);
	yield takeLatest(getType(terminationActions.startTermination.request), startTermination);
	yield takeLatest(getType(terminationActions.getIdentificationForm.request), getIdentificationForm);
	yield takeLatest(getType(terminationActions.finalizeTermination.request), finalizeTermination);
}

export default terminationSaga;
