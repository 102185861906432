import { ApiClientBase } from './apiClientBase';
import { ParkingSpaceAM } from './ParkingSpaceAM';

export type CurrentParkingSpaceAM = ParkingSpaceAM & {
	contractStartDate: Date;
	contractEndDate: Date | undefined;
	terminationAllowed: boolean | undefined;
};
export class ParkingSpaceApiClient extends ApiClientBase {
	public async fetchCurrentSpaces(businessEntityId: string): Promise<CurrentParkingSpaceAM[]> {
		return await this.fetchJson<CurrentParkingSpaceAM[]>(
			`/api/parking-spaces/by-business-entity/${businessEntityId}/my`,
		);
	}
}
