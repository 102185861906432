import React, { useEffect, useState } from 'react';
import { CurrentParkingSpaceAM } from '../Data/parkingSpaceApiClient';
import { ParkingSpaceAM } from '../Data/ParkingSpaceAM';
import { ParkingSpaceInformation, getContractStartDateResourceKey } from './ParkingSpaceInformation';
import { ResourceText, useResource, ResourceTextInline } from '@kojamo/react-utils';
import { formatDate } from '../dateUtils';
import { getParkingSpaceCharacteristics } from '../Common/getParkingSpaceCharacteristics';

interface Props {
	space: ParkingSpaceAM;
	currentSpaces: CurrentParkingSpaceAM[];
	onTerminatedSpaceSelected: (s: CurrentParkingSpaceAM | undefined) => void;
}

export function RentalPageSpaceSummary(props: Props) {
	return (
		<div className="mod mod-parkingSpaceRental">
			<div className="atom-singleParkingSpace">
				<NewParkingSpaceInformation space={props.space} />
				<ChangeParkingSpaceSelection
					currentSpaces={props.currentSpaces}
					selectedSpaceCallback={props.onTerminatedSpaceSelected}
				/>
			</div>
		</div>
	);
}

/** On renting page, the information of the space to be rented  */
function NewParkingSpaceInformation(props: { space: ParkingSpaceAM }) {
	const { space } = props;

	return (
		<ParkingSpaceInformation
			title={<ResourceTextInline resourceKey="ParkingSpace_NewParkingSpace_Title" />}
			space={space}
			electricChargingInfoFormat="Detailed"
		>
			<li className="atom-singleParkingSpace__singleDetail">
				<span className="detailTitle">
					<ResourceTextInline resourceKey="ParkingSpace_AvailableFrom" />
				</span>
				<span>
					{!space.futureAvailabilityDate ? (
						<ResourceTextInline resourceKey="ParkingSpace_AvailableFrom_AlreadyAvailable" />
					) : (
						formatDate(space.futureAvailabilityDate)
					)}
				</span>
			</li>
		</ParkingSpaceInformation>
	);
}

function ChangeParkingSpaceSelection(props: {
	currentSpaces: CurrentParkingSpaceAM[];
	selectedSpaceCallback: (s: CurrentParkingSpaceAM | undefined) => void;
}) {
	const [selectedSpace, setSelectedSpace] = useState<CurrentParkingSpaceAM | undefined>(undefined);
	const { getResourceText } = useResource();
	const { currentSpaces, selectedSpaceCallback } = props;
	const currentParkingSpaceCount = currentSpaces.length;

	const onConfirmReplaceCurrentSpace = (event: React.FormEvent<HTMLInputElement>) => {
		const target = event.currentTarget;
		const isChecked = target.checked;

		setSelectedSpace(isChecked ? currentSpaces[0] : undefined);
	};

	useEffect(() => {
		selectedSpaceCallback(selectedSpace);
	}, [selectedSpace, selectedSpaceCallback]);

	const checkboxForSwapping = () => {
		return (
			<div className="atom-singleParkingSpace__highlight">
				<div className="input checkbox">
					<input id="changeSpace" className="input" type="checkbox" onChange={onConfirmReplaceCurrentSpace} />
					<label htmlFor="changeSpace" className="">
						<ResourceTextInline resourceKey="ParkingSpace_ReplacesCurrentSpace" />
					</label>
				</div>
			</div>
		);
	};

	if (selectedSpace) {
		return (
			<>
				{currentParkingSpaceCount === 1 && checkboxForSwapping()}

				<div className="atom-singleParkingSpace -removeSpace">
					<ParkingSpaceInformation
						title={<ResourceTextInline resourceKey="ParkingSpace_TerminatedSpace_Title" />}
						space={selectedSpace}
						electricChargingInfoFormat="Compact"
						subtitle={
							<ResourceText
								resourceKey="RentalPage_TerminatedSpace_Subtitle"
								textType={'MarkdownInline'}
							/>
						}
					>
						<>
							<li className="atom-singleParkingSpace__singleDetail">
								<span className="detailTitle">
									<ResourceTextInline
										resourceKey={getContractStartDateResourceKey(selectedSpace.contractStartDate)}
									/>
								</span>
								<span>{formatDate(selectedSpace.contractStartDate)}</span>
							</li>
							{currentSpaces.length > 1 && (
								<a
									className="atom-singleParkingSpace__link"
									href="#"
									onClick={() => setSelectedSpace(undefined)}
								>
									<ResourceTextInline resourceKey="ParkingSpace_SelectSpaceToTerminate" />
								</a>
							)}
						</>
					</ParkingSpaceInformation>
				</div>
			</>
		);
	}

	if (currentParkingSpaceCount === 1) {
		return checkboxForSwapping();
	}

	if (currentParkingSpaceCount > 1) {
		return (
			<div className="atom-singleParkingSpace__options">
				<ResourceText
					resourceKey="ParkingSpace_HasMaximumParkingSpaces"
					parameters={[currentParkingSpaceCount.toString()]}
				/>

				<h3 className="atom-singleParkingSpace__title">
					<ResourceTextInline resourceKey="ParkingSpace_SelectTerminatedSpace_Title" />
				</h3>
				{props.currentSpaces.map((s) => (
					<div className="input radio">
						<input type="radio" id={s.id} onChange={() => setSelectedSpace(s)} />
						<label htmlFor={s.id}>
							<ResourceTextInline
								resourceKey="ParkingSpace_TerminatedSpace_Text"
								parameters={[
									s.name,
									getResourceText(getParkingSpaceCharacteristics(s.characteristics)),
									s.rent?.toString() || '',
								]}
							/>
						</label>
					</div>
				))}
			</div>
		);
	}

	return null;
}
