import { useDispatch, useSelector } from 'react-redux';
import { ApiOperationState, isAlreadyRequested, isOperationSuccess, OperationState } from '../operationState';
import { AppRootState } from '../Store/appRootState';
import { ResourceTextInline } from '@kojamo/react-utils';
import { TextHero } from '../Components/TextHero';
import { searchWatchActions } from '../Data/searchWatchActions';
import { Spinner } from '../Components/Spinner';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { appRoutes } from '../appRoutes';
import { WatchAM } from '../Data/searchWatchApiClient';
import { BusinessEntityFullAM } from '../Data/businessEntityApiClient';
import { businessEntityActions } from '../Data/businessEntityActions';
import { Link } from 'react-router-dom';
import { BackToBusinessEntityPage } from '../Components/BackToBusinessEntityPage';
import { pageViewTelemetryActions } from '../Telemetry/pageViewTelemetryActions';
import { addTelemetryEventGA4, GA4EventAction } from '../Telemetry/TelemetryGA4';
import { getParkingSpaceCharacteristics } from '../Common/getParkingSpaceCharacteristics';
import { useAppLanguage } from '../hooks/useAppLanguage';

interface StateProps {
	deleteSearchWatchOperation?: OperationState<void>;
	fetchSearchWatchOperation?: OperationState<WatchAM>;
	businessEntity?: OperationState<BusinessEntityFullAM>;
}

export default function RemoveSearchWatchPage() {
	const { language } = useAppLanguage();
	const dispatch = useDispatch();
	const stateProps = useSelector<AppRootState, StateProps>(({ searchWatch, businessEntity }) => ({
		fetchSearchWatchOperation: searchWatch.currentSearchWatch,
		deleteSearchWatchOperation: searchWatch.deleteSearchWatch,
		businessEntity: businessEntity.businessEntity,
	}));
	const navigate = useNavigate();
	const deleteOperationState = stateProps.deleteSearchWatchOperation
		? stateProps.deleteSearchWatchOperation.status
		: undefined;

	const watch = isOperationSuccess(stateProps.fetchSearchWatchOperation)
		? stateProps.fetchSearchWatchOperation.result
		: undefined;

	const businessEntity = isOperationSuccess(stateProps.businessEntity) ? stateProps.businessEntity.result : undefined;

	useEffect(() => {
		if (!isAlreadyRequested(stateProps.fetchSearchWatchOperation)) {
			dispatch(searchWatchActions.fetchSearchWatch.request());
		}
	}, [dispatch, stateProps.fetchSearchWatchOperation]);

	useEffect(() => {
		if (stateProps.fetchSearchWatchOperation?.status === ApiOperationState.Failed) {
			navigate(appRoutes.homePage);
		}
	}, [stateProps.fetchSearchWatchOperation?.status, navigate]);

	useEffect(() => {
		if (watch && !businessEntity) {
			dispatch(businessEntityActions.fetchBusinessEntity.request(watch.businessEntityId));
		}
	}, [watch, businessEntity, dispatch]);

	useEffect(() => {
		if (businessEntity) {
			dispatch(
				pageViewTelemetryActions.pageComplete({
					businessEntityNumber: businessEntity.number,
					pageType: 'RemoveSearchWatch',
				}),
			);
		}
	}, [businessEntity, dispatch]);

	useEffect(() => {
		if (deleteOperationState === ApiOperationState.Succeeded) {
			navigate(appRoutes.searchWatchRemovalCompleted);
			addTelemetryEventGA4({ action: GA4EventAction.parkingspace_searchwatch_removed });
			dispatch(searchWatchActions.resetSearchWatchRemovalState());
		}
	}, [deleteOperationState, dispatch, navigate, stateProps.businessEntity, language]);

	const onRemoveClick = () => {
		if (watch) {
			dispatch(searchWatchActions.deleteSearchWatch.request(watch.id));
		}
	};

	const searchTypesList = watch
		? watch.allowedTypes.map((type) => (
				<li key={type}>
					<ResourceTextInline resourceKey={getParkingSpaceCharacteristics(type)} />
				</li>
		  ))
		: undefined;

	return (
		<>
			{businessEntity && (
				<>
					<BackToBusinessEntityPage businessEntityId={businessEntity.id} />
					<TextHero
						icon="parkingSpaceSearchWatch"
						title={<ResourceTextInline resourceKey="RemoveSearchWatchPage_TextHero_Title" />}
						text={
							<ResourceTextInline
								resourceKey="RemoveSearchWatchPage_TextHero_Text"
								parameters={[businessEntity.name]}
							/>
						}
					/>
				</>
			)}

			{searchTypesList && (
				<div className="mod">
					<div className="atom-boxedContent">
						<ul className="atom-boxedContent__list -checked">{searchTypesList}</ul>
					</div>
				</div>
			)}

			<div className="mod-ctaGroup">
				{deleteOperationState === ApiOperationState.Requested && <Spinner />}
				{deleteOperationState !== ApiOperationState.Requested && (
					<>
						<button className="mod-ctaGroup__button -primary" onClick={onRemoveClick}>
							<ResourceTextInline resourceKey="RemoveSearchWatchPage_RemoveButton" />
						</button>
						<Link to={appRoutes.editSearchWatchPage}>
							<button className="mod-ctaGroup__button -secondary">
								<ResourceTextInline resourceKey="RemoveSearchWatchPage_EditButton" />
							</button>
						</Link>
						<Link to={appRoutes.businessEntityPage(businessEntity?.id)} className={'mod-ctaGroup__link'}>
							<ResourceTextInline resourceKey="RemoveSearchWatchPage_Back" />
						</Link>
					</>
				)}
			</div>
		</>
	);
}
