import { getType } from 'typesafe-actions';
import { appStateActions } from './appStateActions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { SettingsAM, SettingsApiClient } from './settingsApiClient';
import { calculateServerTimeOffset } from '../Components/serverTime';
import { AppRootState } from '../Store/appRootState';
import { UserApiClient, UserInfoAM } from './userApiClient';
import { UILanguage, UserInfo } from './userReducer';
import { getLocalStorageValue, setLocalStorageValue } from '../Common/localStorage';

const languageLocalStorageKey = 'language';
function* setLanguageSaga(): Generator {
	const search = window.location.search;
	const queryParams = new URLSearchParams(search);
	const lang = (queryParams.get('language') || getLocalStorageValue(languageLocalStorageKey)) as UILanguage;

	if (lang) {
		setLocalStorageValue(languageLocalStorageKey, lang);
	}

	// Validate the lang query parameter.
	if (!['fi', 'en'].includes(lang)) {
		yield put(appStateActions.setLanguage.failure(new Error(`Invalid language: ${lang}`)));
	} else {
		yield put(appStateActions.setLanguage.success(lang));
	}
}

function* fetchAppSettingsSaga() {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userInfo: UserInfo = yield select((state: AppRootState) => state.user.userInfo);
		const api = new SettingsApiClient(language, userInfo);
		const settings: SettingsAM = yield call([api, api.fetchAppSettings]);
		calculateServerTimeOffset(settings.serverTime);
		yield put(appStateActions.fetchAppSettings.success(settings));
	} catch (e: any) {
		yield put(appStateActions.fetchAppSettings.failure(e));
	}
}

function* getUserInfoSaga() {
	try {
		const language: UILanguage = yield select((state: AppRootState) => state.user.uiLanguage);
		const userInfo: UserInfo = yield select((state: AppRootState) => state.user.userInfo);
		const api = new UserApiClient(language, userInfo);
		const settings: UserInfoAM = yield call([api, api.getUserInfo]);
		yield put(appStateActions.getUserInfo.success(settings));
	} catch (e: any) {
		yield put(appStateActions.getUserInfo.failure(e));
	}
}

function* appStateSaga() {
	yield takeLatest(getType(appStateActions.fetchAppSettings.request), fetchAppSettingsSaga);
	yield takeLatest(getType(appStateActions.getUserInfo.request), getUserInfoSaga);
	yield takeLatest(getType(appStateActions.setLanguage.request), setLanguageSaga);
}

export default appStateSaga;
