import { ResourceText } from '@kojamo/react-utils';

export interface IErrorMessageDisplayProps {
	message?: string;
	resourceKey?: string;
	resourceParameters?: string[];
}

export function ErrorMessageDisplay(props: IErrorMessageDisplayProps) {
	function customErrorMessage() {
		if (props.resourceKey) {
			return <ResourceText resourceKey={props.resourceKey} parameters={props.resourceParameters} />;
		}
		return null;
	}

	return (
		<div className="atom-infoBox" style={{ marginBottom: 24 }}>
			<div className="atom-infoBox__text atom-boxedContent">
				{props.message || customErrorMessage() || (
					<ResourceText resourceKey="ErrorMessageDisplay_DefaultMessage" />
				)}
			</div>
		</div>
	);
}
