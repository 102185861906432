//
// serverTime.ts
//
// This modules a difference in milliseconds between the server time and the local time.
// This time shift is used when calculating expiration time for rentals.
//
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import { addMilliseconds, addMinutes } from '../dateUtils';

declare global {
	interface Window {
		serverTimeString: string;
		serverTimeOffset: number;
	}
}

export function calculateServerTimeOffset(serverTime: Date) {
	const now = new Date();
	window.serverTimeOffset = differenceInMilliseconds(now, serverTime);
}

// return the approximate server time in UTC timezone
export default function getServerTimeUtc() {
	const now = new Date();
	const utcnow = addMinutes(now, now.getTimezoneOffset());
	return addMilliseconds(utcnow, -window.serverTimeOffset);
}
