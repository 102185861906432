import { getType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { CurrentParkingSpaceAM } from './parkingSpaceApiClient';
import { OperationFailed, OperationRequested, OperationState, OperationSuccess } from '../operationState';
import { parkingSpaceActions, ParkingSpaceActionTypes } from './parkingSpaceActions';

export interface ParkingSpaceState {
	currentSpaces: OperationState<CurrentParkingSpaceAM[], string> | undefined;
}

const initialState: ParkingSpaceState = {
	currentSpaces: undefined,
};

export const parkingSpaceReducer: Reducer<ParkingSpaceState, ParkingSpaceActionTypes> = (
	state = initialState,
	action,
) => {
	switch (action.type) {
		case getType(parkingSpaceActions.getCurrentParkingSpaces.request): {
			return {
				...state,
				currentSpaces: OperationRequested(action.payload),
			};
		}
		case getType(parkingSpaceActions.getCurrentParkingSpaces.success): {
			return {
				...state,
				currentSpaces: OperationSuccess(action.payload, state.currentSpaces?.param),
			};
		}
		case getType(parkingSpaceActions.getCurrentParkingSpaces.failure): {
			return {
				...state,
				currentSpaces: OperationFailed(action.payload, state.currentSpaces?.param),
			};
		}
	}
	return state;
};
